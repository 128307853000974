import React, { useEffect, useState, useRef, useMemo } from "react";
import "./table.css";

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    flexRender,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function ProjectsTable({
    data,
    setRowSelectionParent,
    defaultSelected,
}) {
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: ({ table }) => (
                    <>
                        <IndeterminateCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                        />{" "}
                        <button
                            {...{
                                onClick:
                                    table.getToggleAllRowsExpandedHandler(),
                            }}
                        >
                            {table.getIsAllRowsExpanded() ? (
                                <ExpandMoreIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </button>{" "}
                        Name
                    </>
                ),
                cell: ({ row, getValue }) => (
                    <div
                        style={{
                            // Since rows are flattened by default,
                            // we can use the row.depth property
                            // and paddingLeft to visually indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 1.5}rem`, // magic number to line things up
                        }}
                    >
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                        />{" "}
                        {getValue()}
                    </div>
                ),
                footer: (props) => props.column.id,
            },
            {
                accessorKey: "address",
                header: () => "Address",
                footer: (props) => props.column.id,
            },

            {
                accessorKey: "city",
                header: () => "City",
                footer: (props) => props.column.id,
            },
            {
                accessorKey: "country",
                header: "Country",
                footer: (props) => props.column.id,
            },
            {
                accessorKey: "postal",
                header: "Postal",
                footer: (props) => props.column.id,
            },
        ],
        []
    );

    const [rowSelection, setRowSelection] = useState(defaultSelected);
    useEffect(() => {
        setRowSelection(defaultSelected);
    }, [defaultSelected]);

    useEffect(() => {
        setRowSelectionParent(rowSelection);
    }, [setRowSelectionParent, rowSelection]);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
        },
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        getFilteredRowModel: getFilteredRowModel(),
    });

    const tableContainerRef = useRef();
    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        estimateSize: () => 50,
        count: rows.length,
        overscan: 20,
    });
    const totalSize = rowVirtualizer.getTotalSize();

    const items = rowVirtualizer.getVirtualItems();
    const firstItem = items[0];
    const lastItem = items[items.length - 1];

    const paddingTop = firstItem ? firstItem.start : 0;
    const paddingBottom = lastItem ? totalSize - lastItem.end : 0;

    return (
        <div className="container" ref={tableContainerRef}>
            <table>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div className="flex gap-2 w-full">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter
                                                        column={header.column}
                                                        table={table}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody>
                    {paddingTop > 0 && (
                        <tr>
                            <td style={{ height: `${paddingTop}px` }} />
                        </tr>
                    )}
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        return (
                            <tr key={row.id} className="pl-5">
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="pl-2">
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    {paddingBottom > 0 && (
                        <tr>
                            <td style={{ height: `${paddingBottom}px` }} />
                        </tr>
                    )}
                </tbody>
            </table>
            <div>{table.getRowModel().rows.length} Rows</div>
        </div>
    );
}

function Filter({ column, table }) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    return typeof firstValue === "number" ? (
        <div className="flex space-x-2">
            <input
                type="number"
                value={columnFilterValue?.[0] ?? ""}
                onChange={(e) =>
                    column.setFilterValue((old) => [e.target.value, old?.[1]])
                }
                placeholder="Min"
                className="w-24 border shadow rounded"
            />
            <input
                type="number"
                value={columnFilterValue?.[1] ?? ""}
                onChange={(e) =>
                    column.setFilterValue((old) => [old?.[0], e.target.value])
                }
                placeholder="Max"
                className="w-24 border shadow rounded"
            />
        </div>
    ) : (
        <input
            type="text"
            value={columnFilterValue ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Search..."
            className="w-28 border rounded font-normal px-2"
        />
    );
}

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = useRef(null);

    useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]); // rest.checked should not be here

    return (
        <input
            type="checkbox"
            ref={ref}
            className={`${className} cursor-pointer`}
            {...rest}
        />
    );
}
