import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateSensorType from "./CreateSensorType";
import DeleteSensorType from "./DeleteSensorType";
import UpdateSensorType from "./UpdateSensorType";
import SearchByDevEUI from "./SearchByDevEUI";
import SensorTemplate from "../SensorTemplate";

function SettingsSensorType({ alertCB }) {
    const [openCreateSensorType, setOpenCreateSensorType] = useState(false);
    const [openDeleteSensorType, setOpenDeleteSensorType] = useState(false);
    const [openUpdateSensorType, setOpenUpdateSensorType] = useState(false);
    const [openSensorTemplate, setOpenSensorTemplate] = useState(false);
    const [openSearchByDevEUI, setOpenSearchByDevEUI] = useState(false);

    const handleCloseCreateSensorType = () => {
        setOpenCreateSensorType(false);
    };
    const handleCloseSensorTemplate = () => {
        setOpenSensorTemplate(false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Sensor</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1} alignItems="flex-start">
                    <Button onClick={() => setOpenSensorTemplate(true)}>
                        Sensor Template
                    </Button>
                    <Button onClick={() => setOpenCreateSensorType(true)}>
                        Create type
                    </Button>
                    <Button onClick={() => setOpenUpdateSensorType(true)}>
                        Update type
                    </Button>
                    <Button onClick={() => setOpenDeleteSensorType(true)}>
                        Delete type
                    </Button>
                    <Button onClick={() => setOpenSearchByDevEUI(true)}>
                        Search by devEUI
                    </Button>
                </Stack>
                <SensorTemplate
                    open={openSensorTemplate}
                    onCloseDialog={handleCloseSensorTemplate}
                    onAlert={alertCB}
                />
                <CreateSensorType
                    open={openCreateSensorType}
                    closeCB={handleCloseCreateSensorType}
                    alertCB={alertCB}
                />
                <UpdateSensorType
                    open={openUpdateSensorType}
                    onCloseDialog={setOpenUpdateSensorType}
                    onAlert={alertCB}
                />
                <DeleteSensorType
                    open={openDeleteSensorType}
                    onCloseDialog={setOpenDeleteSensorType}
                    onAlert={alertCB}
                />
                <SearchByDevEUI
                    open={openSearchByDevEUI}
                    closeCB={setOpenSearchByDevEUI}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default SettingsSensorType;
