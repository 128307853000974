import React, { useCallback, useState } from "react";
import {
    Button,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Logo from "../Images/Invisense_Logo_big.png";
import { checkPassword, login, removeCookies } from "../Services/loginService";
import { signOutTimer } from "../Services/signoutService";

const style = {
    box: {
        width: 750,
        boxShadow: "3",
        borderRadius: 2,
        marginTop: 10,
        marginLeft: "auto",
        marginRight: "auto",
    },
};

export default function LoginForm() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const onSubmit = useCallback(
        (event) => {
            event.preventDefault();
            removeCookies();

            setLoading(true);
            setError("");

            checkPassword(username, password)
                .then(() => login(username, password))
                .then(() => {
                    signOutTimer();
                    navigate("/dashboard");
                })
                .catch((err) => setError(err.response.data))
                .finally(() => setLoading(false));
        },
        [username, password, navigate]
    );

    return (
        <Box sx={{ ...style.box }}>
            <form
                style={{
                    width: "95%",
                    marginLeft: "20px",
                }}
                autoComplete="on"
                onSubmit={onSubmit}
            >
                <Stack spacing={1} alignItems="flex-start">
                    <img
                        src={Logo}
                        alt="logo"
                        style={{
                            width: "35%",
                            marginTop: "20px",
                        }}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Username"
                        id="username"
                        value={username}
                        onChange={(text) => setUsername(text.target.value)}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Password"
                        type="password"
                        autoComplete="on"
                        onChange={(text) => setPassword(text.target.value)}
                    />
                    <Button
                        data-testid="submit"
                        disabled={loading}
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Login
                        {loading && (
                            <CircularProgress size="1rem" sx={{ ml: 1 }} />
                        )}
                    </Button>
                    <Typography color="error">
                        {error?.error?.message}
                    </Typography>
                    <Grid container item justifyContent="flex-end">
                        <Typography sx={{ marginBottom: "10px" }}>
                            Read more about updates for the web portal
                            <Link
                                href="https://invisense.se/releasenotes"
                                underline="none"
                            >
                                {" "}
                                here
                            </Link>
                        </Typography>
                    </Grid>
                </Stack>
            </form>
        </Box>
    );
}
