import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { deletePartner } from "../../../Services/settingsService";
import { getPartners } from "../../../Services/projectService";
import AlertDialog from "../../ReusableComponents/AlertDialog";
import PartnerList from "./PartnerList";

const styles = {
    dialogPaper: {
        minWidth: "700px",
    },
};

function DeletePartner({ open, onCloseDialog, onAlert }) {
    const header = "Delete partner";
    const [partners, setPartners] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [partnerDelete, setPartnerDelete] = useState({
        id: "",
        username: "",
    });

    useEffect(() => {
        if (open) {
            getPartners()
                .then((res) => {
                    setPartners(res.data.partners);
                })
                .catch((error) => onAlert(error.response.data.error));
        }
    }, [open, onAlert]);

    useEffect(() => {}, [partners]);

    const openAlertDialog = (id, username) => {
        setPartnerDelete({ id, username });
        setShowDialog(true);
    };

    const columns = useMemo(
        () => [
            { field: "name", headerName: "Partner", minWidth: 200, flex: 1 },
            { field: "email", headerName: "Email", width: 250 },
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 100,
                align: "center",
                getActions({ row }) {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => openAlertDialog(row.id, row.name)}
                        />,
                    ];
                },
            },
        ],
        []
    );

    const handleDelete = (confirm) => {
        if (confirm) {
            deletePartner(partnerDelete.id)
                .then(() => {
                    setPartners(
                        partners.filter((p) => p.id !== partnerDelete.id)
                    );
                })
                .catch((error) => onAlert(error.response.data.error));
        }
    };

    const closeAlertDialog = (close) => {
        setPartnerDelete({ id: "", username: "" });
        setShowDialog(close);
    };

    const closeDialog = () => {
        setPartners([]);
        onCloseDialog(false);
    };

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <PartnerList
                    columns={columns}
                    rows={partners}
                    autoHeight
                    rowsPerPageOptions={[8]}
                    pageSize={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
            {showDialog ? (
                <AlertDialog
                    header="Delete partner"
                    body={`Are you sure you want to delete partner ${partnerDelete.username}?`}
                    confirmButton="Delete"
                    callback={handleDelete}
                    setCallback={closeAlertDialog}
                />
            ) : null}
        </Dialog>
    );
}

export default DeletePartner;
