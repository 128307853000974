import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function PartnerList({ columns, rows }) {
    return (
        <DataGrid
            initialState={{
                sorting: {
                    sortModel: [{ field: "Customer", sort: "asc" }],
                },
            }}
            columns={columns}
            rows={rows}
            autoHeight
            rowsPerPageOptions={[8]}
            pageSize={8}
        />
    );
}
