import Axios from "axios";

export function getObject(objectId, sensorType) {
    return Axios.get(`/api/object/${objectId}`, {
        params: {
            sensorType,
        },
    });
}

export function getObjects(floorId) {
    return Axios.get("/api/get_objects", {
        params: {
            floorId,
        },
    });
}

export function getObjectsBySensorType(floorId, sensorType) {
    return Axios.get("/api/get_objects_by_sensor_type", {
        params: {
            floorId,
            sensorType,
        },
    });
}

export function deleteObject(objectId) {
    return Axios.delete("/api/delete_object", {
        data: {
            objectId,
        },
    });
}

export function addNewObject(floorId, name) {
    return Axios.post("/api/add_new_object", {
        floorId,
        name,
    });
}

export function uploadBlueprint(file, id) {
    const formData = new FormData();
    formData.append("uploads[]", file, id);
    formData.append("id", id);
    return Axios.post("/upload", formData);
}

export function updateObjectName(objectId, newName) {
    return Axios.put("/api/update_object_name", { objectId, newName });
}

export function deleteBlueprint(objectId) {
    return Axios.delete("/api/blueprint", { data: { id: objectId } });
}

export function addObjectTemplate(name, blueprintName, sensors) {
    return Axios.post("/api/object_template", {
        name,
        blueprintName,
        sensors,
    });
}

export function getObjectTemplate() {
    return Axios.get("/api/object_template");
}

export function addNewObjectFromTemplate(floorId, template) {
    return Axios.post("/api/new_object_from_template", {
        floorId,
        template,
    });
}

export function deleteObjectTemplate(id) {
    return Axios.delete("/api/object_template", {
        data: {
            id,
        },
    });
}
