import React, { useEffect, useState } from "react";
import {
    DialogContent,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    Autocomplete,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as sensorService from "../../Services/sensorService";
import TopBar from "../ReusableComponents/TopBar";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";

const styles = {
    dialogPaper: {
        maxWidth: "50%",
        minWidth: "50%",
    },
    gridMargin: {
        marginTop: "20px",
    },
};

const screedBlockSizes = ["20", "35", "50"];
export default function EditMultipleSensors() {
    const { sensorTypeName, ids, objectId } = useLocation().state;
    const [alert, setAlert] = useState({ type: "error", message: "" });

    const [sensorFields, setSensorFields] = useState({
        sensorTypeName,
        description: null,
        back: null,
        front: null,
        screedDepth: null,
        screedBlockSize: null,
        screedType: null,
    });

    const [screedTypes, setScreedTypes] = useState([]);
    const [selectedScreedType, setSelectedScreedType] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (sensorTypeName === "Screed") {
            sensorService
                .screedTypes()
                .then((res) => {
                    setScreedTypes(res.data.screedTypes);
                })
                .catch((err) =>
                    setAlert({
                        type: "error",
                        message: err.response.data.message,
                    })
                );
        }
    }, [sensorTypeName]);

    const handleSubmit = (event) => {
        event.preventDefault();

        sensorService
            .updateSensors(ids, sensorFields)
            .then(() => {
                setAlert({
                    type: "success",
                    message: "Successfully updated sensors",
                });
                const projectType =
                    localStorage.getItem("projectType") ?? "standard";
                navigate(`/object/${objectId}/${projectType}`);
            })
            .catch((err) => setAlert({ type: "error", message: err.message }));
    };

    return (
        <Dialog
            open
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle style={{ padding: "0px" }}>
                <TopBar
                    title="Edit Sensors"
                    previousPath={`/object/${objectId}`}
                    canClose={false}
                />
            </DialogTitle>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid container item sx={{ ...styles.gridMargin }}>
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                {sensorTypeName === "Screed" ? (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Screed Depth"
                                                type="number"
                                                value={sensorFields.screedDepth}
                                                onChange={(e) =>
                                                    setSensorFields({
                                                        ...sensorFields,
                                                        screedDepth:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                disablePortal
                                                options={screedBlockSizes}
                                                value={
                                                    sensorFields.screedBlockSize?.toString() ??
                                                    ""
                                                }
                                                onChange={(event, newValue) => {
                                                    setSensorFields({
                                                        ...sensorFields,
                                                        screedBlockSize:
                                                            newValue,
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Screed block size (mm)"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={screedTypes}
                                                getOptionLabel={(option) =>
                                                    option.name ?? ""
                                                }
                                                value={selectedScreedType ?? ""}
                                                onChange={(event, newValue) => {
                                                    setSensorFields({
                                                        ...sensorFields,
                                                        screedType:
                                                            newValue?.id,
                                                    });
                                                    setSelectedScreedType(
                                                        newValue
                                                    );
                                                }}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => (
                                                    <li
                                                        {...props}
                                                        key={option.id}
                                                    >
                                                        {option.name}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Screed type"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                                {sensorTypeName !== "Concrete" &&
                                sensorTypeName !== "Screed" ? (
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Adhesive side"
                                                value={sensorFields.frontMat}
                                                onChange={(e) =>
                                                    setSensorFields({
                                                        ...sensorFields,
                                                        frontMat:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Measurement side"
                                                value={sensorFields.backMat}
                                                onChange={(e) =>
                                                    setSensorFields({
                                                        ...sensorFields,
                                                        backMat: e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid container item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Technician"
                                        value={sensorFields.technician}
                                        onChange={(e) =>
                                            setSensorFields({
                                                ...sensorFields,
                                                technician: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={8}
                                        label="Description"
                                        value={sensorFields.description}
                                        onChange={(e) =>
                                            setSensorFields({
                                                ...sensorFields,
                                                description: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
