import * as projectApi from "../Api/projectApi";
import roles from "./roleService";

export function getCustomers() {
    return projectApi.getCustomers();
}

export function getProjects(sensorType) {
    return projectApi.getProjects(sensorType);
}

export function deleteProject(projectId) {
    return projectApi.deleteProject(projectId);
}

export function getProject(projectId, customerId) {
    return projectApi.getProject(projectId, customerId);
}

export function projectTypes(projectId) {
    return projectApi.projectTypes(projectId);
}

export function updateProject(projectId, project, selectedSensorTypes) {
    const modalName = project.modalName !== "" ? project.modalName : "Floors";
    return projectApi.updateProject(
        projectId,
        { ...project, modalName },
        selectedSensorTypes
    );
}

export function existsSensorsBySensorType(sensorType) {
    return projectApi.existsSensorsBySensorType(sensorType);
}

export function addNewProject(customerId) {
    return projectApi.addNewProject(customerId);
}

export function getRole() {
    return projectApi.getRole();
}

export function getPartners() {
    return projectApi.getPartners();
}

export function getCustomersAsMeasurer() {
    return projectApi.getCustomersAsMeasurer();
}

export function getCustomersAsPartner() {
    return projectApi.getCustomersAsPartner();
}

export function getCustomersAsAdmin(partnerId) {
    return projectApi.getCustomersAsAdmin(partnerId);
}

export function getProjectsByCustomer(customerId, sensorType) {
    return projectApi.getProjectsByCustomer(customerId, sensorType);
}

export function getModalInfo(projectId, customerId, sensorType) {
    return projectApi.getModalInfo(projectId, customerId, sensorType);
}

export function projectTypesByObject(objectId, role) {
    if (role === roles.Admin) {
        return projectApi.projectTypesByObjectAdmin(objectId);
    }
    return projectApi.projectTypesByObject(objectId);
}

export function measurerByProject(projectId) {
    return projectApi.measurersByProject(projectId);
}

export function usersByIds(ids) {
    return projectApi.usersByIds(ids);
}
