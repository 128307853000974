import { useReducer, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    CircularProgress,
    IconButton,
    Pagination,
    Typography,
    Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import qs from "query-string";
import ProjectCards from "./ProjectCards";
import SortSelector from "./SortSelector";
import useWindowSize from "./Hooks/useWindowSize";
import * as projectService from "../../Services/projectService";
import SelectorsForRoles from "../ReusableComponents/SelectorsForRoles";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import roles from "../../Services/roleService";
import { useRoleContext } from "../../Context/RoleContext";
import ManageTemplatesDialog from "./ManageTemplatesDialog";

const styles = {
    darkButton: {
        padding: "5px 12px 5px 5px",
        display: "flex",
        backgroundColor: "#24242d",
        borderRadius: "20px",
        color: "#eee",
        alignItems: "center",
        fontFamily: "arial",
        marginLeft: "20px",
        textTransform: "none",
        fontSize: 16,
        "&:hover": {
            backgroundColor: "#24242d",
            borderColor: "#24242d",
            boxShadow: "none",
        },
    },
    addIcon: {
        color: "limegreen",
    },
    settingsIcon: {
        color: "white",
    },
    typographySmall: {
        fontFamily: "Arial",
        fontSize: 18,
    },
};

function Projects({ sensorType }) {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const selections = qs.parse(search);
    const defaultSort = "Latest activity"; // Get sort from db
    const { width, height } = useWindowSize();
    const { role } = useRoleContext();

    // 450 card width, 100 magic number based on sidebar offset
    // 215 card height, 300 magic number based on header offset
    // NEEDS WORK, USES TO MUCH MAGIC
    const calculateProjectsPerPage =
        Math.floor((width - 100) / 450) * Math.floor((height - 300) / 215);

    const [alert, setAlert] = useState({ type: "error", message: "" });
    const [projects, setProjects] = useState({
        items: [],
        isLoading: false,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [showTemplateDialog, setShowTemplateDialog] = useState(false);
    const [projectsPerPage, setProjectsPerPage] = useState(
        calculateProjectsPerPage
    );
    const [partnerSelector, setPartnerSelector] = useState({
        selected: selections.partner ?? localStorage.getItem("partner") ?? "",
        partners: [],
    });
    const [customerSelector, setCustomerSelector] = useState({
        selected: selections.customer ?? localStorage.getItem("customer") ?? "",
        customers: [],
    });

    if (projectsPerPage !== calculateProjectsPerPage) {
        setProjectsPerPage(calculateProjectsPerPage);
    }

    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const pages = Math.ceil(projects.items.length / projectsPerPage);
    const currentProjects = projects.items.slice(
        indexOfFirstProject,
        indexOfLastProject
    );

    const getSortOrder = () => localStorage.getItem("sortOrder") ?? defaultSort;

    const handleSort = ({ sortOrder, arr }) => {
        localStorage.setItem("sortOrder", sortOrder);
        const temp = arr.length > 0 ? arr : [...projects.items];
        switch (sortOrder) {
            case "Latest activity":
                temp.sort((a, b) =>
                    new Date(a.latestActivity) >= new Date(b.latestActivity)
                        ? -1
                        : 1
                );
                break;
            case "Name":
                temp.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case "Created":
                temp.sort((a, b) =>
                    new Date(a.created) >= new Date(b.created) ? -1 : 1
                );
                break;
            default:
                break;
        }

        setProjects({
            items: temp,
            isLoading: false,
        });
    };

    const [, dispatch] = useReducer((current, update) => {
        const { sortOrder, arr } = update;
        handleSort({ sortOrder, arr });
    }, []);

    useEffect(() => {
        if (role === roles.Customer) {
            setProjects({
                items: [],
                isLoading: true,
            });

            projectService
                .getProjects(sensorType)
                .then((res) => {
                    const objects = res.data.projects;
                    dispatch({ sortOrder: getSortOrder(), arr: objects });
                    setProjects({
                        items: objects,
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    setAlert(error.response.data.error);
                });
        } else if (role === roles.Admin) {
            if (partnerSelector.partners.length === 0) {
                projectService
                    .getPartners()
                    .then((res) =>
                        setPartnerSelector((p) => ({
                            ...p,
                            partners: res.data.partners,
                        }))
                    )
                    .catch((error) => setAlert(error.response.data.error));
            }

            if (partnerSelector.selected !== "") {
                projectService
                    .getCustomersAsAdmin(partnerSelector.selected)
                    .then((res) => {
                        setCustomerSelector((c) => ({
                            ...c,
                            customers: res.data.customers,
                        }));
                    })
                    .catch((error) => setAlert(error.response.data.error));
            }

            if (customerSelector.selected !== "") {
                setProjects({
                    items: [],
                    isLoading: true,
                });

                projectService
                    .getProjectsByCustomer(
                        customerSelector.selected,
                        sensorType
                    )
                    .then((res) => {
                        const objects = res.data.projects;
                        dispatch({ sortOrder: getSortOrder(), arr: objects });
                        setProjects({
                            items: objects,
                            isLoading: false,
                        });
                    })
                    .catch((error) => setAlert(error.response.data.error));
            }
        } else if (role === roles.Partner) {
            projectService
                .getCustomersAsPartner()
                .then((res) =>
                    setCustomerSelector((c) => ({
                        ...c,
                        customers: res.data.customers,
                    }))
                )
                .catch((error) => setAlert(error.response.data.error));

            if (customerSelector.selected !== "") {
                setProjects({
                    items: [],
                    isLoading: true,
                });

                projectService
                    .getProjectsByCustomer(
                        customerSelector.selected,
                        sensorType
                    )
                    .then((res) => {
                        const objects = res.data.projects;
                        dispatch({ sortOrder: getSortOrder(), arr: objects });
                        setProjects({
                            items: objects,
                            isLoading: false,
                        });
                    })
                    .catch((error) => setAlert(error.response.data.error));
            }
        } else if (role === roles.Measurer) {
            projectService
                .getCustomersAsMeasurer()
                .then((res) =>
                    setCustomerSelector((c) => ({
                        ...c,
                        customers: res.data.customers,
                    }))
                )
                .catch((error) => setAlert(error.response.data.error));

            if (customerSelector.selected !== "") {
                setProjects({
                    items: [],
                    isLoading: true,
                });

                projectService
                    .getProjectsByCustomer(
                        customerSelector.selected,
                        sensorType
                    )
                    .then((res) => {
                        const objects = res.data.projects;
                        dispatch({ sortOrder: getSortOrder(), arr: objects });
                        setProjects({
                            items: objects,
                            isLoading: false,
                        });
                    })
                    .catch((error) => setAlert(error.response.data.error));
            }
        }
    }, [
        role,
        customerSelector.selected,
        partnerSelector.selected,
        partnerSelector.partners.length,
        sensorType,
    ]);

    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    const handleSelectPartner = (partnerId) => {
        localStorage.setItem("partner", partnerId);
        navigate({
            pathname,
            search: qs.stringify({ partner: partnerId }),
        });

        setPartnerSelector((p) => ({ ...p, selected: partnerId }));
        setCustomerSelector((c) => ({ ...c, selected: "" }));
        setProjects({
            items: [],
            isLoading: false,
        });
    };

    const handleSelectCustomer = (customerId) => {
        localStorage.setItem("customer", customerId);
        const newSearch =
            partnerSelector.selected === ""
                ? { customer: customerId }
                : { partner: partnerSelector.selected, customer: customerId };

        navigate({
            pathname,
            search: qs.stringify(newSearch),
        });

        setCustomerSelector((c) => ({ ...c, selected: customerId }));
    };

    const deleteProjectCallback = (projectId) => {
        const temp = [...projects.items];
        const sliceIndex = temp.findIndex((x) => x.id === projectId);
        if (sliceIndex === 0) {
            setProjects({
                items: temp.slice(1),
                isLoading: false,
            });
        } else if (sliceIndex === temp.length - 1) {
            setProjects({
                items: temp.slice(0, -1),
                isLoading: false,
            });
        } else if (sliceIndex > 0) {
            setProjects({
                items: [].concat(
                    temp.slice(0, sliceIndex),
                    temp.slice(sliceIndex + 1)
                ),
                isLoading: false,
            });
        }
    };

    const handleAddProject = () => {
        projectService.addNewProject(customerSelector.selected).then((res) => {
            localStorage.setItem(
                "CreateEditProject",
                JSON.stringify({
                    isNew: true,
                    projectId: res.data.projectId,
                    customerId: customerSelector.selected,
                })
            );
            navigate("/create-edit-project");
        });
    };

    return (
        <Grid container>
            {showTemplateDialog && (
                <ManageTemplatesDialog setCallback={setShowTemplateDialog} />
            )}
            <SelectorsForRoles
                role={role}
                cSelector={customerSelector}
                handleCustomerSelect={handleSelectCustomer}
                pSelector={partnerSelector}
                handlePartnerSelect={handleSelectPartner}
            />
            <SystemAlertSnackbar
                showAlert={alert?.message?.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <Box style={{ width: "100%" }}>
                <Grid
                    container
                    style={{ marginBottom: "50px", marginTop: "30px" }}
                >
                    <Grid
                        container
                        item
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={5}
                    >
                        <Grid item>
                            <Typography
                                variant="h3"
                                style={{ textTransform: "capitalize" }}
                            >
                                {sensorType} projects
                            </Typography>
                        </Grid>
                        <Grid item>
                            {role <= roles.Customer && (
                                <IconButton
                                    disabled={
                                        role !== roles.Customer &&
                                        customerSelector.selected.length === 0
                                    }
                                    sx={{ ...styles.darkButton }}
                                    variant="contained"
                                    onClick={handleAddProject}
                                >
                                    <AddIcon sx={styles.addIcon} />
                                    Add Project
                                </IconButton>
                            )}
                        </Grid>
                        {sensorType === "standard" && (
                            <Grid item>
                                <IconButton
                                    disabled={
                                        role !== roles.Customer &&
                                        customerSelector.selected.length === 0
                                    }
                                    sx={{ ...styles.darkButton }}
                                    variant="contained"
                                    onClick={() => setShowTemplateDialog(true)}
                                >
                                    <SettingsIcon sx={styles.settingsIcon} />
                                    Manage templates
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={3}
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Typography sx={styles.typographySmall}>
                                Sort by:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SortSelector
                                sortValue={getSortOrder()}
                                callback={handleSort}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        alignContent="center"
                        xs={4}
                    >
                        <Pagination count={pages} onChange={paginate} />
                    </Grid>
                </Grid>
                {projects.isLoading ? (
                    <CircularProgress size={100} />
                ) : projects.items.length > 0 ? (
                    <ProjectCards
                        projects={currentProjects}
                        callback={deleteProjectCallback}
                        customerId={customerSelector.selected}
                        sensorType={sensorType}
                        role={role}
                    />
                ) : (
                    <Typography>
                        No projects of type {sensorType} found
                    </Typography>
                )}
            </Box>
        </Grid>
    );
}

export default Projects;
