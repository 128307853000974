import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ProjectButtons from "./ProjectButtons";
import Projects from "./Projects";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";

const styles = {
    grid: {
        marginTop: "45px",
        marginLeft: "75px",
        alignItems: "center",
        width: "100%",
    },
};

export default function Dashboard() {
    const path = useLocation().pathname.split("/").at(-1);
    const [alert, setAlert] = useState({ type: "error", message: "" });

    return (
        <Grid container sx={styles.grid}>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <Grid spacing={3} container item alignItems="center">
                <Grid item>
                    <DashboardIcon sx={{ fontSize: 25 }} />
                </Grid>
                <Grid item>
                    <Typography fontSize={19} fontFamily="Arial">
                        Dashboard
                    </Typography>
                </Grid>
                <Grid item>
                    <div className="icon-centering">
                        <ArrowForwardIosIcon />
                    </div>
                </Grid>
                <Grid item>
                    <ProjectButtons basePath="dashboard" />
                </Grid>
            </Grid>
            <Grid container style={{ paddingTop: "20px" }}>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate replace to="/dashboard/standard" />}
                    />
                    <Route
                        path={`/${path}`}
                        element={<Projects sensorType={path} />}
                    />
                </Routes>
            </Grid>
        </Grid>
    );
}
