import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as sensorService from "../../Services/sensorService";
import CheckBoxForRole from "../ReusableComponents/CheckBoxForRole";
import * as projectService from "../../Services/projectService";

const styles = {
    dataGridGrid: {
        marginLeft: "2%",
        marginRight: "2%",
        height: "65vh",
    },
};

export default function CreateAdminTemplate({
    onAlert,
    selectedTemplate,
    setSelectedTemplate,
    setTemplates,
    open = false,
}) {
    const [sensorTypes, setSensorTypes] = useState([]);
    const [selectedSensorType, setSelectedSensorType] = useState();
    const [partners, setPartners] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [sensorTemplateOwner, setSensorTemplateOwner] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [newTemplate, setNewTemplate] = useState(true);

    const handleSelectedTypeChange = (event) => {
        setSelectedSensorType(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.nativeEvent.submitter.name === "TEMPLATESAVE") {
            const sensorT = {
                name: selectedTemplate.name,
                backMat: selectedTemplate.backMat,
                frontMat: selectedTemplate.frontMat,
                description: selectedTemplate.description,
                sensorTypeId: `${selectedSensorType.id}`,
            };
            sensorService
                .createSensorTemplate(
                    sensorT,
                    selectedPartners.concat(selectedCustomers)
                )
                .then((res) => {
                    setTemplates((t) =>
                        [
                            ...t.filter((f) => f.id !== res.data.data.id),
                            res.data.data,
                        ].sort((a, b) => a.id - b.id)
                    );
                    onAlert({
                        type: "success",
                        message: "Successfully created template",
                    });
                })
                .catch((err) =>
                    onAlert({ type: "error", message: err.message })
                );
        } else if (event.nativeEvent.submitter.name === "UPDATETEMPLATE") {
            const sensorT = {
                id: selectedTemplate.id,
                name: selectedTemplate.name,
                backMat: selectedTemplate.backMat,
                frontMat: selectedTemplate.frontMat,
                description: selectedTemplate.description,
                sensorTypeId: `${selectedSensorType.id}`,
            };
            sensorService
                .updateSensorTemplate(
                    sensorT,
                    selectedPartners.concat(selectedCustomers)
                )
                .then((res) => {
                    setTemplates((t) =>
                        [
                            ...t.filter((f) => f.id !== res.data.data.id),
                            res.data.data,
                        ].sort((a, b) => a.id - b.id)
                    );
                    onAlert({
                        type: "success",
                        message: "Successfully updated template",
                    });
                })
                .catch((err) =>
                    onAlert({ type: "error", message: err.message })
                );
        }
    };

    useEffect(() => {
        if (open) {
            if (sensorTypes.length === 0) {
                sensorService
                    .getSensorTypes()
                    .then((res) => {
                        setSensorTypes(res.data.sensorTypes);
                    })
                    .catch((err) =>
                        onAlert({ type: "error", message: err.message })
                    );
                projectService
                    .getPartners()
                    .then((res) => setPartners(res.data.partners))
                    .catch(() =>
                        onAlert({
                            type: "error",
                            message: `failed to get partners`,
                        })
                    );
                projectService
                    .getCustomers()
                    .then((res) => setCustomers(res.data.customers))
                    .catch(() =>
                        onAlert({
                            type: "error",
                            message: `failed to get customers`,
                        })
                    );
            } else {
                setNewTemplate(!selectedTemplate.id);
                setSelectedSensorType(
                    sensorTypes[selectedTemplate.sensorTypeId - 1]
                );
                if (selectedTemplate.id) {
                    sensorService
                        .getSensorTemplatesOwner(selectedTemplate.id)
                        .then((res) => {
                            setSensorTemplateOwner(res.data.owners);
                            setSelectedCustomers(
                                customers
                                    .filter(
                                        (customer) =>
                                            res.data.owners.filter(
                                                (owner) =>
                                                    owner.userId === customer.id
                                            ).length !== 0
                                    )
                                    .map((c) => c.id)
                            );
                            setSelectedPartners(
                                partners
                                    .filter(
                                        (partner) =>
                                            res.data.owners.filter(
                                                (owner) =>
                                                    owner.userId === partner.id
                                            ).length !== 0
                                    )
                                    .map((p) => p.id)
                            );
                        })
                        .catch((err) =>
                            onAlert({ type: "error", message: err.message })
                        );
                } else {
                    setSelectedCustomers([]);
                    setSelectedPartners([]);
                    setSensorTemplateOwner([]);
                    setSelectedSensorType("");
                }
            }
        }
    }, [
        open,
        selectedTemplate.id,
        onAlert,
        customers,
        partners,
        selectedTemplate.sensorTypeId,
        sensorTypes,
    ]);
    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container sx={{ ...styles.gridMargin }} spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        value={selectedTemplate.name || ""}
                        onChange={(e) =>
                            setSelectedTemplate({
                                ...selectedTemplate,
                                name: e.target.value,
                            })
                        }
                    />
                </Grid>
            </Grid>

            {selectedSensorType &&
            (selectedSensorType.name === "Concrete" ||
                selectedSensorType.name === "Betong") ? (
                <Grid container sx={{ ...styles.gridMargin }} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="devEUI"
                            value={selectedTemplate.devEUI || ""}
                            onChange={(e) =>
                                setSelectedTemplate({
                                    ...selectedTemplate,
                                    devEUI: e.target.value,
                                })
                            }
                        />
                    </Grid>
                </Grid>
            ) : null}
            <Grid container sx={{ ...styles.gridMargin }} spacing={2}>
                <Grid container item xs={5}>
                    <FormControl fullWidth>
                        <InputLabel>Sensor type</InputLabel>
                        <Select
                            value={selectedSensorType || ""}
                            label="SensorType"
                            onChange={handleSelectedTypeChange}
                        >
                            {sensorTypes.map((types) => (
                                <MenuItem value={types} key={types.id}>
                                    {types.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {selectedSensorType?.name !== "Concrete" &&
            selectedSensorType?.name !== "Betong" ? (
                <Grid container sx={{ ...styles.gridMargin }} spacing={2}>
                    <Grid container item xs={7}>
                        <TextField
                            fullWidth
                            label="Adhesive side"
                            value={selectedTemplate.frontMat || ""}
                            onChange={(e) =>
                                setSelectedTemplate({
                                    ...selectedTemplate,
                                    frontMat: e.target.value,
                                })
                            }
                        />
                    </Grid>
                    <Grid container sx={{ ...styles.gridMargin }} item xs={5}>
                        <TextField
                            fullWidth
                            label="Measurement side"
                            value={selectedTemplate.backMat || ""}
                            onChange={(e) =>
                                setSelectedTemplate({
                                    ...selectedTemplate,
                                    backMat: e.target.value,
                                })
                            }
                        />
                    </Grid>
                </Grid>
            ) : null}
            <Grid container sx={{ ...styles.gridMargin }} spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={8}
                        label="Description"
                        value={selectedTemplate.description || ""}
                        onChange={(e) =>
                            setSelectedTemplate({
                                ...selectedTemplate,
                                description: e.target.value,
                            })
                        }
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ ...styles.gridMargin }} spacing={4}>
                <Grid item xs={12}>
                    <CheckBoxForRole
                        partners={partners}
                        customers={customers}
                        partnersCB={setSelectedPartners}
                        customersCB={setSelectedCustomers}
                        selectedList={sensorTemplateOwner}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                sx={{ ...styles.gridMargin }}
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                {newTemplate ? (
                    <Grid item xs={5}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            value="TEMPLATESAVE"
                            name="TEMPLATESAVE"
                            disabled={
                                selectedTemplate.name === "" ||
                                !selectedTemplate ||
                                selectedSensorType === "" ||
                                !selectedSensorType
                            }
                        >
                            Save template
                        </Button>
                    </Grid>
                ) : (
                    <Grid item xs={5}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            value="UPDATETEMPLATE"
                            name="UPDATETEMPLATE"
                            disabled={
                                selectedTemplate.name === "" ||
                                !selectedTemplate ||
                                selectedSensorType === "" ||
                                !selectedSensorType
                            }
                        >
                            Update template
                        </Button>
                    </Grid>
                )}
            </Grid>
        </form>
    );
}
