import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Chip, Grid, ToggleButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import CustomToolbar from "../ReusableComponents/CustomToolbarDataGrid";
import AddEditDataDialog from "./AddEditDataDialog";
import * as sensorService from "../../Services/sensorService";
import SensorChart from "./SensorChart";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import AlertDialog from "../ReusableComponents/AlertDialog";
import { useSensorDataContext } from "./Contexts/SensorDataContext";

dayjs.extend(utc);

const styles = {
    row: {
        paddingLeft: "10px",
        paddingRight: "5px",
        justifyContent: "center",
        paddingTop: "20px",
    },
    disableMuiMultiSelect: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
                display: "none !important",
            },
    },
};

function Measurements({ sensorType, selectedSensors }) {
    const [selectionModel, setSelectionModel] = useState<any[]>([]);
    const [showAddDataDialog, setShowAddDataDialog] = useState(false);
    const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
    const [toggleGraph, setToggleGraph] = useState("both");
    const [alert, setAlert] = useState({ type: "error", message: "" });
    const { sensorData, setSensorData } = useSensorDataContext();
    const [filteredData, setFilteredData] = useState({
        sensors: [],
        data: sensorData,
    });

    const isConcrete = sensorType === "concrete";
    const isScreed = sensorType === "screed";
    const isActiveScreed = sensorType === "activescreed";
    const showChart = isConcrete || isScreed || isActiveScreed;
    const [correctedRH, setCorrectedRH] = useState(isScreed);

    const columnWidths = {
        id: 68,
        serialNumber: 80,
        name: 150,
        rh: 68,
        temp: 68,
        datetime: 170,
        screedDepth: 105,
        screedBlockSize: 85,
        screedTypeName: 100,
    };

    const roundedToOneDecimal = (value) => {
        if (value == null) return "";
        return Number(value).toFixed(1);
    };

    const getRHValue = (params) =>
        correctedRH ? params.row.rhCorr ?? params.row.rh : params.row.rh;

    const screedRhColumn = correctedRH
        ? {
              field: "rhCorr",
              backupField: "rh",
              headerName: "Corr RH %",
              minWidth: columnWidths.rh,
              align: "right",
              valueFormatter: (params) => roundedToOneDecimal(params.value),
              valueGetter: (params) => getRHValue(params),
          }
        : {
              field: "rh",
              headerName: "RH %",
              minWidth: columnWidths.rh,
              align: "right",
              valueFormatter: (params) => roundedToOneDecimal(params.value),
          };

    const dataColumns = isConcrete
        ? [
              {
                  field: "id",
                  headerName: "ID",
                  width: columnWidths.id,
                  hide: true,
              },
              {
                  field: "serialNumber",
                  headerName: "Sensor ID",
                  minWidth: columnWidths.serialNumber,
              },
              {
                  field: "name",
                  headerName: "Sensor name",
                  flex: 1,
                  minWidth: columnWidths.name,
              },
              {
                  field: "rh1",
                  headerName: "RH1",
                  minWidth: columnWidths.rh,
                  width: columnWidths.rh,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "rh2",
                  headerName: "RH2",
                  minWidth: columnWidths.rh,
                  width: columnWidths.rh,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "rh3",
                  headerName: "RH3",
                  minWidth: columnWidths.rh,
                  width: columnWidths.rh,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "temp1",
                  headerName: "Temp1",
                  minWidth: columnWidths.temp,
                  width: columnWidths.temp,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "temp2",
                  headerName: "Temp2",
                  minWidth: columnWidths.temp,
                  width: columnWidths.temp,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "temp3",
                  headerName: "Temp3",
                  minWidth: columnWidths.temp,
                  width: columnWidths.temp,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "datetime",
                  headerName: "Date",
                  headerAlign: "center",
                  minWidth: columnWidths.datetime,
                  align: "center",
              },
          ]
        : isScreed
        ? [
              {
                  field: "id",
                  headerName: "ID",
                  width: columnWidths.id,
                  hide: true,
              },
              {
                  field: "serialNumber",
                  headerName: "Sensor ID",
                  minWidth: columnWidths.serialNumber,
              },
              {
                  field: "name",
                  headerName: "Sensor name",
                  flex: 1,
                  minWidth: columnWidths.name,
              },
              screedRhColumn,
              {
                  field: "temperature",
                  headerName: "Temp",
                  minWidth: columnWidths.temp,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "screedDepth",
                  headerName: "Screed depth",
                  minWidth: columnWidths.screedDepth,
                  align: "right",
              },
              {
                  field: "screedBlockSize",
                  headerName: "Block size",
                  minWidth: columnWidths.screedBlockSize,
                  align: "right",
              },
              {
                  field: "screedTypeName",
                  headerName: "Type",
                  minWidth: columnWidths.screedTypeName,
                  align: "right",
              },
              {
                  field: "datetime",
                  headerName: "Date",
                  minWidth: columnWidths.datetime,
                  align: "center",
                  headerAlign: "center",
              },
          ]
        : isActiveScreed
        ? [
              {
                  field: "id",
                  headerName: "ID",
                  width: columnWidths.id,
                  hide: true,
              },
              {
                  field: "serialNumber",
                  headerName: "Sensor ID",
                  minWidth: columnWidths.serialNumber,
              },
              {
                  field: "name",
                  headerName: "Sensor name",
                  flex: 1,
                  minWidth: columnWidths.name,
              },
              screedRhColumn,
              {
                  field: "temperature",
                  headerName: "Temp",
                  minWidth: columnWidths.temp,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "screedDepth",
                  headerName: "Screed depth",
                  minWidth: columnWidths.screedDepth,
                  align: "right",
              },
              {
                  field: "screedBlockSize",
                  headerName: "Block size",
                  minWidth: columnWidths.screedBlockSize,
                  align: "right",
              },
              {
                  field: "datetime",
                  headerName: "Date",
                  minWidth: columnWidths.datetime,
                  align: "center",
                  headerAlign: "center",
              },
          ]
        : [
              {
                  field: "id",
                  headerName: "ID",
                  width: columnWidths.id,
                  hide: true,
              },
              {
                  field: "serialNumber",
                  headerName: "Sensor ID",
                  minWidth: columnWidths.serialNumber,
              },
              {
                  field: "name",
                  headerName: "Sensor name",
                  flex: 1,
                  minWidth: columnWidths.name,
              },
              {
                  field: "rh",
                  headerName: "RH %",
                  minWidth: columnWidths.rh,
                  align: "right",
                  valueFormatter: (params) => roundedToOneDecimal(params.value),
              },
              {
                  field: "datetime",
                  headerName: "Date",
                  minWidth: columnWidths.datetime,
                  align: "center",
                  headerAlign: "center",
              },
          ];

    useEffect(() => {
        const reduceSelectedSensors = selectedSensors;
        if (reduceSelectedSensors?.length > 0) {
            const sensorIds =
                sensorType === "concrete" || sensorType === "activescreed"
                    ? selectedSensors.map((sensor) => sensor.deveui)
                    : selectedSensors.map((sensor) => sensor.id);
            sensorService.getSensorData(sensorIds, sensorType).then((res) => {
                let newSelectedData: any[] = [];
                if (res.data.data !== undefined) {
                    res.data.data.forEach((data) => {
                        const { screedDepth, screedBlockSize } = isScreed
                            ? selectedSensors.find(
                                  (sensor) => sensor.id === data.sensorId
                              )
                            : isActiveScreed
                            ? selectedSensors.find(
                                  (sensor) => sensor.deveui === data.deveui
                              )
                            : {
                                  screedDepth: null,
                                  screedBlockSize: null,
                              };
                        const screedTypeName = isScreed
                            ? data.screedTypeName
                            : null;

                        const { serialNumber, name, color } =
                            isConcrete || isActiveScreed
                                ? selectedSensors.find(
                                      (sensor) => sensor.deveui === data.deveui
                                  )
                                : selectedSensors.find(
                                      (sensor) => sensor.id === data.sensorId
                                  );
                        const datetime = dayjs.utc(data.datetime);
                        newSelectedData = [
                            ...newSelectedData,
                            {
                                ...data,
                                serialNumber,
                                screedDepth,
                                screedBlockSize,
                                screedTypeName,
                                name,
                                color,
                                datetime: `${datetime
                                    .local()
                                    .format("YYYY-MM-DD")} ${datetime
                                    .local()
                                    .format("HH:mm")}`,
                            },
                        ];
                    });
                }
                setSensorData(newSelectedData);
            });
        } else {
            setSensorData([]);
        }
    }, [
        isConcrete,
        isScreed,
        isActiveScreed,
        selectedSensors,
        sensorType,
        setSensorData,
    ]);

    useEffect(() => {
        if (!(isScreed || isActiveScreed)) {
            setFilteredData({ sensors: selectedSensors, data: sensorData });
        } else {
            const newData = sensorData.filter((d) => {
                const sensor = selectedSensors.find(
                    (s) => s.serialNumber === d.serialNumber
                );

                if (sensor != null) {
                    const depth = sensor.screedDepth;
                    const blockSize = sensor.screedBlockSize;

                    if (depth != null && blockSize != null) {
                        return depth - blockSize > 0;
                    }
                }
                return true;
            });

            setFilteredData({ sensors: selectedSensors, data: newData });
        }
    }, [sensorData, isScreed, isActiveScreed, selectedSensors]);

    const addEditDataCallback = (newValue) => {
        const index = sensorData.findIndex(
            (data) => data.id === newValue[0].id
        );
        if (index === -1) {
            const newDate = [...sensorData, ...newValue];
            newDate.sort(
                (a, b) =>
                    new Date(b.datetime).getTime() -
                    new Date(a.datetime).getTime()
            );
            setSensorData(newDate);
        } else {
            setSensorData([
                ...sensorData.map((t, i) => (i === index ? newValue[0] : t)),
            ]);
        }
    };

    const handleSelect = (event: GridSelectionModel) => {
        if (event.length > 1) {
            const selectionSet = new Set(selectionModel);
            const result = event.filter((e) => !selectionSet.has(e));
            setSelectionModel(result);
        } else {
            setSelectionModel(event);
        }
    };

    const handleConcreteToggleButtons = () => (
        <>
            <Button
                variant={toggleGraph === "temp" ? "outlined" : "text"}
                onClick={() => setToggleGraph("temp")}
            >
                Temp
            </Button>
            <Button
                variant={toggleGraph === "rh" ? "outlined" : "text"}
                onClick={() => setToggleGraph("rh")}
            >
                Rh
            </Button>
            <Button
                variant={toggleGraph === "both" ? "outlined" : "text"}
                onClick={() => setToggleGraph("both")}
            >
                Temp & Rh
            </Button>
        </>
    );

    const handleCorrectedRHButtons = () => (
        <ToggleButton
            color="primary"
            value="checked"
            selected={correctedRH}
            sx={{ paddingY: 0.75, paddingX: 1 }}
            onClick={() => setCorrectedRH(!correctedRH)}
        >
            Corrected RH
        </ToggleButton>
    );

    const deleteSensorData = (resp) => {
        if (resp) {
            const dataId = selectionModel[0];
            if (dataId) {
                sensorService
                    .deleteSensorData(dataId, sensorType)
                    .then((res) => {
                        if (res.data.status) {
                            const temp = [...sensorData];
                            const sliceIndex = temp.findIndex(
                                (x) => x.id === dataId
                            );
                            if (sliceIndex === 0) {
                                setSensorData(temp.slice(1));
                            } else if (sliceIndex === temp.length - 1) {
                                setSensorData(temp.slice(0, -1));
                            } else if (sliceIndex > 0) {
                                setSensorData(
                                    [].concat(
                                        temp.slice(0, sliceIndex),
                                        temp.slice(sliceIndex + 1)
                                    )
                                );
                            }
                        }
                    })
                    .catch((error) => setAlert(error.response.data.error));
            }
        }
    };

    return (
        <>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <Grid container item xs={6} justifyContent="center">
                {showChart ? handleConcreteToggleButtons() : null}
            </Grid>
            {showChart ? (
                <Grid item container sx={{ ...styles.row }}>
                    <SensorChart
                        selectedSensorsData={filteredData}
                        toggle={toggleGraph}
                        sensorType={sensorType}
                        correctedRH={correctedRH}
                    />
                </Grid>
            ) : null}
            {isScreed || isActiveScreed ? (
                <Grid container item xs={6} justifyContent="center">
                    {showChart ? handleCorrectedRHButtons() : null}
                </Grid>
            ) : null}
            <Grid item container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h4">Sensor Data</Typography>
                </Grid>
                <Grid item>
                    <Chip
                        disabled={
                            (selectedSensors.length !== 1 &&
                                selectionModel.length === 0) ||
                            sensorType === "concrete" ||
                            sensorType === "activescreed"
                        }
                        label={
                            selectionModel.length === 1
                                ? "Edit selected"
                                : "Add Data"
                        }
                        icon={
                            selectionModel.length === 1 ? (
                                <EditIcon style={{ color: "blue" }} />
                            ) : (
                                <AddIcon style={{ color: "green" }} />
                            )
                        }
                        onClick={() => {
                            setShowAddDataDialog(true);
                        }}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        disabled={selectionModel.length !== 1}
                        label="Delete selected"
                        icon={<DeleteIcon style={{ color: "#e00" }} />}
                        onClick={() => {
                            setShowDeleteAlertDialog(true);
                        }}
                    />
                </Grid>
            </Grid>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{ field: "datetime", sort: "desc" }],
                    },
                }}
                sx={styles.disableMuiMultiSelect}
                rows={filteredData.data}
                columns={dataColumns}
                checkboxSelection={!isConcrete && !isActiveScreed}
                disableSelectionOnClick
                autoPageSize
                density="compact"
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{ toolbar: { data: sensorData, dataColumns } }}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelect}
            />
            {showAddDataDialog ? (
                <AddEditDataDialog
                    // Only possible to have on selected at a time
                    measurement={sensorData.find(
                        (data) => data.id === selectionModel[0]
                    )}
                    sensorType={sensorType}
                    isEditing={selectionModel.length === 1}
                    closeCallback={setShowAddDataDialog}
                    selectedSensor={selectedSensors[0]} // Can only add when one sensor is selected
                    addEditDataCallback={addEditDataCallback}
                />
            ) : null}
            {showDeleteAlertDialog ? (
                <AlertDialog
                    header="Delete Measurement"
                    body="Are you sure you want to delete the measurement?"
                    confirmButton="Delete"
                    setCallback={setShowDeleteAlertDialog}
                    callback={deleteSensorData}
                />
            ) : null}
        </>
    );
}

export default Measurements;
