import React, { useState, useMemo } from "react";

export const SensorDataContext = React.createContext();

export function SensorDataContextProvider(props) {
    const [sensorData, setSensorData] = useState([]);

    const sensorDataValue = useMemo(
        () => ({ sensorData, setSensorData }),
        [sensorData, setSensorData]
    );

    return (
        <SensorDataContext.Provider value={sensorDataValue}>
            {props.children}
        </SensorDataContext.Provider>
    );
}

export const useSensorDataContext = () => React.useContext(SensorDataContext);
