import { useEffect, useState } from "react";
import {
    DialogContent,
    TextField,
    Select,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Dialog,
    DialogTitle,
    Autocomplete,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import * as sensorService from "../Services/sensorService";
import TopBar from "./ReusableComponents/TopBar";
import SystemAlertSnackbar from "./ReusableComponents/SystemAlertSnackbar";
import WarningDialog from "./ReusableComponents/WarningDialog";
import { sensorIsTwoPointZero } from "../Utils/sensorUtils";
import dayjs from "dayjs";

const styles = {
    dialogPaper: {
        minHeight: "80%",
        maxHeight: "80%",
        maxWidth: "90%",
        minWidth: "90%",
    },
    gridMargin: {
        marginTop: "20px",
    },
};

const screedBlockSizes = ["20", "35", "50"];
const screedWarningCloseToSurface =
    "Sensor is very close to surface, will probably show the RH value in the room, not in the screed.";
const screedWarningAboveSurface =
    "Block Size is larger than Screed Depth, please check the values.";

export default function CreateEditSensor() {
    const {
        sensorTypes,
        update,
        num,
        objectId,
        id,
        name,
        color,
        selectedSensorType,
        technician,
        installationDate,
        description,
        back,
        front,
        devEUI,
        screedDepth,
        screedBlockSize,
        screedType,
        reportInterval,
    } = useLocation().state;
    const [alert, setAlert] = useState({ type: "error", message: "" });

    const [sensorType, setSensorType] = useState(selectedSensorType);
    const [sensor, setSensor] = useState({
        sensorId: id,
        name,
        color,
        sensorTypeId: selectedSensorType.id,
        sensorTypeName: selectedSensorType.name,
        technician: technician ?? "",
        installationDate: dayjs(installationDate, "YYYY-MM-DD").isValid()
            ? installationDate
            : dayjs().format("YYYY-MM-DD"),
        description: description ?? "",
        frontMat: front ?? "",
        backMat: back ?? "",
        num,
        objectId,
        devEUI,
        screedDepth,
        screedBlockSize,
        screedType,
        reportInterval,
    });

    const [templates, setTemplates] = useState([
        { id: -1, name: "No template" },
    ]);
    const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
    const [screedTypes, setScreedTypes] = useState([]);
    const [selectedScreedType, setSelectedScreedType] = useState(null);
    const [showScreedWarningDialog, setShowScreedWarningDialog] =
        useState(false);
    const [screedWarning, setScreedWarning] = useState("");
    const [isConcreteSensorTwoPointZero, setIsConcreteSensorTwoPointZero] =
        useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        sensorService
            .getSensorTemplates(sensorType)
            .then((res) => {
                setTemplates((aT) => [...aT, ...res.data.templates]);
            })
            .catch((err) => setAlert({ type: "error", message: err.message }));

        if (
            sensorType.name === "Screed" ||
            sensorType.name === "activescreed"
        ) {
            const screedTypeToUse = sensor.screedType ?? screedType;
            sensorService
                .screedTypes()
                .then((res) => {
                    setScreedTypes(res.data.screedTypes);
                    setSelectedScreedType(
                        res.data.screedTypes.find(
                            (type) => type.id === screedTypeToUse
                        )
                    );
                })
                .catch((err) =>
                    setAlert({
                        type: "error",
                        message: err.response.data.message,
                    })
                );
        } else if (sensorType.name === "Concrete" && sensor.devEUI) {
            setIsConcreteSensorTwoPointZero(
                sensorIsTwoPointZero(sensor.devEUI)
            );
        }
    }, [sensorType, screedType, sensor]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.nativeEvent.submitter.name === "TEMPLATESAVE") {
            const sensorT = {
                name: sensor.name,
                backMat: sensor.backMat,
                frontMat: sensor.frontMat,
                description: sensor.description,
                sensorTypeId: `${sensorType.id}`,
            };
            sensorService
                .createSensorTemplate(sensorT)
                .then((res) => {
                    setTemplates([...templates, res.data.data]);
                    setAlert({
                        type: "success",
                        message: "Successfully created template",
                    });
                })
                .catch((err) =>
                    setAlert({ type: "error", message: err.message })
                );
        } else if (event.nativeEvent.submitter.name === "SAVEEDIT") {
            sensorService
                .updateSensor(sensor)
                .then(() => {
                    setAlert({
                        type: "success",
                        message: "Successfully updated sensor",
                    });

                    if (sensorType.name === "Screed") {
                        const depth = sensor.screedDepth;
                        const blockSize = sensor.screedBlockSize;

                        if (depth && blockSize) {
                            const diff = Number(depth) - blockSize;

                            if (diff <= 0) {
                                setScreedWarning(screedWarningAboveSurface);
                                setShowScreedWarningDialog(true);
                            } else if (diff < 10) {
                                setScreedWarning(screedWarningCloseToSurface);
                                setShowScreedWarningDialog(true);
                            }
                        }
                    }
                })
                .catch((err) => {
                    const { title, message } = err.response.data.error;
                    setAlert({
                        type: "error",
                        message: `${title}: ${message}`,
                    });
                });
        } else if (event.nativeEvent.submitter.name === "CREATE") {
            sensorService
                .createSensor(sensor)
                .then(() => {
                    setAlert({
                        type: "success",
                        message: "Successfully created sensor",
                    });
                    const projectType =
                        localStorage.getItem("projectType") ?? "standard";
                    navigate(`/object/${objectId}/${projectType}`);
                })
                .catch((err) => {
                    const { title, message } = err.response.data.error;
                    setAlert({
                        type: "error",
                        message: `${title}: ${message}`,
                    });
                });
        }
    };

    const handleAdminTemplateChange = (event) => {
        setSelectedTemplate(event.target.value);
        if (event.target.value.name === "No template") {
            setSensor(() => ({
                ...sensor,
                name: "",
                backMat: "",
                frontMat: "",
                description: "",
                sensorTypeId: selectedSensorType.id,
            }));
            setSensorType(selectedSensorType);
        } else {
            const type = sensorTypes.find(
                (sType) => sType.id === event.target.value.sensorTypeId
            );
            setSensor(() => ({
                ...sensor,
                name: event.target.value.name,
                backMat: event.target.value.frontMat,
                frontMat: event.target.value.backMat,
                description: event.target.value.description,
                sensorTypeId: type.id,
            }));
            setSensorType(type);
        }
    };

    return (
        <Dialog
            open
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle style={{ padding: "0px" }}>
                <TopBar
                    title="Create/Edit Sensor"
                    previousPath={`/object/${objectId}`}
                    canClose={false}
                />
            </DialogTitle>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid container item xs={6} sx={{ ...styles.gridMargin }}>
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Name"
                                        value={sensor.name}
                                        onChange={(e) =>
                                            setSensor({
                                                ...sensor,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                {sensorType.name === "Concrete" ||
                                sensorType.name === "activescreed" ? (
                                    <>
                                        <Grid
                                            item
                                            xs={
                                                isConcreteSensorTwoPointZero ||
                                                sensorType.name ===
                                                    "activescreed"
                                                    ? 6
                                                    : 12
                                            }
                                        >
                                            <TextField
                                                fullWidth
                                                label="devEUI"
                                                value={sensor.devEUI}
                                                onChange={(e) =>
                                                    setSensor({
                                                        ...sensor,
                                                        devEUI: e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        {isConcreteSensorTwoPointZero ||
                                        sensorType.name === "activescreed" ? (
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="reportIntervalSelectLabel">
                                                        Report interval
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="reportIntervalSelectLabel"
                                                        label="Report interval"
                                                        id="reportIntervalSelect"
                                                        value={
                                                            sensor.reportInterval ??
                                                            120
                                                        }
                                                        onChange={(e) => {
                                                            const minutes =
                                                                e.target.value;
                                                            setSensor({
                                                                ...sensor,
                                                                reportInterval:
                                                                    minutes,
                                                            });
                                                        }}
                                                    >
                                                        <MenuItem value={60}>
                                                            1h
                                                        </MenuItem>
                                                        <MenuItem value={120}>
                                                            2h
                                                        </MenuItem>
                                                        <MenuItem value={240}>
                                                            4h
                                                        </MenuItem>
                                                        <MenuItem value={480}>
                                                            8h
                                                        </MenuItem>
                                                        <MenuItem value={720}>
                                                            12h
                                                        </MenuItem>
                                                        <MenuItem value={1440}>
                                                            24h
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        ) : null}
                                    </>
                                ) : null}
                                {sensorType.name === "Screed" ||
                                sensorType.name === "activescreed" ? (
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Screed Depth"
                                                type="number"
                                                value={sensor.screedDepth}
                                                onChange={(e) =>
                                                    setSensor({
                                                        ...sensor,
                                                        screedDepth:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                disablePortal
                                                options={screedBlockSizes}
                                                value={
                                                    sensor.screedBlockSize?.toString() ??
                                                    ""
                                                }
                                                onChange={(event, newValue) => {
                                                    setSensor({
                                                        ...sensor,
                                                        screedBlockSize:
                                                            newValue,
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Screed block size (mm)"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                                {(sensorType.name === "Screed" ||
                                    sensorType.name === "activescreed") && (
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={screedTypes}
                                            getOptionLabel={(option) =>
                                                option.name ?? ""
                                            }
                                            value={selectedScreedType ?? ""}
                                            onChange={(event, newValue) => {
                                                setSensor({
                                                    ...sensor,
                                                    screedType: newValue?.id,
                                                });
                                                setSelectedScreedType(newValue);
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Screed type"
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid item container xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Color"
                                        type="color"
                                        value={sensor.color}
                                        onChange={(e) =>
                                            setSensor({
                                                ...sensor,
                                                color: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled
                                            fullWidth
                                            label="Type"
                                            value={
                                                sensorType.name ===
                                                "activescreed"
                                                    ? "Active screed"
                                                    : sensorType.name
                                            }
                                            onChange={(e) =>
                                                setSensor({
                                                    ...sensor,
                                                    selectedSensorType:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Technician"
                                        value={sensor.technician}
                                        onChange={(e) =>
                                            setSensor({
                                                ...sensor,
                                                technician: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid container item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Installation Date"
                                        type="date"
                                        value={sensor.installationDate}
                                        onChange={(e) =>
                                            setSensor({
                                                ...sensor,
                                                installationDate:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                {sensorType.name !== "Concrete" &&
                                sensorType.name !== "Screed" &&
                                sensorType.name !== "activescreed" ? (
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Adhesive side"
                                                value={sensor.frontMat}
                                                onChange={(e) =>
                                                    setSensor({
                                                        ...sensor,
                                                        frontMat:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Measurement side"
                                                value={sensor.backMat}
                                                onChange={(e) =>
                                                    setSensor({
                                                        ...sensor,
                                                        backMat: e.target.value,
                                                    })
                                                }
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={8}
                                        label="Description"
                                        value={sensor.description}
                                        onChange={(e) =>
                                            setSensor({
                                                ...sensor,
                                                description: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        value={update ? "SAVEEDIT" : "CREATE"}
                                        name={update ? "SAVEEDIT" : "CREATE"}
                                    >
                                        {update ? "Save" : "Create"}
                                    </Button>
                                </Grid>
                                <Grid item xs={5}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        value="TEMPLATESAVE"
                                        name="TEMPLATESAVE"
                                        disabled={sensor.name === ""}
                                    >
                                        Save as template
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid container item xs={6} sx={{ ...styles.gridMargin }}>
                        <FormControl fullWidth>
                            <InputLabel>Admin Templates</InputLabel>
                            <Select
                                label="Admin Templates"
                                value={selectedTemplate}
                                onChange={handleAdminTemplateChange}
                            >
                                {templates.map((template) => (
                                    <MenuItem
                                        value={template}
                                        key={template.id}
                                    >
                                        {template.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            {showScreedWarningDialog ? (
                <WarningDialog
                    text={screedWarning}
                    setCallback={setShowScreedWarningDialog}
                />
            ) : null}
        </Dialog>
    );
}
