import { IconButton, InputAdornment, TextField, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Edit from "@mui/icons-material/Edit";
import { useState } from "react";

const styles = {
    editableTextField: {
        fontSize: 8,
        padding: "0px",
    },
};

const StyledTextField = styled(TextField)({
    "& .MuiInputBase-input.Mui-disabled": {
        color: "rgba(0, 0, 0, 1)",
    },
});

function EditableTextField({ name, editCallback }) {
    const [textField, setTextField] = useState({
        name,
        editMode: false,
        mouseOver: false,
    });

    const originalName = name;

    const handleChange = (event) => {
        setTextField({ ...textField, name: event.target.value });
    };

    const handleMouseOver = () => {
        if (!textField.mouseOver) {
            setTextField({ ...textField, mouseOver: true });
        }
    };

    const handleMouseOut = () => {
        if (textField.mouseOver) {
            setTextField({ ...textField, mouseOver: false });
        }
    };

    const handleEditClick = () => {
        setTextField({
            ...textField,
            editMode: true,
            mouseOver: false,
        });
    };

    const handleSaveClick = () => {
        if (originalName !== textField.name) {
            editCallback(textField.name);
        }
        setTextField({
            ...textField,
            editMode: false,
            mouseOver: false,
        });
    };

    return (
        <StyledTextField
            autoFocus
            sx={{ ...styles.editableTextField }}
            value={textField.name}
            variant={!textField.editMode ? "standard" : "outlined"}
            disabled={!textField.editMode}
            onChange={handleChange}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            InputProps={{
                disableUnderline: true,
                endAdornment: textField.editMode ? (
                    <InputAdornment position="end">
                        <IconButton onClick={handleSaveClick}>
                            <CheckIcon />
                        </IconButton>
                    </InputAdornment>
                ) : textField.mouseOver ? (
                    <InputAdornment position="end">
                        <IconButton onClick={handleEditClick}>
                            <Edit />
                        </IconButton>
                    </InputAdornment>
                ) : (
                    ""
                ),
            }}
        />
    );
}

export default EditableTextField;
