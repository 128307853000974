import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as api from "../../../Services/settingsService";

function AddMeasurer({ open, onCloseDialog, onAlert }) {
    const header = "Add Measurer";

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [username, setUsername] = useState("");

    const handleSave = () => {
        api.addMeasurer(username, password)
            .then(() => onCloseDialog(false))
            .catch((error) => onAlert(error.response.data.error));
    };

    return (
        <Dialog open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Measurer name"
                    variant="outlined"
                    margin="dense"
                    onChange={(text) => setUsername(text.target.value)}
                />
                <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    margin="dense"
                    type={showPassword ? "text" : "password"}
                    onChange={(text) => setPassword(text.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    label="Confirm password"
                    variant="outlined"
                    margin="dense"
                    type={showConfirm ? "text" : "password"}
                    onChange={(text) => setConfirm(text.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowConfirm(!showConfirm)}
                                >
                                    {showConfirm ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={
                        password.length === 0 ||
                        username.length === 0 ||
                        password !== confirm
                    }
                >
                    Save
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddMeasurer;
