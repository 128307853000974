import { Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React from "react";

function QuestionMarkToolTip({ text }) {
    return (
        <Tooltip title={<Typography>{text}</Typography>}>
            <p className="icon-centering">
                <HelpOutlineIcon />
            </p>
        </Tooltip>
    );
}

export default QuestionMarkToolTip;
