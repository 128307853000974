import randomcolor from "randomcolor";

function useCorrectedRh(depth, blockSize, typeName) {
    return depth && blockSize && typeName !== "Other";
}

function formatData(data, dateTime) {
    return { x: new Date(dateTime).valueOf(), y: data };
}

function formattedSensorData(selectedSensorsData, types, correctedRh = false) {
    const result = selectedSensorsData.reduce((arr, item) => {
        const {
            serialNumber,
            color,
            datetime,
            rh,
            rhCorr,
            temperature,
            screedDepth,
            screedBlockSize,
            screedTypeName,
        } = item;
        const existingItem = arr.find((obj) => obj.sensorId === serialNumber);
        const useCorrected = useCorrectedRh(
            screedDepth,
            screedBlockSize,
            screedTypeName
        );
        const includesRh = types.includes("rh");
        const includesTemp = types.includes("temperature");

        if (existingItem) {
            if (includesRh) {
                existingItem.rhData.push(
                    formatData(
                        correctedRh && useCorrected ? rhCorr : rh,
                        datetime
                    )
                );
            }

            if (includesTemp) {
                existingItem.temperatureData.push(
                    formatData(temperature, datetime)
                );
            }
        } else {
            const data = {};

            if (includesRh) {
                data.rhData = [
                    formatData(
                        correctedRh && useCorrected ? rhCorr : rh,
                        datetime
                    ),
                ];
            }

            if (includesTemp) {
                data.temperatureData = [formatData(temperature, datetime)];
            }

            arr.push({ sensorId: serialNumber, color, ...data });
        }

        return arr;
    }, []);

    return result;
}

/* function filteredSortedData(data, minDate, maxDate) {
    let firstIndex;
    let lastIndex;
    const result = { ...data };

    let index = 0;
    result.data.forEach((d) => {
        const date = new Date(d.x);
        if (!firstIndex && date >= minDate) firstIndex = index;
        if (!lastIndex && date >= maxDate) lastIndex = index;
        index += 1;
    });

    firstIndex = firstIndex ?? 0;
    lastIndex = lastIndex ?? result.data.length - 1;

    // Slice just outside of range to keep graph continous
    result.data = result.data.slice(firstIndex - 1, lastIndex + 1);

    return result;
} */

function getMinMaxDate(dates) {
    const minDate = new Date(dates[0].x);
    minDate.setDate(minDate.getDate() - 1);

    const maxDate = new Date(dates[dates.length - 1].x);
    maxDate.setDate(maxDate.getDate() + 1);

    return { minDate, maxDate };
}

function processRhData(rhData) {
    let rhMin = 100;
    let rhMax = 0;

    if (rhData.rhData.length === 1) {
        rhMax = rhData.rhData[0].y;
        rhMin = rhData.rhData[0].y;
    }

    const sortedRh = rhData.rhData.sort((a, b) => {
        if (rhMin > a.y || rhMin > b.y) {
            rhMin = a.y < b.y ? a.y : b.y;
        }

        if (rhMax < a.y || rhMax < b.y) {
            rhMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const minRh = Math.floor((rhMin - 1) / 5) * 5;
    const maxRh = Math.ceil((rhMax + 1) / 5) * 5;
    const { minDate, maxDate } = getMinMaxDate(sortedRh);

    return {
        rhData: {
            label: `Sensor: ${rhData.sensorId} (RH)`,
            data: [...sortedRh],
            yAxisID: "rh",
            fill: false,
            backgroundColor: randomcolor({ luminosity: "dark" }),
            borderColor: randomcolor({ luminosity: "dark" }),
        },
        minRh,
        maxRh,
        minDate,
        maxDate,
    };
}

function processTempData(tempData) {
    let tempMin = 1000;
    let tempMax = -1000;

    if (tempData.temperatureData.length === 1) {
        tempMax = tempData.temperatureData[0].y;
        tempMin = tempData.temperatureData[0].y;
    }

    const sortedTemp = tempData.temperatureData.sort((a, b) => {
        if (tempMin > a.y || tempMin > b.y) {
            tempMin = a.y < b.y ? a.y : b.y;
        }

        if (tempMax < a.y || tempMax < b.y) {
            tempMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const minTemp = Math.floor((tempMin - 1) / 5) * 5;
    const maxTemp = Math.ceil((tempMax + 1) / 5) * 5;
    const { minDate, maxDate } = getMinMaxDate(sortedTemp);

    return {
        tempData: {
            label: `Sensor: ${tempData.sensorId} (Temperature)`,
            data: sortedTemp,
            yAxisID: "temperature",
            fill: false,
            backgroundColor: randomcolor({ luminosity: "dark" }),
            borderColor: randomcolor({ luminosity: "dark" }),
        },
        minTemp,
        maxTemp,
        minDate,
        maxDate,
    };
}

export function generateRhChartData(selectedSensorsData, correctedRH = false) {
    const timeSeries = new Set();
    const sensorData = formattedSensorData(
        selectedSensorsData,
        ["rh"],
        correctedRH
    );

    const actualSensorData = sensorData.filter(
        (sensor) => sensor.rhData.length > 0
    );

    const rhDataList = [];
    let minRh = 100;
    let maxRh = -1;
    actualSensorData.forEach((sData) => {
        const sortedRh = sData.rhData.sort((a, b) => a.x - b.x);

        sortedRh.forEach((rh) => timeSeries.add(rh.x));

        const rhData = processRhData(sData);

        rhDataList.push(rhData.rhData);
        minRh = minRh > rhData.minRh ? rhData.minRh : minRh;
        maxRh = maxRh < rhData.maxRh ? rhData.maxRh : maxRh;
    });

    /* const rhDataListFiltered = rhDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    ); */

    const chartData = {
        rhData: rhDataList,
        minRh,
        maxRh,
    };

    return { chartData, timeSeries: [...timeSeries] };
}

export function generateTempChartData(selectedSensorsData) {
    const timeSeries = new Set();
    const sensorData = formattedSensorData(selectedSensorsData, [
        "temperature",
    ]);

    const actualSensorData = sensorData.filter(
        (sensor) => sensor.temperatureData.length > 0
    );

    const tempDataList = [];
    let minTemp = 1000;
    let maxTemp = 0;
    actualSensorData.forEach((sData) => {
        const sortedTemp = sData.temperatureData.sort((a, b) => a.x - b.x);

        sortedTemp.forEach((rh) => timeSeries.add(rh.x));
        const tempData = processTempData(sData);

        tempDataList.push(tempData.tempData);
        minTemp = minTemp > tempData.minTemp ? tempData.minTemp : minTemp;
        maxTemp = maxTemp < tempData.maxTemp ? tempData.maxTemp : maxTemp;
    });

    /* const tempDataListFiltered = tempDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    ); */

    const chartData = {
        tempData: tempDataList,
        minTemp,
        maxTemp,
    };

    return { chartData, timeSeries: [...timeSeries] };
}

export function generateBothChartData(
    selectedSensorsData,
    correctedRH = false
) {
    const timeSeries = new Set();
    const sensorData = formattedSensorData(
        selectedSensorsData,
        ["rh", "temperature"],
        correctedRH
    );

    const actualSensorData = sensorData.filter(
        (sensor) =>
            sensor.temperatureData.length > 0 && sensor.rhData.length > 0
    );

    const tempDataList = [];
    const rhDataList = [];
    let minTemp = 100;
    let maxTemp = 0;
    let minRh = 100;
    let maxRh = 0;
    actualSensorData.forEach((sData) => {
        const sortedRh = sData.rhData.sort((a, b) => a.x - b.x);

        sortedRh.forEach((rh) => timeSeries.add(rh.x));

        const tempData = processTempData(sData);
        const rhData = processRhData(sData);

        tempDataList.push(tempData.tempData);
        rhDataList.push(rhData.rhData);
        minTemp = minTemp > tempData.minTemp ? tempData.minTemp : minTemp;
        maxTemp = maxTemp < tempData.maxTemp ? tempData.maxTemp : maxTemp;
        minRh = minRh > rhData.minRh ? rhData.minRh : minRh;
        maxRh = maxRh < rhData.maxRh ? rhData.maxRh : maxRh;
    });

    /* const tempDataListFiltered = tempDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    );
    const rhDataListFiltered = rhDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    ); */

    const chartData = {
        tempData: tempDataList,
        rhData: rhDataList,
        minTemp,
        maxTemp,
        minRh,
        maxRh,
    };

    return { chartData, timeSeries: [...timeSeries] };
}

export function getMinMaxData(data) {
    let minDate;
    let maxDate;
    let minRh;
    let maxRh;
    let minTemp;
    let maxTemp;

    data.forEach((d) => {
        const dateTime = d.datetime;
        const minRhData = Math.min(d.rh, d.rhCorr);
        const maxRhData = Math.max(d.rh, d.rhCorr);

        if (!minDate || dateTime < minDate) minDate = dateTime;
        if (!maxDate || dateTime > maxDate) maxDate = dateTime;
        minRh = minRh ? Math.min(minRh, minRhData) : minRhData;
        maxRh = maxRh ? Math.max(maxRh, maxRhData) : maxRhData;
        minTemp = minTemp ? Math.min(minTemp, d.temperature) : d.temperature;
        maxTemp = maxTemp ? Math.max(maxTemp, d.temperature) : d.temperature;
    });

    return {
        minDate,
        maxDate,
        minRh,
        maxRh,
        minTemp,
        maxTemp,
    };
}
