import React, { useEffect, useState, memo } from "react";
import { Chip } from "@mui/material";

const styles = {
    display: "inline-block",
    transform: "rotate(-7deg)",
    WebkitTransform: "rotate(-7deg)",
};
const ChipDragPreview = memo(({ index, serialNumber, sensorColor, scale }) => {
    const [tickTock, setTickTock] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => setTickTock(!tickTock), 500);
        return () => clearInterval(interval);
    }, [tickTock]);
    return (
        <div style={styles} className="panningDisabled">
            <Chip
                key={`prev-${index}`}
                label={serialNumber}
                style={{
                    backgroundColor: sensorColor,
                    transform: `scale(${scale})`,
                }}
                className="panningDisabled"
            />
        </div>
    );
});

export default ChipDragPreview;
