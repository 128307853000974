import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setNewPassword } from "../../Services/settingsService";

function ChangePassword({ open, onCloseDialog, onAlert }) {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const header = "Change password";

    const handleSave = () => {
        setNewPassword(password)
            .then(() => {
                onCloseDialog(false);
            })
            .catch((err) => onAlert(err.response.data.error));
    };

    return (
        <Dialog open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent sx={{ maxWidth: "250px", paddingBottom: "0px" }}>
                <TextField
                    fullWidth
                    label="New password"
                    variant="outlined"
                    margin="dense"
                    type={showPassword ? "text" : "password"}
                    onChange={(text) => setPassword(text.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    label="Confirm password"
                    variant="outlined"
                    margin="dense"
                    type={showConfirm ? "text" : "password"}
                    onChange={(text) => setConfirm(text.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowConfirm(!showConfirm)}
                                >
                                    {showConfirm ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={password !== confirm || password.length === 0}
                >
                    Save
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangePassword;
