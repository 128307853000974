import {
    AppBar,
    Box,
    IconButton,
    InputAdornment,
    Grid,
    Typography,
    OutlinedInput,
    Stack,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const styles = {
    appBar: {
        backgroundColor: "#24242d",
        position: "static",
    },
    iconMargin: {
        marginLeft: "10px",
    },
};

function EditingTitle({ eTitle, editModeCB, titleCB }) {
    const [newTitle, setNewTitle] = useState(eTitle);

    const handleSaveEdit = () => {
        editModeCB(false);
        titleCB(newTitle);
    };

    const handleCancelEdit = () => {
        editModeCB(false);
    };

    const handleTitleChange = (event) => {
        setNewTitle(event.target.value);
    };

    return (
        <OutlinedInput
            autoFocus
            size="small"
            value={newTitle}
            onChange={handleTitleChange}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={handleSaveEdit}>
                        <CheckIcon
                            fontSize="small"
                            style={{ color: "green", ...styles.iconMargin }}
                        />
                    </IconButton>
                    <IconButton onClick={handleCancelEdit}>
                        <ClearIcon
                            fontSize="small"
                            style={{ color: "red", ...styles.iconMargin }}
                        />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}

function TopBar({
    title,
    callback,
    previousPath = null,
    canEdit = false,
    canGoBack = true,
    canClose = true,
    onClose = null,
    parentNames = null,
}) {
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();

    return (
        <AppBar sx={{ ...styles.appBar }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={2}>
                    {canGoBack && (
                        <IconButton
                            onClick={() => {
                                const projectType =
                                    localStorage.getItem("projectType") ??
                                    "standard";
                                if (previousPath) {
                                    if (
                                        previousPath === "/create-edit-project"
                                    ) {
                                        navigate(previousPath);
                                    } else {
                                        navigate(
                                            `${previousPath}/${projectType}`
                                        );
                                    }
                                } else {
                                    navigate(`/dashboard/${projectType}`);
                                }
                            }}
                        >
                            <ArrowBackIosIcon sx={{ color: "white" }} />
                        </IconButton>
                    )}
                </Grid>
                <Grid container item xs={8} justifyContent="center">
                    {canEdit && parentNames ? (
                        parentNames.multilayered === 1 ? (
                            <Stack direction="row">
                                <span className="icon-centering">
                                    <Typography>
                                        {parentNames.siteName}
                                    </Typography>
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: 16 }}
                                    />
                                    <Typography>
                                        {parentNames.floorName}
                                    </Typography>
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: 16 }}
                                    />
                                </span>
                            </Stack>
                        ) : (
                            <Stack direction="row">
                                <span className="icon-centering">
                                    <Typography>
                                        {parentNames.siteName}
                                    </Typography>
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: 16 }}
                                    />
                                </span>
                            </Stack>
                        )
                    ) : null}
                    {!canEdit ? (
                        <Typography>{title}</Typography>
                    ) : !editMode ? (
                        <IconButton onClick={() => setEditMode(true)}>
                            <Typography style={{ color: "white" }}>
                                {" "}
                                {title}{" "}
                            </Typography>
                            <EditIcon
                                fontSize="small"
                                style={{ color: "white", ...styles.iconMargin }}
                            />
                        </IconButton>
                    ) : (
                        <Box sx={{ backgroundColor: "white" }}>
                            <EditingTitle
                                eTitle={title}
                                editModeCB={setEditMode}
                                titleCB={callback}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                    {canClose && (
                        <IconButton
                            onClick={
                                onClose === null
                                    ? () => {
                                          const projectType =
                                              localStorage.getItem(
                                                  "projectType"
                                              ) ?? "standard";
                                          navigate(`/dashboard/${projectType}`);
                                      }
                                    : onClose
                            }
                        >
                            <CancelIcon sx={{ color: "red" }} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </AppBar>
    );
}

export default TopBar;
