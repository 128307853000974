import Axios from "axios";

export function deleteSensors(sensorIds, sensorType) {
    return Axios.delete("/api/sensor", {
        data: { ids: sensorIds, sensorType },
    });
}

export function updatePosition(sensorId, x, y, sensorType) {
    return Axios.put("/api/update_sensor_pos", {
        sensorId,
        sensorType,
        posX: x,
        posY: y,
    });
}

export function getSensorData(sensorIds, sensorType) {
    if (sensorType === "concrete" || sensorType === "activescreed") {
        return Axios.get("/api/get_lora_data", {
            params: {
                devEUIs: sensorIds,
                sensorType,
            },
        });
    }
    return Axios.post("/api/get_sensor_data", {
        sensorIds,
        sensorType,
    });
}

export function addSensorData(sensorData) {
    return Axios.post("/api/add_sensor_data", {
        sensorId: sensorData.sensorId,
        rh: sensorData.rh,
        temp: sensorData.temp,
        sensorType: sensorData.sensorType,
        datetime: sensorData.datetime,
    });
}

export function getCorrectedRHValue(id) {
    return Axios.get("/api/get_corrected_rh_value", {
        params: {
            id,
        },
    });
}

export function updateSensorData(sensorData) {
    return Axios.post("/api/update_sensor_data", {
        id: sensorData.id,
        rh: sensorData.rh,
        temp: sensorData.temp,
        sensorType: sensorData.sensorType,
        datetime: sensorData.datetime,
    });
}

export function deleteSensorData(dataId, sensorType) {
    return Axios.delete("/api/delete_sensor_data", {
        data: {
            dataId,
            sensorType,
        },
    });
}

export function getSensorTemplates(sensorType) {
    return Axios.get("/api/templates", { params: { typeId: sensorType?.id } });
}

export function deleteSensorTemplates(sensorTemplateId) {
    return Axios.delete("/api/delete_template", {
        data: { sensorTemplateId },
    });
}

export function getSensorTemplatesOwner(sensorTemplateId) {
    return Axios.get("/api/sensor_template_owners", {
        params: { sensorTemplateId },
    });
}

export function updateSensor(sensor) {
    return Axios.put("/api/update_sensor", { sensor });
}

export function updateSensors(ids, sensorFields) {
    return Axios.put("/api/update_sensors", { ids, sensorFields });
}

export function createSensor(sensor) {
    return Axios.put("/api/new_sensor", {
        sensor,
        pos: { x: null, y: null },
    });
}
export function updateSensorTemplate(sensorT, owners) {
    return Axios.put("/api/update_sensor_template", {
        template: sensorT,
        owners,
        id: sensorT.id,
    });
}
export function createSensorTemplate(sensorT, owners) {
    return Axios.put("/api/create_sensor_template", {
        template: sensorT,
        owners,
    });
}

export function getSensorTypes() {
    return Axios.get("/api/types");
}

export function getSensorTypeRights(typeId) {
    return Axios.get("/api/sensor_type_rights", {
        params: {
            sensorTypeId: typeId,
        },
    });
}

export function sensorTypesWithRights() {
    return Axios.get("/api/sensor_types_by_rights");
}

export function sensorTypesWithRightsAsAdmin() {
    return Axios.get("/api/sensor_types");
}

export function screedTypes() {
    return Axios.get("/api/screedTypes");
}

export function sensorBatteryLevel(devEUI, type) {
    return Axios.get("/api/sensor_battery_level", {
        params: {
            devEUI,
            type,
        },
    });
}

export function sensorSearch(devEUI, type) {
    return Axios.get("/api/sensor/search", {
        params: {
            devEUI,
            type,
        },
    });
}
