import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteCustomer } from "../../../Services/settingsService";
import {
    getCustomersAsAdmin,
    getCustomersAsPartner,
    getPartners,
} from "../../../Services/projectService";
import AlertDialog from "../../ReusableComponents/AlertDialog";
import roles from "../../../Services/roleService";

const styles = {
    dialogPaper: {
        minWidth: "700px",
    },
};

function DeleteCustomer({ open, onCloseDialog, onAlert, role }) {
    const header = "Delete customer";

    const [partnerSelector, setPartnerSelector] = useState({
        selected: "",
        partners: [],
    });
    const [customers, setCustomers] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [userDelete, setUserDelete] = useState({ id: "", username: "" });

    useEffect(() => {
        if (open) {
            if (role === roles.Admin) {
                getPartners()
                    .then((res) => {
                        setPartnerSelector({
                            selected: "",
                            partners: res.data.partners,
                        });
                    })
                    .catch((error) => onAlert(error.response.data.error));
            } else {
                getCustomersAsPartner()
                    .then((res) => setCustomers(res.data.customers))
                    .catch((error) => onAlert(error.response.data.error));
            }
        }
    }, [open, onAlert, role]);

    const openAlertDialog = (id, username) => {
        setUserDelete({ id, username });
        setShowDialog(true);
    };

    const columns = useMemo(
        () => [
            { field: "name", headerName: "Customer name", width: 300 },
            { field: "email", headerName: "email", width: 250 },
            {
                field: "actions",
                type: "actions",
                headerName: "actions",
                width: 100,
                align: "center",
                getActions({ row }) {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => openAlertDialog(row.id, row.name)}
                        />,
                    ];
                },
            },
        ],
        []
    );

    const handleDelete = (confirm) => {
        if (confirm) {
            deleteCustomer(userDelete.id)
                .then(() => {
                    const temp = [...customers];
                    const sliceIndex = temp.findIndex(
                        (x) => x.id === userDelete.id
                    );
                    if (sliceIndex === 0) {
                        setCustomers(temp.slice(1));
                    } else if (sliceIndex === temp.length - 1) {
                        setCustomers(temp.slice(0, -1));
                    } else if (sliceIndex > 0) {
                        const newObjects = [].concat(
                            temp.slice(0, sliceIndex),
                            temp.slice(sliceIndex + 1)
                        );
                        setCustomers(newObjects);
                    }
                })
                .catch((error) => onAlert(error.response.data.error));
        }
    };

    const closeAlertDialog = (close) => {
        setUserDelete({ id: "", username: "" });
        setShowDialog(close);
    };

    const closeDialog = () => {
        setCustomers([]);
        setPartnerSelector({ selected: "", partners: [] });
        onCloseDialog(false);
    };

    const handlePartnerSelect = (partnerId) => {
        setPartnerSelector({ ...partnerSelector, selected: partnerId });
        getCustomersAsAdmin(partnerId)
            .then((res) => setCustomers(res.data.customers))
            .catch((error) => onAlert(error.response.data.error));
    };

    return (
        <Dialog
            open={open}
            onClose={() => onCloseDialog(false)}
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                {role === roles.Admin ? (
                    <FormControl
                        sx={{
                            width: "200px",
                            marginTop: "5px",
                            marginBottom: "5px",
                        }}
                    >
                        <InputLabel>Partners</InputLabel>
                        <Select
                            value={
                                partnerSelector.partners.length > 0
                                    ? partnerSelector.selected
                                    : ""
                            }
                            label="Partners"
                            onChange={(event) =>
                                handlePartnerSelect(event.target.value)
                            }
                        >
                            {partnerSelector.partners.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: "Customer name", sort: "asc" },
                            ],
                        },
                    }}
                    columns={columns}
                    rows={customers}
                    autoHeight
                    rowsPerPageOptions={[8]}
                    pageSize={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
            {showDialog ? (
                <AlertDialog
                    header="Delete customer"
                    body={`Are you sure you want to delete customer ${userDelete.username}?`}
                    confirmButton="Delete"
                    callback={handleDelete}
                    setCallback={closeAlertDialog}
                />
            ) : null}
        </Dialog>
    );
}

export default DeleteCustomer;
