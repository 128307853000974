import { screedTypes } from "../Api/sensorApi";

export async function getScreedTypeName(type) {
    const res = await screedTypes();
    const types = res.data.screedTypes;
    const screedType = types.find((t) => t.id === type);
    return screedType?.name;
}

export function sensorIsTwoPointZero(devEUI) {
    if (!devEUI || devEUI === undefined) return false;

    // Sensors with the devEUI 8C1F6400002EF16F or higher is 2.0.
    const limit = 10096898840753795413n; // BigInt("0x8C1F6400002EF16F")
    const hex = `0x${devEUI}`;

    let isTwoPointZero = false;
    try {
        isTwoPointZero = BigInt(hex) >= limit; // eslint-disable-line
    } catch (err) {
        // Failed to parse hex
    }

    return isTwoPointZero;
}
