import { useNavigate } from "react-router-dom";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";

const styles = {
    dialogPaper: {
        minHeight: "80%",
        maxHeight: "80%",
        maxWidth: "90%",
        minWidth: "90%",
    },
    GridToContentSize: {
        width: "100%",
        height: "100%",
    },
};

export default function Unauthorized() {
    const navigate = useNavigate();

    return (
        <Dialog
            open
            fullScreen
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle disableTypography>
                <Typography variant="h2"> Unauthorized </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={styles.GridToContentSize}
                >
                    <IconButton onClick={() => navigate(-2)}>
                        <ArrowBackIosIcon fontSize="large" />
                        <Typography variant="h3"> Go back </Typography>
                    </IconButton>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
