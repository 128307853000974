import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import TopBar from "../ReusableComponents/TopBar";
import FloorCards from "../ReusableComponents/FloorCards";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import * as projectService from "../../Services/projectService";

const styles = {
    dialogPaper: {
        minHeight: "80%",
        maxHeight: "80%",
        maxWidth: "90%",
        minWidth: "90%",
    },
    floor: {
        width: "100%",
        paddingLeft: "30px",
        paddingRight: "20px",
        paddingTop: "20px",
    },
    floorCard: {
        paddingTop: "40px",
        paddingLeft: "30px",
    },
    measurers: {
        marginTop: "10px",
    },
};

function InfoModal() {
    const { projectId, sensorType } = useParams();
    const { title, modalName, multilayered } = useLocation().state ?? {
        title: null,
        modalName: null,
        multilayered: true,
    };
    const [titleState, setTitleState] = useState(title);
    const [modalNameState, setModalNameState] = useState(modalName);
    const [multilayeredState, setMultilayeredState] = useState(multilayered);

    const [alert, setAlert] = useState({ type: "error", message: "" });

    useEffect(() => {
        if (titleState === null || modalNameState === null) {
            const customerId = localStorage.getItem("customer") ?? "";
            projectService
                .getModalInfo(projectId, customerId, sensorType)
                .then((modalInfo) => {
                    setTitleState(modalInfo.data.title);
                    setModalNameState(modalInfo.data.modalName);
                    setMultilayeredState(modalInfo.data.multilayered);
                })
                .catch((error) => setAlert(error.response.data.error));
        }
    }, [titleState, modalNameState, projectId, sensorType, multilayeredState]);

    const { data: measurerData } = useQuery(
        ["measurerByProject", projectId],
        async () => projectService.measurerByProject(projectId),
        {
            select: (data) => data.data.result.map((d) => d.userId),
        }
    );

    const { data: userData } = useQuery({
        queryKey: ["users", measurerData],
        queryFn: async () => projectService.usersByIds(measurerData),
        enabled: !!measurerData,
        select: (data) =>
            data.data.result.map((d) => ({
                id: d.id,
                name: d.name,
            })),
    });

    return (
        <Dialog
            disableEnforceFocus
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            open
        >
            <TopBar title={title} />
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={styles.floor}>
                        <Typography variant="h4">{modalNameState}</Typography>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={4} sx={styles.floorCard}>
                            <FloorCards
                                id={projectId}
                                sensorType={sensorType}
                                editable={false}
                                multilayered={multilayeredState}
                            />
                        </Grid>
                        {userData?.length > 0 ? (
                            <Grid item xs={4} sx={styles.floorCard}>
                                <Typography variant="h6">
                                    Assigned measurers
                                </Typography>
                                <div style={styles.measurers}>
                                    {userData?.map((user) => (
                                        <Typography key={user.id}>
                                            {user.name}
                                        </Typography>
                                    ))}
                                </div>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
export default InfoModal;
