import Axios from "axios";

export function getCustomers() {
    return Axios.get("/api/get_customers");
}

export function getProjects(sensorType) {
    return Axios.post("/api/dashboard", {
        data: {
            sensorType,
        },
    });
}

export function deleteProject(projectId) {
    return Axios.delete("/api/delete_project", {
        data: {
            projectId,
        },
    });
}

export function getProject(projectId, customerId) {
    return Axios.get("/api/get_project", {
        params: {
            projectId,
            customerId,
        },
    });
}

export function projectTypes(projectId) {
    return Axios.get("/api/project_types", {
        params: {
            projectId,
        },
    });
}

export function existsSensorsBySensorType(sensorType) {
    return Axios.get("/api/project/exists_sensors_by_sensor_type", {
        params: { sensorType },
    });
}
export function updateProject(projectId, project, sensorTypes) {
    return Axios.put("/api/update_project", {
        projectId,
        project,
        sensorTypes,
    });
}

export function addNewProject(customerId) {
    return Axios.post("/api/add_new_project", {
        customerId,
    });
}

export function getRole() {
    return Axios.get("/api/get_role");
}

export function getPartners() {
    return Axios.get("/api/get_partners");
}

export function getCustomersAsMeasurer() {
    return Axios.get("/api/customers_as_measurer");
}

export function getCustomersAsPartner() {
    return Axios.get("/api/get_customers_as_partner");
}

export function getCustomersAsAdmin(partnerId) {
    return Axios.get("/api/get_customers_as_admin", {
        params: {
            partnerId,
        },
    });
}

export function getProjectsByCustomer(customerId, sensorType) {
    return Axios.get("/api/get_sites_by_customer", {
        params: {
            customerId,
            sensorType,
        },
    });
}

export function getModalInfo(projectId, customerId, sensorType) {
    return Axios.get("/api/get_modal_info", {
        params: {
            projectId,
            customerId,
            sensorType,
        },
    });
}

export function projectTypesByObject(objectId) {
    return Axios.get("/api/project_types_by_object", {
        params: { objectId },
    });
}

export function projectTypesByObjectAdmin(objectId) {
    return Axios.get("/api/project_types_by_object_admin", {
        params: { objectId },
    });
}

export function measurersByProject(projectId) {
    return Axios.get("/api/measurer-by-project", {
        params: { siteId: projectId },
    });
}

export function usersByIds(ids) {
    return Axios.get("/api/users-by-ids", {
        params: { ids },
    });
}
