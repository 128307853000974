import md5 from "md5";
import * as loginApi from "../Api/loginApi";

export function signOut() {
    localStorage.clear();
    return loginApi.signOut();
}

export function checkPassword(username, password) {
    return loginApi.checkPassword(username, md5(password));
}

export function login(username, password) {
    return loginApi.login(username, md5(password));
}

export function removeCookies() {
    localStorage.clear();
    const res = document.cookie;
    const multiple = res.split(";");
    for (let i = 0; i < multiple.length; i += 1) {
        const key = multiple[i].split("=");
        document.cookie = `${key[0]}=; expires=Thu 01 Jan 1337 00:00:00 UTC;Path=/`;
    }
}
