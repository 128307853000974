import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    deleteSensorType,
    getSensorTypes,
} from "../../../Services/settingsService";
import AlertDialog from "../../ReusableComponents/AlertDialog";

const styles = {
    dialogPaper: {
        minWidth: "700px",
    },
};

function DeleteSensorType({ open, onCloseDialog, onAlert }) {
    const header = "Delete sensor type";

    const [types, setTypes] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [typeDelete, setTypeDelete] = useState({ id: "", name: "" });

    useEffect(() => {
        if (open) {
            getSensorTypes()
                .then((res) => setTypes(res.data.sensorTypes))
                .catch((error) => onAlert(error.response.data.error));
        }
    }, [open, onAlert]);

    const openAlertDialog = (id, name) => {
        setTypeDelete({ id, name });
        setShowDialog(true);
    };

    const columns = useMemo(
        () => [
            { field: "name", headerName: "name", width: 300 },
            { field: "formula", headerName: "formula", width: 250 },
            {
                field: "actions",
                type: "actions",
                headerName: "actions",
                width: 100,
                align: "center",
                getActions({ row }) {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => openAlertDialog(row.id, row.name)}
                        />,
                    ];
                },
            },
        ],
        []
    );

    const handleDelete = (confirm) => {
        if (confirm) {
            deleteSensorType(typeDelete.id)
                .then(() => {
                    const temp = types.filter(
                        (type) => type.id !== typeDelete.id
                    );
                    setTypes(temp);
                    onAlert({ type: "success", message: "Sensortype deleted" });
                })
                .catch((error) => onAlert(error.response.data.error));
        }
    };

    const closeAlertDialog = (close) => {
        setTypeDelete({ id: "", name: "" });
        setShowDialog(close);
    };

    const closeDialog = () => {
        setTypes([]);
        onCloseDialog(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => onCloseDialog(false)}
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "name", sort: "asc" }],
                        },
                    }}
                    columns={columns}
                    rows={types}
                    autoHeight
                    rowsPerPageOptions={[8]}
                    pageSize={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
            {showDialog ? (
                <AlertDialog
                    header="Delete sensor type"
                    body={`Are you sure you want to delete sensor type ${typeDelete.name}?`}
                    confirmButton="Delete"
                    callback={handleDelete}
                    setCallback={closeAlertDialog}
                />
            ) : null}
        </Dialog>
    );
}

export default DeleteSensorType;
