import randomcolor from "randomcolor";

function formatData(data, dateTime) {
    return { x: new Date(dateTime).valueOf(), y: data };
}

function formattedSensorData(selectedSensorsData, types) {
    const result = selectedSensorsData.reduce((arr, item) => {
        const {
            serialNumber,
            color,
            datetime,
            rh1,
            rh2,
            rh3,
            temp1,
            temp2,
            temp3,
        } = item;
        const existingItem = arr.find((obj) => obj.sensorId === serialNumber);
        const includesRh = types.includes("rh");
        const includesTemp = types.includes("temp");

        if (existingItem) {
            if (includesRh) {
                existingItem.rh1Data.push(formatData(rh1, datetime));
                existingItem.rh2Data.push(formatData(rh2, datetime));
                existingItem.rh3Data.push(formatData(rh3, datetime));
            }

            if (includesTemp) {
                existingItem.temp1Data.push(formatData(temp1, datetime));
                existingItem.temp2Data.push(formatData(temp2, datetime));
                existingItem.temp3Data.push(formatData(temp3, datetime));
            }
        } else {
            const data = {};

            if (includesRh) {
                data.rh1Data = [formatData(rh1, datetime)];
                data.rh2Data = [formatData(rh2, datetime)];
                data.rh3Data = [formatData(rh3, datetime)];
            }

            if (includesTemp) {
                data.temp1Data = [formatData(temp1, datetime)];
                data.temp2Data = [formatData(temp2, datetime)];
                data.temp3Data = [formatData(temp3, datetime)];
            }

            arr.push({ sensorId: serialNumber, color, ...data });
        }

        return arr;
    }, []);

    return result;
}

/* function filteredSortedData(data, minDate, maxDate) {
    let firstIndex;
    let lastIndex;
    const result = { ...data };

    let index = 0;
    result.data.forEach((d) => {
        const date = new Date(d.x);
        if (!firstIndex && date >= minDate) firstIndex = index;
        if (!lastIndex && date >= maxDate) lastIndex = index;
        index += 1;
    });

    firstIndex = firstIndex ?? 0;
    lastIndex = lastIndex ?? result.data.length - 1;

    // Slice just outside of range to keep graph continous
    result.data = result.data.slice(firstIndex - 1, lastIndex + 1);

    return result;
} */

function getMinMaxDate(dates1, dates2) {
    const minDate = new Date(dates1[0].x);
    minDate.setDate(minDate.getDate() - 1);

    const maxDate = new Date(dates2[dates2.length - 1].x);
    maxDate.setDate(maxDate.getDate() + 1);

    return { minDate, maxDate };
}

function processRhData(rhData) {
    let rhMin = 1000;
    let rhMax = -1000;

    const sortedRh1 = rhData.rh1Data.sort((a, b) => {
        if (rhMin > a.y || rhMin > b.y) {
            rhMin = a.y < b.y ? a.y : b.y;
        }

        if (rhMax < a.y || rhMax < b.y) {
            rhMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const sortedRh2 = rhData.rh2Data.sort((a, b) => {
        if (rhMin > a.y || rhMin > b.y) {
            rhMin = a.y < b.y ? a.y : b.y;
        }

        if (rhMax < a.y || rhMax < b.y) {
            rhMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const sortedRh3 = rhData.rh3Data.sort((a, b) => {
        if (rhMin > a.y || rhMin > b.y) {
            rhMin = a.y < b.y ? a.y : b.y;
        }

        if (rhMax < a.y || rhMax < b.y) {
            rhMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const minRh = Math.floor((rhMin - 1) / 5) * 5;
    const maxRh = Math.ceil((rhMax + 1) / 5) * 5;
    const { minDate, maxDate } = getMinMaxDate(sortedRh1, sortedRh3);

    return {
        rhData: [
            {
                label: `Sensor: ${rhData.sensorId} rh1`,
                data: sortedRh1,
                yAxisID: "rh",
                fill: false,
                backgroundColor: randomcolor({ luminosity: "dark" }),
                borderColor: randomcolor({ luminosity: "dark" }),
            },
            {
                label: `Sensor: ${rhData.sensorId} rh2`,
                data: sortedRh2,
                yAxisID: "rh",
                fill: false,
                backgroundColor: randomcolor({ luminosity: "dark" }),
                borderColor: randomcolor({ luminosity: "dark" }),
            },
            {
                label: `Sensor: ${rhData.sensorId} rh3`,
                data: sortedRh3,
                yAxisID: "rh",
                fill: false,
                backgroundColor: randomcolor({ luminosity: "dark" }),
                borderColor: randomcolor({ luminosity: "dark" }),
            },
        ],
        minRh,
        maxRh,
        minDate,
        maxDate,
    };
}

function processTempData(tempData) {
    let tempMin = 1000;
    let tempMax = -1000;

    const sortedTemp1 = tempData.temp1Data.sort((a, b) => {
        if (tempMin > a.y || tempMin > b.y) {
            tempMin = a.y < b.y ? a.y : b.y;
        }

        if (tempMax < a.y || tempMax < b.y) {
            tempMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const sortedTemp2 = tempData.temp2Data.sort((a, b) => {
        if (tempMin > a.y || tempMin > b.y) {
            tempMin = a.y < b.y ? a.y : b.y;
        }

        if (tempMax < a.y || tempMax < b.y) {
            tempMax = a.y < b.y ? b.y : a.y;
        }

        return a.x - b.x;
    });

    const sortedTemp3 = tempData.temp3Data.sort((a, b) => {
        if (tempMin > a.y || tempMin > b.y) {
            tempMin = a.y < b.y ? a.y : b.y;
        }
        if (tempMax < a.y || tempMax < b.y) {
            tempMax = a.y < b.y ? b.y : a.y;
        }
        return a.x - b.x;
    });

    const minTemp = Math.floor((tempMin - 1) / 5) * 5;
    const maxTemp = Math.ceil((tempMax + 1) / 5) * 5;
    const { minDate, maxDate } = getMinMaxDate(sortedTemp1, sortedTemp3);

    return {
        tempData: [
            {
                label: `Sensor: ${tempData.sensorId} temp1`,
                data: sortedTemp1,
                yAxisID: "temperature",
                fill: false,
                backgroundColor: randomcolor({ luminosity: "dark" }),
                borderColor: randomcolor({ luminosity: "dark" }),
            },
            {
                label: `Sensor: ${tempData.sensorId} temp2`,
                data: sortedTemp2,
                yAxisID: "temperature",
                fill: false,
                backgroundColor: randomcolor({ luminosity: "dark" }),
                borderColor: randomcolor({ luminosity: "dark" }),
            },
            {
                label: `Sensor: ${tempData.sensorId} temp3`,
                data: sortedTemp3,
                yAxisID: "temperature",
                fill: false,
                backgroundColor: randomcolor({ luminosity: "dark" }),
                borderColor: randomcolor({ luminosity: "dark" }),
            },
        ],
        minTemp,
        maxTemp,
        minDate,
        maxDate,
    };
}

export function generateRhChartData(selectedSensorsData) {
    let timeSeries = [];
    const sensorData = formattedSensorData(selectedSensorsData, ["rh"]);

    const actualSensorData = sensorData.filter(
        (sensor) => sensor.rh1Data.length > 0
    );

    const rhDataList = [];
    let minRh = 100;
    let maxRh = -1;
    actualSensorData.forEach((sData) => {
        const sortedRh1 = sData.rh1Data.sort((a, b) => a.x - b.x);

        // should only need from one as the dates of rh/temp should be the same
        timeSeries = timeSeries.concat(
            sortedRh1.map((rh) => rh.x).filter((i) => !timeSeries.includes(i))
        );
        const rhData = processRhData(sData);

        rhDataList.push(...rhData.rhData);
        minRh = minRh > rhData.minRh ? rhData.minRh : minRh;
        maxRh = maxRh < rhData.maxRh ? rhData.maxRh : maxRh;
    });

    /* const rhDataListFiltered = rhDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    ); */

    const chartData = {
        rhData: rhDataList,
        minRh,
        maxRh,
    };

    return { chartData, timeSeries };
}

export function generateTempChartData(selectedSensorsData) {
    let timeSeries = [];
    const sensorData = formattedSensorData(selectedSensorsData, ["temp"]);

    const actualSensorData = sensorData.filter(
        (sensor) => sensor.temp1Data.length > 0
    );

    const tempDataList = [];
    let minTemp = 1000;
    let maxTemp = 0;
    actualSensorData.forEach((sData) => {
        const sortedTemp1 = sData.temp1Data.sort((a, b) => a.x - b.x);

        timeSeries = timeSeries.concat(
            sortedTemp1.map((rh) => rh.x).filter((i) => !timeSeries.includes(i))
        );
        const tempData = processTempData(sData);

        tempDataList.push(...tempData.tempData);
        minTemp = minTemp > tempData.minTemp ? tempData.minTemp : minTemp;
        maxTemp = maxTemp < tempData.maxTemp ? tempData.maxTemp : maxTemp;
    });

    /* const tempDataListFiltered = tempDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    ); */

    const chartData = {
        tempData: tempDataList,
        minTemp,
        maxTemp,
    };

    return { chartData, timeSeries };
}

export function generateBothChartData(selectedSensorsData) {
    let timeSeries = [];
    const sensorData = formattedSensorData(selectedSensorsData, ["rh", "temp"]);

    const actualSensorData = sensorData.filter(
        (sensor) => sensor.temp1Data.length > 0 && sensor.rh1Data.length > 0
    );

    const tempDataList = [];
    const rhDataList = [];
    let minTemp = 100;
    let maxTemp = 0;
    let minRh = 100;
    let maxRh = 0;
    actualSensorData.forEach((sData) => {
        const sortedTemp1 = sData.temp1Data.sort((a, b) => a.x - b.x);

        timeSeries = timeSeries.concat(
            sortedTemp1.map((rh) => rh.x).filter((i) => !timeSeries.includes(i))
        );
        const tempData = processTempData(sData);
        const rhData = processRhData(sData);

        tempDataList.push(...tempData.tempData);
        rhDataList.push(...rhData.rhData);
        minTemp = minTemp > tempData.minTemp ? tempData.minTemp : minTemp;
        maxTemp = maxTemp < tempData.maxTemp ? tempData.maxTemp : maxTemp;
        minRh = minRh > rhData.minRh ? rhData.minRh : minRh;
        maxRh = maxRh < rhData.maxRh ? rhData.maxRh : maxRh;
    });

    /* const rhDataListFiltered = rhDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    );
    const tempDataListFiltered = tempDataList.map((list) =>
        filteredSortedData(list, minDate, maxDate)
    ); */

    const chartData = {
        tempData: tempDataList,
        rhData: rhDataList,
        minTemp,
        maxTemp,
        minRh,
    };

    return { chartData, timeSeries };
}

export function getMinMaxData(data) {
    let minDate;
    let maxDate;
    let minRh;
    let maxRh;
    let minTemp;
    let maxTemp;

    data.forEach((d) => {
        const dateTime = d.datetime;
        const minRhData = Math.min(d.rh1, d.rh2, d.rh3);
        const maxRhData = Math.max(d.rh1, d.rh2, d.rh3);
        const minTempData = Math.min(d.temp1, d.temp2, d.temp3);
        const maxTempData = Math.max(d.temp1, d.temp2, d.temp3);

        if (!minDate || dateTime < minDate) minDate = dateTime;
        if (!maxDate || dateTime > maxDate) maxDate = dateTime;
        minRh = minRh ? Math.min(minRh, minRhData) : minRhData;
        maxRh = maxRh ? Math.max(maxRh, maxRhData) : maxRhData;
        minTemp = minTemp ? Math.min(minTemp, minTempData) : minTempData;
        maxTemp = maxTemp ? Math.max(maxTemp, maxTempData) : maxTempData;
    });

    return {
        minDate,
        maxDate,
        minRh,
        maxRh,
        minTemp,
        maxTemp,
    };
}
