import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    getPartners,
    getCustomersAsAdmin,
} from "../../Services/projectService";
import { getSites } from "../../Services/statisticsService";
import SelectorsForRoles from "../ReusableComponents/SelectorsForRoles";
import { moveProject } from "../../Services/settingsService";

function MoveProject({ open, onCloseDialog, onAlert, role }) {
    const [partnerFromSelector, setPartnerFromSelector] = useState({
        selected: "",
        partners: [],
    });
    const [customerFromSelector, setCustomerFromSelector] = useState({
        selected: "",
        customers: [],
    });
    const [partnerToSelector, setPartnerToSelector] = useState({
        selected: "",
        partners: [],
    });
    const [customerToSelector, setCustomerToSelector] = useState({
        selected: "",
        customers: [],
    });
    const [sites, setSites] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");

    useEffect(() => {
        getPartners()
            .then((res) => {
                setPartnerFromSelector((p) => ({
                    ...p,
                    partners: res.data.partners,
                }));
                setPartnerToSelector((p) => ({
                    ...p,
                    partners: res.data.partners,
                }));
            })
            .catch((error) => onAlert(error.response.data.error));
    }, [onAlert]);

    const handlePartnerFromSelect = (partnerId) => {
        setPartnerFromSelector({ ...partnerFromSelector, selected: partnerId });
        getCustomersAsAdmin(partnerId)
            .then((res) => {
                setCustomerFromSelector((c) => ({
                    ...c,
                    customers: res.data.customers,
                }));
            })
            .catch((error) => onAlert(error.response.data.error));
    };

    const handleCustomerFromSelect = (customerId) => {
        setCustomerFromSelector({
            ...customerFromSelector,
            selected: customerId,
        });
        getSites(customerId, partnerFromSelector.selected, role)
            .then((res) =>
                setSites([
                    ...res.data.sites.map((site) => ({
                        siteId: site.siteId,
                        name: site.name,
                        address: site.address,
                        postal: site.postal,
                        city: site.city,
                        country: site.country,
                    })),
                ])
            )
            .catch((error) => onAlert(error.response.data.error));
    };

    const handleProjectChange = (project) => {
        setSelectedProject(project.target.value);
    };

    const handlePartnerToSelect = (partnerId) => {
        setPartnerToSelector({ ...partnerToSelector, selected: partnerId });
        getCustomersAsAdmin(partnerId)
            .then((res) => {
                setCustomerToSelector((c) => ({
                    ...c,
                    customers: res.data.customers,
                }));
            })
            .catch((error) => onAlert(error.response.data.error));
    };

    const handleCustomerToSelect = (customerId) => {
        setCustomerToSelector({ ...customerToSelector, selected: customerId });
    };

    const handleMove = () => {
        moveProject(
            customerFromSelector.selected,
            selectedProject.siteId,
            customerToSelector.selected
        )
            .then(() => onCloseDialog(false))
            .catch((err) => onAlert(err.response.data.error));
    };

    return (
        <Dialog open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>Move project</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">From</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">To</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item container spacing={1} xs={6}>
                        <Grid item>
                            <SelectorsForRoles
                                role={role}
                                cSelector={customerFromSelector}
                                handleCustomerSelect={handleCustomerFromSelect}
                                pSelector={partnerFromSelector}
                                handlePartnerSelect={handlePartnerFromSelect}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl sx={{ width: 240 }}>
                                <InputLabel>Projects</InputLabel>
                                <Select
                                    onChange={handleProjectChange}
                                    label="Projects"
                                    value={selectedProject}
                                >
                                    {sites.map((project, key) => (
                                        <MenuItem
                                            value={project}
                                            key={project.siteId}
                                        >
                                            {key < 2
                                                ? project.name
                                                : `${project.name}, 
                                                ${project.address}, 
                                                ${project.postal}, 
                                                ${project.city}, 
                                                ${project.country}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container xs={6}>
                        <Grid item>
                            <SelectorsForRoles
                                role={role}
                                cSelector={customerToSelector}
                                handleCustomerSelect={handleCustomerToSelect}
                                pSelector={partnerToSelector}
                                handlePartnerSelect={handlePartnerToSelect}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleMove} disabled={selectedProject === ""}>
                    Move
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MoveProject;
