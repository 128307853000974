import { useDragLayer } from "react-dnd";
import snapToGrid from "./SnapToGrid";
import ChipDragPreview from "./ChipDragPreview";

const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
};

function getItemStyles(initialOffset, currentOffset, scale, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        };
    }
    let { x, y } = currentOffset;
    if (isSnapToGrid) {
        x -= initialOffset.x;
        y -= initialOffset.y;
        [x, y] = snapToGrid(x, y);
        x += initialOffset.x;
        y += initialOffset.y;
    }
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

export default function CustomDragLayer() {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));
    function renderItem() {
        switch (itemType) {
            case "Chip":
                return (
                    <ChipDragPreview
                        index={item.title}
                        sensorId={item.sensorId}
                        serialNumber={item.serialNumber}
                        sensorColor={item.sensorColor}
                        scale={item.scale}
                    />
                );
            default:
                return null;
        }
    }
    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    );
}
