import { memo } from "react";
import { Grid, Avatar, AppBar, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import ProjectMenuButton from "./ProjectMenuButton";
import roles from "../../Services/roleService";
import ProjectCardInfo from "./ProjectCardInfo";

dayjs.extend(utc);

const styles = {
    box: {
        width: 375,
        minWidth: 375,
        height: 200,
        minHeight: 200,
        boxShadow: "3",
        borderRadius: 2,
        overflow: "hidden",
        fontFamily: "Arial",
    },
    appBar: {
        position: "static",
        color: "white",
        background: "#24242d",
        height: "25%",
        justifyContent: "center",
    },
    hover: {
        cursor: "pointer",
    },
    house: {
        fontSize: "30px",
        color: "white",
    },
    avatar: {
        backgroundColor: "#0D8ED9",
        width: "48px",
        height: "48px",
    },
    gridBox: {
        paddingLeft: "40px",
        margin: 0,
        height: "75%",
    },
};

const ProjectCards = memo(
    ({ projects, callback, customerId, sensorType, role }) => {
        const navigate = useNavigate();
        const deleteCallback = (projectId) => {
            callback(projectId);
        };
        const handleOnClick = (
            projectId,
            projectName,
            modalName,
            multilayered
        ) => {
            localStorage.setItem("projectId", projectId);
            navigate(`/info-modal/${projectId}/${sensorType}`, {
                state: { title: projectName, modalName, multilayered },
            });
        };
        return (
            // magic margin right, could be because of the navbar on the left side
            <div className="flex flex-wrap gap-7 place-content-between mr-[125px]">
                {projects.map((item) => (
                    <Box key={item.id} sx={{ ...styles.box }}>
                        <AppBar sx={{ ...styles.appBar }}>
                            <div className="flex flex-row px-1">
                                <div
                                    style={styles.gridItem}
                                    className="gap-2 flex-1 flex flex-row center items-center justify-start text-md pl-3"
                                >
                                    {!["activescreed", "concrete"].includes(
                                        sensorType
                                    ) && <ProjectCardInfo item={item} />}
                                </div>
                                {role <= roles.Customer && (
                                    <ProjectMenuButton
                                        className=""
                                        id={item.id}
                                        name={item.name}
                                        callback={deleteCallback}
                                        customerId={customerId}
                                    />
                                )}
                            </div>
                        </AppBar>
                        <Grid
                            container
                            item
                            spacing={1}
                            alignItems="center"
                            sx={{ ...styles.gridBox }}
                            onClick={() =>
                                handleOnClick(
                                    item.id,
                                    item.name,
                                    item.modalName,
                                    item.multilayered
                                )
                            }
                            style={styles.hover}
                        >
                            <Grid item>
                                <Avatar sx={{ ...styles.avatar }}>
                                    <HomeIcon sx={{ ...styles.house }} />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <div style={{ fontSize: "20px" }}>
                                    {item.name}
                                </div>
                                <div style={{ fontSize: "14px" }}>
                                    {item.created}
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </div>
        );
    }
);

export default ProjectCards;
