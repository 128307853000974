import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import ChangePassword from "./ChangePassword";
import PartnerSettings from "./PartnerSettings/PartnerSettings";
import SettingsProject from "./SettingsProject";
import SettingsSensorType from "./SensorTypeSettings/SettingsSensorType";
import CustomerSettings from "./CustomerSettings/CustomerSettings";
import roles from "../../Services/roleService";
import MeasurerSettings from "./MeasurerSettings/MeasurerSettings";
import { updateCorrectedRHValues } from "../../Api/settingsApi";
import { useRoleContext } from "../../Context/RoleContext";

function Settings() {
    const [alert, setAlert] = useState({ type: "error", message: "" });
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const { role } = useRoleContext();

    return (
        <Grid container justifyContent="center">
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <Grid item xs={6}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{ margin: "10px 0" }}
                >
                    Settings
                </Typography>
                {/* Add you component with an Accordion that takes your role */}
                <Accordion
                    disableGutters
                    onChange={() => setOpenChangePassword(true)}
                >
                    <AccordionSummary>
                        <Typography>Change password</Typography>
                    </AccordionSummary>
                </Accordion>
                {role === roles.Admin && (
                    <PartnerSettings role={role} onAlert={setAlert} />
                )}
                {(role === roles.Admin || role === roles.Partner) && (
                    <CustomerSettings role={role} onAlert={setAlert} />
                )}
                {(role === roles.Admin || role === roles.Partner) && (
                    <MeasurerSettings role={role} onAlert={setAlert} />
                )}
                {(role === roles.Admin || role === roles.Partner) && (
                    <SettingsProject role={role} alertCB={setAlert} />
                )}
                {role === roles.Admin && (
                    <SettingsSensorType alertCB={setAlert} />
                )}
                {role === roles.Admin && (
                    <Button onClick={() => updateCorrectedRHValues()}>
                        Update corrected RH data
                    </Button>
                )}
            </Grid>
            <ChangePassword
                open={openChangePassword}
                onAlert={setAlert}
                onCloseDialog={setOpenChangePassword}
            />
        </Grid>
    );
}

export default Settings;
