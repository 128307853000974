import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    getCustomersAsAdmin,
    getCustomersAsPartner,
    getPartners,
    updateUser,
} from "../../../Services/settingsService";
import roles from "../../../Services/roleService";

const filter = createFilterOptions();

function ChangeCustomer({ role, open, onCloseDialog, onAlert }) {
    const header = "Update customer";
    const [partner, setPartner] = useState("");
    const [customer, setCustomer] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [partnerOptions, setPartnerOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);

    // Reset Defaults
    useEffect(() => {
        setPartner("");
        setCustomer("");
        setUsername("");
        setPassword("");
        setConfirm("");
    }, [open]);

    // set Partner list
    useEffect(() => {
        if (role === roles.Admin) {
            getPartners().then((res) => {
                setPartnerOptions(res.data.partners);
            });
        }
    }, [role, open]);

    // set Customer list
    useEffect(() => {
        if (partner !== "" && role === roles.Admin) {
            getCustomersAsAdmin(partner.id)
                .then((res) => setCustomerOptions(res.data.customers))
                .catch(() =>
                    onAlert({
                        type: "error",
                        message: "failed to get customers",
                    })
                );
        } else {
            getCustomersAsPartner()
                .then((res) => setCustomerOptions(res.data.customers))
                .catch(() =>
                    onAlert({
                        type: "error",
                        message: "failed to get customers",
                    })
                );
        }
    }, [partner, onAlert, role, open]);

    // Set values based on customer
    useEffect(() => {
        if (customer) {
            setUsername(customer.name ?? "");
        }
    }, [customer]);

    const handleSave = () => {
        const user = {
            userId: customer.id,
            username,
            password,
        };
        updateUser(user)
            .then(() => onCloseDialog(false))
            .catch((err) => onAlert({ message: err.response.data.error }));
    };

    const allowSave = customer && username && password === confirm;

    return (
        <Dialog open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent sx={{ maxWidth: "500px", paddingBottom: "0px" }}>
                <Stack spacing={1} sx={{ paddingTop: "10px" }}>
                    {role === roles.Admin ? (
                        <Autocomplete
                            label="Select partner"
                            options={partnerOptions}
                            renderInput={(params) => (
                                <TextField {...params} label="Partner" />
                            )}
                            onChange={(event, value) => {
                                setPartner(value ?? -1);
                            }}
                            getOptionLabel={(option) =>
                                typeof option === "string"
                                    ? option
                                    : option.name ?? ""
                            }
                            filterOptions={(options, params) =>
                                filter(options, params)
                            }
                        />
                    ) : null}
                    <Autocomplete
                        label="Select customer"
                        options={customerOptions}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" />
                        )}
                        onChange={(event, value) => {
                            setCustomer(value ?? -1);
                        }}
                        getOptionLabel={(option) =>
                            typeof option === "string"
                                ? option
                                : option.name ?? ""
                        }
                        filterOptions={(options, params) =>
                            filter(options, params)
                        }
                    />
                    <TextField
                        value={username}
                        fullWidth
                        label="Customer name"
                        variant="outlined"
                        margin="dense"
                        onChange={(text) => setUsername(text.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="New password"
                        variant="outlined"
                        margin="dense"
                        type={showPassword ? "text" : "password"}
                        onChange={(text) => setPassword(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Confirm password"
                        variant="outlined"
                        margin="dense"
                        type={showConfirm ? "text" : "password"}
                        onChange={(text) => setConfirm(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowConfirm(!showConfirm)
                                        }
                                    >
                                        {showConfirm ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} disabled={!allowSave}>
                    Save
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangeCustomer;
