import React, { useCallback, useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
    Menu,
    IconButton,
    Stack,
    Grid,
    TextField,
    Typography,
    Button,
    Tooltip,
} from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
    roundedDownToNearest,
    roundedUpToNearest,
} from "../../Utils/mathUtils";

const graphPaddingValue = 5;

function GraphFilter({
    minMaxDataAll,
    minMaxDataUser,
    setMinMaxDataUser,
    toggle,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [minTempBuffer, setMinTempBuffer] = useState(minMaxDataUser.minTemp);
    const [maxTempBuffer, setMaxTempBuffer] = useState(minMaxDataUser.maxTemp);
    const [minRhBuffer, setMinRhBuffer] = useState(minMaxDataUser.minRh);
    const [maxRhBuffer, setMaxRhBuffer] = useState(minMaxDataUser.maxRh);
    const [minDateBuffer, setMinDateBuffer] = useState(minMaxDataUser.minDate);
    const [maxDateBuffer, setMaxDateBuffer] = useState(minMaxDataUser.maxDate);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);

    const resetBuffers = useCallback(() => {
        setMinTempBuffer(minMaxDataUser.minTemp);
        setMaxTempBuffer(minMaxDataUser.maxTemp);
        setMinRhBuffer(minMaxDataUser.minRh);
        setMaxRhBuffer(minMaxDataUser.maxRh);
        setMinDateBuffer(minMaxDataUser.minDate);
        setMaxDateBuffer(minMaxDataUser.maxDate);
    }, [minMaxDataUser]);

    useEffect(() => {
        resetBuffers();
    }, [minMaxDataAll, resetBuffers]);

    const open = Boolean(anchorEl);

    const close = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.target);
    };

    const handleClose = () => {
        close();
        resetBuffers();
    };

    const handleDateRangeChanged = (event) => {
        const start = new Date(event.selection.startDate);
        start.setHours(12);
        setMinDateBuffer(start.toISOString().split("T")[0]);

        const end = new Date(event.selection.endDate);
        end.setHours(12);
        setMaxDateBuffer(end.toISOString().split("T")[0]);
    };

    useEffect(() => {
        if (minDateBuffer && maxDateBuffer) {
            setDateRange([
                {
                    startDate: new Date(minDateBuffer),
                    endDate: new Date(maxDateBuffer),
                    key: "selection",
                },
            ]);
        }
    }, [minDateBuffer, maxDateBuffer]);

    const handleEdited = () => {
        setMinMaxDataUser((prev) => ({
            ...prev,
            minRh: minRhBuffer,
            maxRh: maxRhBuffer,
            minTemp: minTempBuffer,
            maxTemp: maxTempBuffer,
        }));
    };

    const handleEditedDate = () => {
        setMinMaxDataUser((prev) => ({
            ...prev,
            minDate: minDateBuffer,
            maxDate: maxDateBuffer,
        }));
    };

    const handleUpdate = () => {
        handleEdited();
        if (
            minMaxDataAll.minDate !== minDateBuffer ||
            minMaxDataAll.maxDate !== maxDateBuffer
        ) {
            handleEditedDate();
        }
        close();
    };

    const handleViewAll = () => {
        setMinMaxDataUser({
            minDate: minMaxDataAll.minDate,
            maxDate: minMaxDataAll.maxDate,
            minRh: roundedDownToNearest(minMaxDataAll.minRh, graphPaddingValue),
            maxRh: roundedUpToNearest(minMaxDataAll.maxRh, graphPaddingValue),
            minTemp: roundedDownToNearest(
                minMaxDataAll.minTemp,
                graphPaddingValue
            ),
            maxTemp: roundedUpToNearest(
                minMaxDataAll.maxTemp,
                graphPaddingValue
            ),
        });
        close();
    };

    const handleCancel = () => {
        resetBuffers();
        close();
    };

    return (
        <>
            <IconButton
                expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <FilterListIcon />
                <Typography>Modify axes</Typography>
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Stack spacing={1}>
                    <DateRange
                        style={{ fontSize: "16px" }}
                        editableDateInputs
                        onChange={handleDateRangeChanged}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                    />
                    {toggle === "rh" || toggle === "both" ? (
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    paddingLeft: "10px",
                                    paddingRight: "5px",
                                }}
                            >
                                <TextField
                                    label="Min rh"
                                    variant="outlined"
                                    value={minRhBuffer}
                                    onChange={(event) => {
                                        const value =
                                            event.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                            );
                                        setMinRhBuffer(value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleEdited();
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    paddingLeft: "5px",
                                    paddingRight: "10px",
                                }}
                            >
                                <TextField
                                    label="Max rh"
                                    variant="outlined"
                                    value={maxRhBuffer}
                                    onChange={(event) => {
                                        const value =
                                            event.target.value.replace(
                                                /[^0-9]+/g,
                                                ""
                                            );
                                        setMaxRhBuffer(value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleEdited();
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    {toggle === "temp" || toggle === "both" ? (
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    paddingLeft: "10px",
                                    paddingRight: "5px",
                                }}
                            >
                                <TextField
                                    label="Min temp"
                                    variant="outlined"
                                    value={minTempBuffer ?? ""}
                                    onChange={(event) => {
                                        const value =
                                            event.target.value.replace(
                                                /[^0-9-]+/g,
                                                ""
                                            );
                                        setMinTempBuffer(value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleEdited();
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    paddingLeft: "5px",
                                    paddingRight: "10px",
                                }}
                            >
                                <TextField
                                    label="Max temp"
                                    variant="outlined"
                                    value={maxTempBuffer ?? ""}
                                    onChange={(event) => {
                                        const value =
                                            event.target.value.replace(
                                                /[^0-9-]+/g,
                                                ""
                                            );
                                        setMaxTempBuffer(value);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleEdited();
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container>
                        <Grid
                            item
                            xs={4}
                            sx={{ paddingLeft: "10px", paddingRight: "5px" }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleUpdate}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            sx={{ paddingLeft: "5px", paddingRight: "5px" }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            sx={{ paddingLeft: "5px", paddingRight: "10px" }}
                        >
                            <Tooltip title="Viewing all data may result in slow performance in large data sets">
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={handleViewAll}
                                >
                                    View all
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Stack>
            </Menu>
        </>
    );
}

export default GraphFilter;
