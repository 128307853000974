import UpdateIcon from "@mui/icons-material/Update";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default function ProjectCardInfo({ item }) {
    return (
        <>
            <UpdateIcon />
            <Grid item>
                {new Date(dayjs.utc(item.latestActivity)).toLocaleDateString()}{" "}
            </Grid>
            <Grid>
                {new Date(dayjs.utc(item.latestActivity)).toLocaleTimeString()}
            </Grid>
            <Grid item>
                <ArrowForwardIosIcon fontSize="15px" />
            </Grid>
            <Grid item>{item.latestMeasurement} % RF</Grid>
        </>
    );
}
