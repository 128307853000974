import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
    ListItemText,
    List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import usePagination from "../ReusableComponents/Pagination";
import * as sensorService from "../../Services/sensorService";
import CreateAdminTemplate from "./CreateAdminTemplate";
import AlertDialog from "../ReusableComponents/AlertDialog";

const styles = {
    overrideHidden: {
        overflow: "hidden",
        width: "35vw",
    },
    dialogPaper: {
        minHeight: "50%",
        maxHeight: "50%",
        maxWidth: "80%",
        minWidth: "80%",
    },
    background: {
        margin: "20px",
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
    },
};

function SensorTemplate({ open, onCloseDialog, onAlert }) {
    const [templates, setTemplates] = useState([]);
    const [deleteTemplate, setDeleteTemplate] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const header = "Sensor Template";
    const [page, setPage] = useState(1);
    const PER_PAGE = 8;
    const count = Math.ceil(templates.length / PER_PAGE);
    const PTEMPLATES = usePagination(templates, PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        PTEMPLATES.jump(p);
    };
    const handleSelectTemplate = (template) => {
        if (template.id === selectedTemplate.id) {
            setSelectedTemplate({});
        } else {
            setSelectedTemplate(template);
        }
    };
    const openDeleteDialog = (template) => {
        setDeleteTemplate(template);
        setShowDialog(true);
    };
    const closeAlertDialog = () => {
        setDeleteTemplate({});
        setShowDialog(false);
    };
    const handleDeleteTemplate = (confirm) => {
        if (confirm) {
            sensorService.deleteSensorTemplates(deleteTemplate.id).then(() => {
                setSelectedTemplate({});
                sensorService
                    .getSensorTemplates()
                    .then((res) => {
                        setTemplates(res.data.templates);
                    })
                    .catch((err) => onAlert(err.message));
                onAlert({
                    type: "success",
                    message: "Successfully deleted template",
                });
            });
        }
    };

    useEffect(() => {
        if (open) {
            sensorService
                .getSensorTemplates()
                .then((res) => {
                    setTemplates(res.data.templates);
                })
                .catch((err) => onAlert(err.message));
        }
    }, [open, onAlert]);

    return (
        <Dialog
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            open={open}
            onClose={onCloseDialog}
        >
            <DialogTitle sx={{ paddingBottom: "0px" }}>{header}</DialogTitle>
            <DialogContent sx={{ paddingBottom: "0px" }}>
                <Grid container>
                    <Grid container item xs={6}>
                        <Box className={styles.background}>
                            <List dense>
                                {PTEMPLATES.currentData().map((template) => (
                                    <MenuItem
                                        selected={
                                            template.id === selectedTemplate.id
                                        }
                                        key={template.id}
                                        disableGutters
                                        onClick={() =>
                                            handleSelectTemplate(template)
                                        }
                                    >
                                        <ListItemText
                                            secondaryTypographyProps={{
                                                style: styles.overrideHidden,
                                            }}
                                            primaryTypographyProps={{
                                                style: styles.overrideHidden,
                                            }}
                                            primary={template.name}
                                            secondary={template.description}
                                        />
                                        <DeleteIcon
                                            onClick={() =>
                                                openDeleteDialog(template)
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </List>
                            <Pagination
                                count={count}
                                size="large"
                                page={page}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid container item xs={6}>
                        <Box className={styles.background}>
                            <CreateAdminTemplate
                                onAlert={onAlert}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                setTemplates={setTemplates}
                                open={open}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: "0px" }}>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
            {showDialog ? (
                <AlertDialog
                    header="Delete sensor type"
                    body={`Are you sure you want to delete sensor type ${deleteTemplate.name}?`}
                    confirmButton="Delete"
                    callback={handleDeleteTemplate}
                    setCallback={closeAlertDialog}
                />
            ) : null}
        </Dialog>
    );
}

export default SensorTemplate;
