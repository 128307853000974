import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import roles from "../../Services/roleService";
import AddEditDataDialog from "./AddEditDataDialog";
import { useSensorDataContext } from "./Contexts/SensorDataContext";
import SensorListRow from "./SensorListRow";

function EnhancedTableHead({
    onSelectAllClick,
    numSelected,
    rowCount,
    role,
    sensorType,
}) {
    const isConcrete = sensorType === "concrete";
    const isScreed = sensorType === "screed";
    const isScreedActive = sensorType === "activescreed";

    const sensorColumns = [
        { id: "id", label: "ID" },
        { id: "name", label: "Name" },
        { id: "color", label: "Color" },
    ];

    if (isConcrete || isScreedActive)
        sensorColumns.push({ id: "battery", label: "Battery" });

    if (role !== roles.Measurer)
        sensorColumns.push({ id: "place-remove", label: "Remove/Place" });

    if (!(isConcrete || isScreedActive))
        sensorColumns.push({ id: "add", label: "Add data" });

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" sx={{ borderRight: "none" }}>
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all sensors",
                        }}
                    />
                </TableCell>
                {sensorColumns.map((headCell) => (
                    <TableCell
                        sx={{ borderRight: "none" }}
                        key={headCell.id}
                        align="left"
                        padding="normal"
                    >
                        {headCell.label}
                    </TableCell>
                ))}
                {(isScreed || isScreedActive) && (
                    <TableCell
                        sx={{ borderRight: "none" }}
                        key="screedWarning"
                        align="left"
                        padding="checkbox"
                    />
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function SensorList({
    allSensors,
    handleClick,
    selectedSensors,
    handleSelectAllClick,
    addSensorPosition,
    handleRemoveSensorPosition,
    role,
    sensorType,
}) {
    const [showAddDataDialog, setShowAddDataDialog] = useState(false);
    const [selectedSensor, setSelectedSensor] = useState(null);
    const { sensorData, setSensorData } = useSensorDataContext();

    const addEditDataCallback = (newValue) => {
        const { serialNumber } = newValue[0];
        const containsSerialNumber = selectedSensors.some(
            (data) => data.serialNumber === serialNumber
        );

        if (containsSerialNumber) {
            const newData = [...sensorData, ...newValue];
            newData.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
            setSensorData(newData);
        }
    };

    return (
        <>
            <TableContainer component={Paper} style={{ maxHeight: "100%" }}>
                <Table stickyHeader>
                    <EnhancedTableHead
                        numSelected={selectedSensors.length}
                        rowCount={allSensors.length}
                        onSelectAllClick={handleSelectAllClick}
                        role={role}
                        sensorType={sensorType}
                    />
                    <TableBody>
                        {allSensors.map((sensor) => {
                            const isItemSelected =
                                selectedSensors.indexOf(sensor) !== -1;

                            return (
                                <SensorListRow
                                    sensor={sensor}
                                    handleClick={handleClick}
                                    addSensorPosition={addSensorPosition}
                                    handleRemoveSensorPosition={
                                        handleRemoveSensorPosition
                                    }
                                    setShowAddDataDialog={setShowAddDataDialog}
                                    setSelectedSensor={setSelectedSensor}
                                    role={role}
                                    sensorType={sensorType}
                                    isItemSelected={isItemSelected}
                                    key={sensor.id}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {showAddDataDialog ? (
                <AddEditDataDialog
                    selectedSensor={selectedSensor}
                    sensorType={sensorType}
                    closeCallback={setShowAddDataDialog}
                    isEditing={false}
                    addEditDataCallback={addEditDataCallback}
                />
            ) : null}
        </>
    );
}
