import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getCustomers, getPartners } from "../../../Services/projectService";
import {
    getSensorTypes,
    getSensorTypeRights,
    migrateRh,
    updateSensorType,
    updateSensorTypeRights,
} from "../../../Services/settingsService";
import AlertDialog from "../../ReusableComponents/AlertDialog";

const styles = {
    equation: {
        marginLeft: "15%",
    },
    dialogPaper: {
        minHeight: "50%",
        maxHeight: "50%",
        maxWidth: "30%",
        minWidth: "30%",
    },
};

function UpdateSensorType({ open, onCloseDialog, onAlert, role }) {
    const header = "Update sensor type";
    const functionText = "RH% = af2+bf+c";
    const functionSubText = "Set a = 0 for a linear function";

    const [sensorTypeName, setSensorTypeName] = useState("");
    const [translationFunction] = useState("Quadratic equation");
    const [a, setA] = useState("");
    const [b, setB] = useState("");
    const [c, setC] = useState("");
    const [partners, setPartners] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState({});
    const [grantAll, setGrantAll] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);

    useEffect(() => {
        if (open) {
            getPartners()
                .then((res) => setPartners(res.data.partners))
                .catch((error) => onAlert(error.response.data.error));

            getCustomers()
                .then((res) => setCustomers(res.data.customers))
                .catch((error) => onAlert(error.response.data.error));

            getSensorTypes()
                .then((res) => setTypes(res.data.sensorTypes))
                .catch((error) => onAlert(error.response.data.error));
        }
    }, [onAlert, role, open]);

    const cleanDialog = () => {
        setSelectedType({});
        setSensorTypeName("");
        setA("");
        setB("");
        setC("");
        setSelectedCustomers([]);
        setSelectedPartners([]);
        setGrantAll(false);
    };

    const handleTypeSelected = (selectedSensorType) => {
        if (selectedSensorType) {
            ReactDOM.unstable_batchedUpdates(() => {
                setSelectedType(selectedSensorType);
                setSensorTypeName(selectedSensorType.name);
                setA(selectedSensorType.a);
                setB(selectedSensorType.b);
                setC(selectedSensorType.c);

                getSensorTypeRights(selectedSensorType.id)
                    .then((res) => {
                        setGrantAll(res.data.grantAll[0].grantAllUsers !== 0);

                        const partnersSelected = partners.filter((p) =>
                            res.data.rights.some((x) => x.userId === p.id)
                        );
                        setSelectedPartners(partnersSelected);

                        const customersSelected = customers.filter((p) =>
                            res.data.rights.some((x) => x.userId === p.id)
                        );
                        setSelectedCustomers(customersSelected);
                    })
                    .catch((error) => onAlert(error.response.data.error));
            });
        } else {
            cleanDialog();
        }
    };

    const handleSelectAll = (event) => {
        const { checked } = event.target;
        setGrantAll(checked);
        if (checked) {
            setSelectedPartners(partners);
            setSelectedCustomers(customers);
        } else {
            setSelectedPartners([]);
            setSelectedCustomers([]);
        }
    };

    const handleSave = () => {
        setSaveDisabled(true);
        updateSensorType(
            selectedType.id,
            sensorTypeName,
            translationFunction,
            a,
            b,
            c
        )
            .then(() => {
                const userList = selectedPartners
                    .concat(selectedCustomers)
                    .map((user) => user.id);
                updateSensorTypeRights(selectedType.id, userList, grantAll)
                    .then(() =>
                        onAlert({
                            type: "success",
                            message: "Sensor type updated",
                        })
                    )
                    .catch((error) => onAlert(error.response.data.error));
            })
            .catch((error) => onAlert(error.response.data.error))
            .finally(() => setSaveDisabled(false));
    };

    const handleConvertRh = (confirm) => {
        if (confirm) {
            migrateRh(selectedType.id, translationFunction, a, b, c)
                .then(() =>
                    onAlert({
                        type: "success",
                        message: "Conversion successful",
                    })
                )
                .catch((error) => onAlert(error.response.data.error));
        }
    };

    return (
        <Dialog
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            onClose={() => {
                cleanDialog();
                onCloseDialog(false);
            }}
            open={open}
        >
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <Stack
                    spacing={1}
                    alignItems="flex-start"
                    sx={{ marginTop: "5px" }}
                >
                    <Autocomplete
                        fullWidth
                        options={types}
                        renderOption={(props, option) => (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) =>
                            handleTypeSelected(newValue)
                        }
                        renderInput={(params) => (
                            <TextField {...params} label="Sensor type" />
                        )}
                    />
                    <TextField
                        label="Sensortype name"
                        fullWidth
                        required
                        variant="outlined"
                        value={sensorTypeName}
                        onChange={(e) => setSensorTypeName(e.target.value)}
                    />
                    <TextField
                        label="Translation function"
                        fullWidth
                        required
                        variant="outlined"
                        value={translationFunction}
                        disabled
                    />
                    <Grid>
                        <Typography variant="h5">{functionText}</Typography>
                        <Typography variant="h9">{functionSubText}</Typography>
                    </Grid>
                    <TextField
                        style={styles.equation}
                        label="a"
                        required
                        variant="outlined"
                        type="number"
                        value={a}
                        onChange={(e) => setA(e.target.value)}
                    />
                    <TextField
                        style={styles.equation}
                        label="b"
                        required
                        variant="outlined"
                        type="number"
                        value={b}
                        onChange={(e) => setB(e.target.value)}
                    />
                    <TextField
                        style={styles.equation}
                        label="c"
                        required
                        variant="outlined"
                        type="number"
                        value={c}
                        onChange={(e) => setC(e.target.value)}
                    />
                    <Grid>
                        <Typography variant="h5">Add Rights</Typography>
                        <FormControlLabel
                            label="All"
                            labelPlacement="start"
                            control={
                                <Checkbox
                                    checked={grantAll}
                                    onChange={handleSelectAll}
                                />
                            }
                        />
                    </Grid>
                    <Autocomplete
                        fullWidth
                        limitTags={2}
                        options={partners}
                        value={selectedPartners}
                        multiple
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) =>
                            setSelectedPartners(value ?? [])
                        }
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={selected}
                                    value={option.id}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                label="Partners"
                            />
                        )}
                    />
                    <Autocomplete
                        fullWidth
                        limitTags={2}
                        options={customers}
                        value={selectedCustomers}
                        multiple
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) =>
                            setSelectedCustomers(value ?? [])
                        }
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={selected}
                                    value={option.id}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                label="Customers"
                            />
                        )}
                    />
                    <Button onClick={() => setShowDialog(true)}>
                        Migrate rh to frequency
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={
                        Object.keys(selectedType).length === 0 ||
                        sensorTypeName.length === 0 ||
                        a.length === 0 ||
                        b.length === 0 ||
                        c.length === 0 ||
                        saveDisabled
                    }
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        cleanDialog();
                        onCloseDialog(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
            {showDialog ? (
                <AlertDialog
                    header="Migrate rh"
                    body="Are you sure you want to convert rh to frequency?"
                    confirmButton="Yes"
                    callback={handleConvertRh}
                    setCallback={setShowDialog}
                />
            ) : null}
        </Dialog>
    );
}

export default UpdateSensorType;
