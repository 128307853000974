import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

function CheckBoxForRole({
    partners,
    customers,
    partnersCB,
    customersCB,
    selectedList,
}) {
    const [valuePartner, setPartnerValue] = useState([]);
    const [valueCustomer, setCustomerValue] = useState([]);

    useEffect(() => {
        if (selectedList) {
            setPartnerValue(
                partners.filter(
                    (partner) =>
                        selectedList.filter(
                            (owner) => owner.userId === partner.id
                        ).length !== 0
                )
            );
            setCustomerValue(
                customers.filter(
                    (customer) =>
                        selectedList.filter(
                            (owner) => owner.userId === customer.id
                        ).length !== 0
                )
            );
        }
    }, [partners, customers, selectedList]);

    return (
        <Grid container>
            <Grid item xs={6}>
                <Autocomplete
                    options={partners}
                    multiple
                    limitTags={2}
                    value={valuePartner}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        partnersCB(newValue?.map((v) => v.id) ?? []);
                        setPartnerValue(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox checked={selected} value={option.id} />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            label="Partners"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    options={customers}
                    multiple
                    limitTags={2}
                    value={valueCustomer}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        customersCB(newValue?.map((v) => v.id) ?? []);
                        setCustomerValue(newValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox checked={selected} value={option.id} />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            label="Customers"
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}

export default CheckBoxForRole;
