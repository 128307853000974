import * as sensorApi from "../Api/sensorApi";
import roles from "./roleService";

export function updatePosition(sensorId, x, y, sensorType) {
    return sensorApi.updatePosition(sensorId, x, y, sensorType);
}

export function deleteSensors(sensorIds, sensorType) {
    return sensorApi.deleteSensors(sensorIds, sensorType);
}

export function getSensorData(sensorIds, sensorType) {
    return sensorApi.getSensorData(sensorIds, sensorType);
}

export function addSensorData(sensorData) {
    return sensorApi.addSensorData(sensorData);
}

export function updateSensorData(sensorData) {
    return sensorApi.updateSensorData(sensorData);
}

export function getCorrectedRHValue(id) {
    return sensorApi.getCorrectedRHValue(id);
}

export function deleteSensorData(sensorData, sensorType) {
    return sensorApi.deleteSensorData(sensorData, sensorType);
}

export function getSensorTemplates(sensorType = null) {
    return sensorApi.getSensorTemplates(sensorType);
}

export function deleteSensorTemplates(sensorTemplateId) {
    return sensorApi.deleteSensorTemplates(sensorTemplateId);
}

export function updateSensor(sensor) {
    return sensorApi.updateSensor(sensor);
}

export function updateSensors(ids, sensorFields) {
    return sensorApi.updateSensors(ids, sensorFields);
}

export function createSensor(sensor) {
    return sensorApi.createSensor(sensor);
}

export function updateSensorTemplate(sensorT, owners) {
    return sensorApi.updateSensorTemplate(sensorT, owners);
}

export function createSensorTemplate(sensorT, owners) {
    return sensorApi.createSensorTemplate(sensorT, owners);
}

export function getSensorTypes() {
    return sensorApi.getSensorTypes();
}

export function getSensorTemplatesOwner(sensorTemplateId) {
    return sensorApi.getSensorTemplatesOwner(sensorTemplateId);
}

export function sensorTypesWithRights(role) {
    if (role === roles.Admin) {
        return sensorApi.sensorTypesWithRightsAsAdmin();
    }

    return sensorApi.sensorTypesWithRights();
}

export function screedTypes() {
    return sensorApi.screedTypes();
}

export function sensorBatteryLevel(devEUI, type) {
    return sensorApi.sensorBatteryLevel(devEUI, type);
}

export function sensorSearch(devEUI, type) {
    return sensorApi.sensorSearch(devEUI, type);
}
