import * as statisticsApi from "../Api/statisticsApi";

export function putStatsColumns(filter) {
    return statisticsApi.putStatsColumns(filter);
}

export function getStatsColumns() {
    return statisticsApi.getStatsColumns();
}

export function getInitSites() {
    return statisticsApi.getInitSites();
}

export function getSites(customerId, partnerId, role) {
    return statisticsApi.getSites(customerId, partnerId, role);
}

export function getSitesByCustomer(customerId, role) {
    return statisticsApi.getSites(customerId, null, role);
}

export function getStats(siteId, customerId) {
    return statisticsApi.getStats(siteId, customerId);
}

export const projectsWithStatisticsPage = ["standard", "r.o.s.i", "pipe"];

export function projectToSensorType(project) {
    switch (project) {
        case "standard":
            return "Standard";
        case "r.o.s.i":
            return "R.O.S.I";
        case "pipe":
            return "Pipe";
        default:
            return "N/A";
    }
}
