import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as api from "../../../Services/settingsService";
import roles from "../../../Services/roleService";

const filter = createFilterOptions();

function ChangeMeasurer({ role, open, onCloseDialog, onAlert }) {
    const header = "Update measurer";
    const [measurer, setMeasurer] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [measurers, setMeasurers] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);

    // Reset Defaults
    useEffect(() => {
        setMeasurer("");
        setUsername("");
        setPassword("");
        setConfirm("");
    }, [open]);

    // set measurer list
    useEffect(() => {
        if (role === roles.Admin || role === roles.Partner) {
            api.getMeasurers()
                .then((res) => setMeasurers(res.data.measurers))
                .catch(() =>
                    onAlert({
                        type: "error",
                        message: "failed to get measurers",
                    })
                );
        }
    }, [onAlert, role, open]);

    // Set values based on measurer
    useEffect(() => {
        if (measurer) {
            setUsername(measurer.name ?? "");
        }
    }, [measurer]);

    const handleSave = () => {
        const user = {
            userId: measurer.id,
            username,
            password,
        };
        api.updateUser(user)
            .then(() => onCloseDialog(false))
            .catch((err) => onAlert({ message: err.response.data.error }));
    };

    const allowSave = measurer && username && password === confirm;

    return (
        <Dialog open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent sx={{ maxWidth: "500px", paddingBottom: "0px" }}>
                <Stack spacing={1} sx={{ paddingTop: "10px" }}>
                    <Autocomplete
                        label="Select measurer"
                        options={measurers}
                        renderInput={(params) => (
                            <TextField {...params} label="Measurer" />
                        )}
                        onChange={(event, value) => {
                            setMeasurer(value ?? -1);
                        }}
                        getOptionLabel={(option) =>
                            typeof option === "string"
                                ? option
                                : option.name ?? ""
                        }
                        filterOptions={(options, params) =>
                            filter(options, params)
                        }
                    />
                    <TextField
                        value={username}
                        fullWidth
                        label="Measurer name"
                        variant="outlined"
                        margin="dense"
                        onChange={(text) => setUsername(text.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="New password"
                        variant="outlined"
                        margin="dense"
                        type={showPassword ? "text" : "password"}
                        onChange={(text) => setPassword(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Confirm password"
                        variant="outlined"
                        margin="dense"
                        type={showConfirm ? "text" : "password"}
                        onChange={(text) => setConfirm(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowConfirm(!showConfirm)
                                        }
                                    >
                                        {showConfirm ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} disabled={!allowSave}>
                    Save
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangeMeasurer;
