import * as floorApi from "../Api/floorApi";

export function getFloors(projectId) {
    return floorApi.getFloors(projectId);
}

export function getFloorsBySensorType(projectId, sensorType) {
    return floorApi.getFloorsBySensorType(projectId, sensorType);
}

export function addNewFloor(projectId, name) {
    return floorApi.addNewFloor(projectId, name);
}

export function deleteFloor(floorId) {
    return floorApi.deleteFloor(floorId);
}

export function UpdateFloorName(floorId, name) {
    return floorApi.UpdateFloorName(floorId, name);
}
