import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, IconButton } from "@mui/material";
import AlertDialog from "../ReusableComponents/AlertDialog";
import * as projectService from "../../Services/projectService";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";

function ProjectMenuButton({ id, name, callback, customerId }) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState({
        target: null,
        projectId: null,
    });

    const [alert, setAlert] = useState({ type: "error", message: "" });

    const [showDialog, setShowDialog] = useState(false);

    const open = Boolean(anchorEl.target);
    const projectId = id;

    const handleClick = (event) => {
        setAnchorEl({
            target: event.target,
            projectId,
        });
    };

    const handleClose = () => {
        if (anchorEl.projectId === null) {
            return;
        }

        setAnchorEl({
            target: null,
            projectId: null,
        });
    };

    const handleDelete = (confirm) => {
        if (confirm) {
            projectService
                .deleteProject(anchorEl.projectId)
                .then(() => {
                    setAnchorEl({
                        target: null,
                        projectId: null,
                    });
                    callback(anchorEl.projectId);
                    setAlert({
                        type: "success",
                        message: "successfully removed project",
                    });
                })
                .catch((error) => setAlert(error.response.data.error));
        }
        setShowDialog(false);
    };

    const handleEdit = () => {
        localStorage.setItem(
            "CreateEditProject",
            JSON.stringify({
                isNew: false,
                projectId,
                customerId,
            })
        );
        navigate("/create-edit-project");
    };

    return (
        <>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <IconButton
                expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: "white" }}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu anchorEl={anchorEl.target} open={open} onClose={handleClose}>
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem
                    sx={{ color: "red" }}
                    onClick={() => setShowDialog(true)}
                >
                    Delete
                </MenuItem>
            </Menu>
            {showDialog ? (
                <AlertDialog
                    header="Remove Project"
                    body={`Are you sure you want to delete project ${name}?`}
                    confirmButton="Delete"
                    callback={handleDelete}
                    setCallback={setShowDialog}
                />
            ) : null}
        </>
    );
}

export default ProjectMenuButton;
