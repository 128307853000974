import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    DialogContent,
    Chip,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    Box,
} from "@mui/material";
import AlertDialog from "../ReusableComponents/AlertDialog";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import TopBar from "../ReusableComponents/TopBar";
import CustomDragLayer from "./CustomDragLayer";
import * as sensorService from "../../Services/sensorService";
import * as objectService from "../../Services/objectService";
import PanContainer from "./PanContainer.tsx";
import ProjectButtons from "../Dashboard/ProjectButtons";
import Measurements from "./Measurements.tsx";
import SensorList from "./SensorList";
import roles from "../../Services/roleService";
import { SensorDataContextProvider } from "./Contexts/SensorDataContext";
import TemplateDialog from "./TemplateDialog";
import { useRoleContext } from "../../Context/RoleContext";

const styles = {
    dialogPaper: {
        minHeight: "100%",
        maxHeight: "100%",
        maxWidth: "95%",
        minWidth: "95%",
    },
    row: {
        paddingLeft: "10px",
        paddingRight: "5px",
        justifyContent: "center",
        paddingTop: "20px",
    },
    button: {
        display: "flex",
        backgroundColor: "#24242d",
        borderRadius: "8px",
        height: "40px",
        "&:hover": {
            cursor: "pointer",
        },
    },
    buttonText: {
        margin: "10px 20px 0 20px",
        fontSize: "18px",
        color: "white",
    },
};

function ObjectDialog() {
    const sensorType = useLocation().pathname.split("/").at(-1);
    const { objectId } = useParams();
    const [objectName, setObjectName] = useState("");
    const [alert, setAlert] = useState({ type: "error", message: "" });
    const [allSensors, setAllSensors] = useState([]);
    const [placedSensors, setPlacedSensors] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [blueprint, setBlueprint] = useState({});
    const [blueprintName, setBlueprintName] = useState({});
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [showTemplateDialog, setShowTemplateDialog] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [sensorTypes, setSensorTypes] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState({
        header: "",
        body: "",
        callback: {},
    });
    const [parentNames, setParentNames] = useState({});
    const { role } = useRoleContext();

    const hiddenBlueprintUpload = useRef(null);
    const navigate = useNavigate();
    const projectId = localStorage.getItem("projectId");
    const previousPath =
        projectId !== "createEdit"
            ? `/info-modal/${projectId}`
            : "/create-edit-project";
    let sensorPlacementToRemove = {};

    useEffect(() => {
        setSelectedSensors([]);
        objectService
            .getObject(objectId, sensorType)
            .then((res) => {
                ReactDOM.unstable_batchedUpdates(() => {
                    setObjectName(res.data.objectdata.name);
                    setBlueprintName(res.data.objectdata.blueprintName);
                    const adjustedSensorList = res.data.sensors
                        .map((sensor) => ({
                            ...sensor,
                            serialNumber: Number(
                                sensor.serialNumber.split("-")[1]
                            ),
                        }))
                        .sort((a, b) => a.serialNumber - b.serialNumber);
                    setAllSensors([...adjustedSensorList]);
                    setPlacedSensors([
                        ...adjustedSensorList.filter(
                            (sensor) =>
                                sensor.positionX !== null &&
                                sensor.positionY !== null
                        ),
                    ]);
                    setSensorTypes(
                        res.data.sensorType.map((type) => ({
                            id: type.id,
                            name: type.name,
                        }))
                    );
                    if (res.data.s3data !== undefined) {
                        setBlueprint(res.data.s3data);
                    } else {
                        setAlert({
                            type: "info",
                            message: "No blueprint for this object",
                        });
                    }
                    setParentNames(res.data.parentNames[0]);
                });
            })
            .catch((err) =>
                setAlert({
                    type: "error",
                    message: `failed to get object with error: ${err.message}`,
                })
            );
    }, [objectId, sensorType]);

    const changeObjectName = (newName) => {
        objectService
            .updateObjectName(objectId, newName)
            .then(() => {
                setObjectName(newName);
                setAlert({
                    type: "success",
                    message: `changed name to ${newName}`,
                });
            })
            .catch((err) =>
                setAlert({
                    type: "error",
                    message: `failed to update name with error: ${err.message}`,
                })
            );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectedSensors([...allSensors]);
        } else {
            setSelectedSensors([]);
        }
    };

    const sliceArray = (array, sliceValue) => {
        const sliceIndex = array.indexOf(sliceValue);
        if (sliceIndex === -1) {
            return [].concat(array, sliceValue);
        }
        if (sliceIndex === 0) {
            return [].concat(array.slice(1));
        }
        if (sliceIndex === array.length - 1) {
            return [].concat(array.slice(0, -1));
        }
        return [].concat(
            array.slice(0, sliceIndex),
            array.slice(sliceIndex + 1)
        );
    };

    const handleClick = (event, sensor) => {
        const newSelected = sliceArray(selectedSensors, sensor);
        setSelectedSensors(newSelected);
    };

    const updateSensorPosition = (sensor, newX, newY) => {
        sensorService
            .updatePosition(sensor.id, newX, newY, sensorType)
            .then(() => {
                const selectedSensorIndex = allSensors.findIndex(
                    (s) => s.id === sensor.id
                );
                allSensors[selectedSensorIndex].positionX = newX;
                allSensors[selectedSensorIndex].positionY = newY;
                setAllSensors([...allSensors]);
                setPlacedSensors([
                    ...allSensors.filter(
                        (s) => s.positionX !== null && s.positionY !== null
                    ),
                ]);
                setAlert({
                    type: "success",
                    message: "Sensor position was updated",
                });
            })
            .catch(() =>
                setAlert({
                    type: "error",
                    message: "failed to update sensor position.",
                })
            );
    };

    const addSensorPosition = (sensor) => {
        const i = new Image();
        i.src = `data:image/jpeg;base64,${blueprint.contentbody}`;
        i.onload = () => {
            updateSensorPosition(sensor, i.width / 2, i.height / 2);
        };
    };

    const RemoveSensorPosition = (resp) => {
        if (resp) {
            updateSensorPosition(sensorPlacementToRemove, null, null);
        }
    };

    const handleRemoveSensorPosition = (sensor) => {
        sensorPlacementToRemove = sensor;
        setShowAlertDialog(true);
        setDeleteAlert({
            header: "Remove sensor placement",
            body: `Are you sure you want to remove placement for sensor ${sensorPlacementToRemove.name}?`,
            callback: RemoveSensorPosition,
        });
    };

    const handleEditSensor = () => {
        if (selectedSensors.length === 1) {
            navigate("/createeditsensor", {
                state: {
                    sensorTypes,
                    update: true,
                    num: selectedSensors[0].serialNumber,
                    objectId,
                    id: selectedSensors[0].id,
                    name: selectedSensors[0].name,
                    color: selectedSensors[0].color,
                    selectedSensorType: sensorTypes.find(
                        (element) => element.name.toLowerCase() === sensorType
                    ),
                    technician: selectedSensors[0].technician,
                    installationDate: selectedSensors[0].installationDate,
                    description: selectedSensors[0].description,
                    back: selectedSensors[0].backMat,
                    front: selectedSensors[0].frontMat,
                    devEUI: selectedSensors[0].deveui,
                    screedDepth: selectedSensors[0].screedDepth,
                    screedBlockSize: selectedSensors[0].screedBlockSize,
                    screedType: selectedSensors[0].screedType,
                    reportInterval: selectedSensors[0].reportInterval,
                },
            });
        } else {
            navigate("/editsensors", {
                state: {
                    objectId,
                    ids: selectedSensors.map((s) => s.id),
                    sensorTypeName: sensorTypes.find(
                        (element) => element.name.toLowerCase() === sensorType
                    ).name,
                },
            });
        }
    };

    const handleAddSensor = () => {
        const num =
            allSensors.length > 0
                ? Math.max(...allSensors.map((sensor) => sensor.serialNumber)) +
                  1
                : 1;
        navigate("/createeditsensor", {
            state: {
                sensorTypes,
                update: false,
                num,
                objectId,
                id: -1,
                name: "",
                color: "#095E8F",
                selectedSensorType: sensorTypes.find(
                    (element) => element.name.toLowerCase() === sensorType
                ),
                technician: "",
                installationDate: new Date().toISOString().split("T")[0],
                description: "",
                back: "",
                front: "",
                devEUI: "",
                screedDepth: null,
                screedBlockSize: null,
                screedType: null,
            },
        });
    };

    const handleDeleteSelectedSensor = (resp) => {
        if (resp) {
            sensorService
                .deleteSensors(
                    selectedSensors.map((sensor) => sensor.id),
                    sensorType
                )
                .then(() => {
                    const newAllSensors = allSensors.filter(
                        (sensor) => !selectedSensors.includes(sensor)
                    );
                    setAllSensors([...newAllSensors]);
                    setPlacedSensors([
                        ...newAllSensors.filter(
                            (sensor) =>
                                sensor.positionX !== null &&
                                sensor.positionY !== null
                        ),
                    ]);
                    setSelectedSensors([]);
                    setAlert({
                        type: "success",
                        message: "Sensor was successfully removed",
                    });
                })
                .catch((err) =>
                    setAlert({ type: "error", message: err.message })
                );
        }
    };

    const handleBlueprintUpload = (event) => {
        setUploading(true);
        objectService
            .uploadBlueprint(event.target.files[0], objectId)
            .then((res) => {
                setBlueprint(res.data);
                setUploading(false);
            })
            .catch((err) => {
                setUploading(false);
                setAlert({ type: "error", message: err.message });
            });
    };

    const handleBlueprintRemove = (resp) => {
        if (resp) {
            setUploading(true);
            objectService
                .deleteBlueprint(objectId)
                .then(() => {
                    setUploading(false);
                    setBlueprint({});
                })
                .catch((err) => {
                    setUploading(false);
                    setAlert({ type: "error", message: err.message });
                });
        }
    };

    const handleOnDeleteClick = (type) => {
        if (type === "sensors") {
            setShowAlertDialog(true);
            setDeleteAlert({
                header: "Delete Selected Sensors",
                body: `Are you sure you want to delete selected sensors: ${selectedSensors.map(
                    (sensor) => sensor.id
                )}`,
                callback: handleDeleteSelectedSensor,
            });
        } else if (type === "blueprint") {
            setShowAlertDialog(true);
            setDeleteAlert({
                header: "Delete Blueprint",
                body: "Are you sure you want to delete the blueprint?",
                callback: handleBlueprintRemove,
            });
        }
    };

    const saveObjectTemplate = (name) => {
        objectService.addObjectTemplate(name, blueprintName, allSensors);
    };

    return (
        <Dialog
            open
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <TopBar
                canEdit={role !== roles.Measurer}
                previousPath={previousPath}
                callback={changeObjectName}
                title={objectName}
                parentNames={parentNames}
            />
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <DialogContent>
                <SensorDataContextProvider>
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={6}
                            sx={{ ...styles.row, height: "fit-content" }}
                        >
                            <Grid
                                container
                                item
                                spacing={2}
                                justifyContent="left"
                                sx={{ paddingLeft: "20px" }}
                            >
                                <Grid item>
                                    <ProjectButtons
                                        basePath={`object/${objectId}`}
                                        objectId={objectId}
                                    />
                                </Grid>
                                {sensorType === "standard" && (
                                    <Grid item>
                                        <Box
                                            sx={styles.button}
                                            onClick={() =>
                                                setShowTemplateDialog(true)
                                            }
                                        >
                                            <Typography sx={styles.buttonText}>
                                                Save as template
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                sx={{
                                    ...styles.row,
                                    minHeight: "500px",
                                    maxHeight: "500px",
                                }}
                            >
                                <DndProvider backend={HTML5Backend}>
                                    <PanContainer
                                        selectedSensors={selectedSensors}
                                        sensors={placedSensors}
                                        blueprint={blueprint}
                                        blueprintName={blueprintName}
                                        updateSensorPosition={
                                            updateSensorPosition
                                        }
                                    />
                                    <CustomDragLayer />
                                </DndProvider>
                            </Grid>
                            <Grid
                                item
                                container
                                sx={{ ...styles.row, height: "fit-content" }}
                            >
                                <Grid
                                    item
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="h4">
                                            Sensors
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            disabled={
                                                sensorTypes.length === 0 ||
                                                role === roles.Measurer
                                            }
                                            label={
                                                selectedSensors.length === 0
                                                    ? "Add Sensor"
                                                    : "Edit selected"
                                            }
                                            icon={
                                                selectedSensors.length === 0 ? (
                                                    <AddIcon
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    />
                                                ) : (
                                                    <EditIcon
                                                        style={{
                                                            color: "blue",
                                                        }}
                                                    />
                                                )
                                            }
                                            onClick={
                                                selectedSensors.length === 0
                                                    ? handleAddSensor
                                                    : handleEditSensor
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            label="Delete selected"
                                            icon={
                                                <CloseIcon
                                                    style={{ color: "red" }}
                                                />
                                            }
                                            onClick={() =>
                                                handleOnDeleteClick("sensors")
                                            }
                                            disabled={
                                                selectedSensors.length === 0 ||
                                                role === roles.Measurer
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            disabled={
                                                objectName.length === 0 ||
                                                role === roles.Measurer
                                            }
                                            label={
                                                Object.keys(blueprint)
                                                    .length === 0
                                                    ? "Upload blueprint"
                                                    : "Delete Blueprint"
                                            }
                                            icon={
                                                Object.keys(blueprint)
                                                    .length === 0 ? (
                                                    uploading ? (
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <FileUploadIcon
                                                            style={{
                                                                color: "green",
                                                            }}
                                                        />
                                                    )
                                                ) : (
                                                    <DeleteIcon
                                                        style={{ color: "red" }}
                                                    />
                                                )
                                            }
                                            onClick={() =>
                                                Object.keys(blueprint)
                                                    .length === 0
                                                    ? hiddenBlueprintUpload.current.click()
                                                    : handleOnDeleteClick(
                                                          "blueprint"
                                                      )
                                            }
                                        />
                                        <input
                                            type="file"
                                            ref={hiddenBlueprintUpload}
                                            style={{ display: "none" }}
                                            onChange={handleBlueprintUpload}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <SensorList
                                        handleClick={handleClick}
                                        allSensors={allSensors}
                                        selectedSensors={selectedSensors}
                                        addSensorPosition={addSensorPosition}
                                        handleSelectAllClick={
                                            handleSelectAllClick
                                        }
                                        handleRemoveSensorPosition={
                                            handleRemoveSensorPosition
                                        }
                                        role={role}
                                        sensorType={sensorType}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6} sx={{ ...styles.row }}>
                            <Grid
                                item
                                container
                                sx={{ ...styles.row, height: "95%" }}
                            >
                                <Measurements
                                    sensorType={sensorType}
                                    selectedSensors={selectedSensors}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {showAlertDialog && (
                        <AlertDialog
                            header={deleteAlert.header}
                            body={deleteAlert.body}
                            confirmButton="Delete"
                            setCallback={setShowAlertDialog}
                            callback={deleteAlert.callback}
                        />
                    )}
                    {showTemplateDialog && (
                        <TemplateDialog
                            confirmButton="Save"
                            setCallback={setShowTemplateDialog}
                            saveCallback={saveObjectTemplate}
                        />
                    )}
                </SensorDataContextProvider>
            </DialogContent>
        </Dialog>
    );
}

export default ObjectDialog;
