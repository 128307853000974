import * as objectApi from "../Api/objectApi";

export function getObject(objectId, typeName) {
    return objectApi.getObject(objectId, typeName);
}

export function getObjects(floorId) {
    return objectApi.getObjects(floorId);
}

export function getObjectsBySensorType(floorId, sensorType) {
    return objectApi.getObjectsBySensorType(floorId, sensorType);
}

export function deleteObject(objectId) {
    return objectApi.deleteObject(objectId);
}

export function addNewObject(floorId, name) {
    return objectApi.addNewObject(floorId, name);
}

export function uploadBlueprint(file, id) {
    return objectApi.uploadBlueprint(file, id);
}

export function updateObjectName(objectId, newName) {
    return objectApi.updateObjectName(objectId, newName);
}

export function deleteBlueprint(objectId) {
    return objectApi.deleteBlueprint(objectId);
}

export function addObjectTemplate(name, blueprintName, sensors) {
    return objectApi.addObjectTemplate(name, blueprintName, sensors);
}

export function getObjectTemplate() {
    return objectApi.getObjectTemplate();
}

export function addNewObjectFromTemplate(floorId, template) {
    return objectApi.addNewObjectFromTemplate(floorId, template);
}

export function deleteObjectTemplate(id) {
    return objectApi.deleteObjectTemplate(id);
}
