import { Scatter } from "react-chartjs-2";
import { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import utc from "dayjs/plugin/utc";

import GraphFilter from "./GraphFilter";
import * as ConcreteChart from "./ChartFunctions/ConcreteChartFunctions";
import * as ScreedChart from "./ChartFunctions/ScreedChartFunctions";
import {
    roundedDownToNearest,
    roundedUpToNearest,
} from "../../Utils/mathUtils";
import { sensorIsTwoPointZero } from "../../Utils/sensorUtils";

dayjs.extend(utc);

Chart.register(...registerables);

const graphPaddingValue = 5;

export default function SensorChart({
    selectedSensorsData,
    toggle,
    sensorType,
    correctedRH,
}) {
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [minMaxDataAll, setMinMaxDataAll] = useState({}); // Min/max values of all data from sensors
    const [minMaxDataUser, setMinMaxDataUser] = useState({}); // Min/max values provided from user in graph filter
    const [minMaxDataGraph, setMinMaxDataGraph] = useState({}); // Min/max values sent to scatter graph
    const sensorCountPrev = useRef(0);
    const sensorDataCountPrev = useRef(selectedSensorsData.data.length);

    useEffect(() => {
        const isConcrete = sensorType === "concrete";
        const selectedSensors = selectedSensorsData.sensors;

        let containsTwoPointZero = false;
        if (isConcrete)
            containsTwoPointZero = selectedSensors.some((sensor) =>
                sensorIsTwoPointZero(sensor.deveui)
            );

        const minMaxData = isConcrete
            ? ConcreteChart.getMinMaxData(selectedSensorsData.data)
            : ScreedChart.getMinMaxData(selectedSensorsData.data);
        setMinMaxDataAll(minMaxData);

        let minDateGraph;
        let defaultMinDate = isConcrete
            ? dayjs(minMaxData.maxDate).subtract(2, "month")
            : minMaxData.minDate;
        if (containsTwoPointZero) defaultMinDate = minMaxData.minDate;

        if (!minMaxDataUser.minDate) {
            minDateGraph = defaultMinDate;
        } else {
            minDateGraph = minMaxDataUser.minDate;
        }
        minDateGraph = new Date(minDateGraph);

        let maxDateGraph;
        if (!minMaxDataUser.maxDate) {
            maxDateGraph = minMaxData.maxDate;
        } else {
            maxDateGraph = minMaxDataUser.maxDate;
        }
        maxDateGraph = new Date(maxDateGraph);

        setMinMaxDataGraph((prev) => ({
            ...prev,
            minDate: minDateGraph,
            maxDate: maxDateGraph,
        }));

        let chartData;
        if (isConcrete) {
            if (toggle === "rh") {
                chartData = ConcreteChart.generateRhChartData(
                    selectedSensorsData.data
                );
            } else if (toggle === "temp") {
                chartData = ConcreteChart.generateTempChartData(
                    selectedSensorsData.data
                );
            } else {
                chartData = ConcreteChart.generateBothChartData(
                    selectedSensorsData.data
                );
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (toggle === "rh") {
                chartData = ScreedChart.generateRhChartData(
                    selectedSensorsData.data,
                    correctedRH
                );
            } else if (toggle === "temp") {
                chartData = ScreedChart.generateTempChartData(
                    selectedSensorsData.data
                );
            } else {
                chartData = ScreedChart.generateBothChartData(
                    selectedSensorsData.data,
                    correctedRH
                );
            }
        }

        setData({
            labels: [],
            datasets:
                toggle === "both"
                    ? [
                          ...chartData.chartData.tempData,
                          ...chartData.chartData.rhData,
                      ]
                    : chartData.chartData[`${toggle}Data`],
        });

        // Give some padding to data
        const minRh = roundedDownToNearest(
            chartData.chartData.minRh,
            graphPaddingValue
        );
        const maxRh = roundedUpToNearest(
            chartData.chartData.maxRh,
            graphPaddingValue
        );
        const minTemp = roundedDownToNearest(
            chartData.chartData.minTemp,
            graphPaddingValue
        );
        const maxTemp = roundedUpToNearest(
            chartData.chartData.maxTemp,
            graphPaddingValue
        );

        const sensorCount = selectedSensorsData.sensors.length;
        const sensorDiff = sensorCount !== sensorCountPrev.current;
        sensorCountPrev.current = sensorCount;

        const dataDiff =
            selectedSensorsData.data.length !== sensorDataCountPrev.current;
        sensorDataCountPrev.current = selectedSensorsData.data.length;

        if (sensorDiff || dataDiff) {
            setMinMaxDataGraph({
                minRh,
                maxRh,
                minTemp,
                maxTemp,
                minDate: new Date(defaultMinDate),
                maxDate: new Date(minMaxData.maxDate),
            });

            setMinMaxDataUser({
                minRh,
                maxRh,
                minTemp,
                maxTemp,
                minDate: new Date(defaultMinDate),
                maxDate: new Date(minMaxData.maxDate),
            });
        }
    }, [
        selectedSensorsData,
        toggle,
        sensorType,
        correctedRH,
        sensorCountPrev,
        minMaxDataUser.minDate,
        minMaxDataUser.maxDate,
    ]);

    // Dont re-fetch data if only changing temp/rh scale
    useEffect(() => {
        setMinMaxDataGraph((prev) => ({
            ...prev,
            minRh: minMaxDataUser.minRh,
            maxRh: minMaxDataUser.maxRh,
            minTemp: minMaxDataUser.minTemp,
            maxTemp: minMaxDataUser.maxTemp,
        }));
    }, [
        minMaxDataUser.minTemp,
        minMaxDataUser.maxTemp,
        minMaxDataUser.minRh,
        minMaxDataUser.maxRh,
    ]);

    const tempAxisOption = {
        bezierCurve: false,
        position: "left",
        beginAtZero: true,
        title: {
            display: true,
            text: "Temperature (°C)",
        },
        grid: {
            drawOnChartArea: false,
        },
        min: minMaxDataGraph.minTemp,
        max: minMaxDataGraph.maxTemp,
        ticks: {
            callback: (val) => (Number.isInteger(val) ? val : null), // this removes decimals, without it, it would result in 50.0000000000000
        },
    };

    const rhAxisOption = {
        bezierCurve: false,
        position: "left",
        beginAtZero: true,
        ticks: {
            callback: (val) => (Number.isInteger(val) ? val : null), // this removes decimals, without it, it would result in 50.0000000000000
        },

        title: {
            display: true,
            text: "Rh (%)",
        },
        grid: {
            drawOnChartArea: false,
        },
        min: minMaxDataGraph.minRh,
        max: minMaxDataGraph.maxRh,
    };

    const xAxisOption = {
        type: "time",
        time: {
            displayFormats: {
                day: "YYYY-MM-DD",
            },
            parser: "YYYY-MM-DD hh:mm:ss",
            unit: "day",
        },

        ticks: {
            source: minMaxDataAll.maxDate ? null : "labels",
            minRotation: 30,
        },
        min: new Date(minMaxDataGraph.minDate),
        max: new Date(minMaxDataGraph.maxDate),
    };

    const pluginOptions = {
        decimation: {
            algorithm: "lltb", // lltb or min-max
            enabled: true,
        },
    };

    const tempOption = {
        animation: false,
        showLine: true,
        scales: {
            temperature: tempAxisOption,
            x: xAxisOption,
        },
        parsing: false,
        plugins: pluginOptions,
    };

    const rhOption = {
        animation: false,
        showLine: true,
        scales: {
            rh: rhAxisOption,
            x: xAxisOption,
        },
        parsing: false,
        plugins: pluginOptions,
    };

    const bothOption = {
        animation: false,
        showLine: true,
        scales: {
            temperature: {
                ...tempAxisOption,
                position: "right",
            },
            rh: rhAxisOption,
            x: xAxisOption,
        },
        parsing: false,
        plugins: pluginOptions,
    };

    return (
        <Grid container>
            <Grid item>
                <GraphFilter
                    minMaxDataAll={minMaxDataAll}
                    minMaxDataUser={minMaxDataUser}
                    setMinMaxDataUser={setMinMaxDataUser}
                    toggle={toggle}
                />
            </Grid>
            <Grid item xs={12}>
                {toggle === "rh" ? (
                    <Scatter data={data} options={rhOption} type="scatter" />
                ) : toggle === "temp" ? (
                    <Scatter data={data} options={tempOption} type="scatter" />
                ) : toggle === "both" ? (
                    <Scatter data={data} options={bothOption} type="scatter" />
                ) : null}
            </Grid>
        </Grid>
    );
}
