import Axios from "axios";
import roles from "../Services/roleService";

export function createSensorType(
    sensorTypeName,
    formula,
    a,
    b,
    c,
    checkedUsers
) {
    return Axios.post("/api/sensor_type", {
        sensorTypeName,
        formula,
        a,
        b,
        c,
        checkedUsers,
    });
}

export function updateRights(userId, role, rights) {
    return Axios.put("/api/rights", { userId, role, rights });
}

export function getProjectRights(id, role) {
    return Axios.get("/api/rights", { params: { id, role } });
}

export function setNewPassword(newPassword) {
    return Axios.put("/api/new_password", {
        password: newPassword,
    });
}

export function addPartner(partner) {
    return Axios.post("/api/partner", partner);
}

export function deletePartner(partnerId) {
    return Axios.delete("/api/delete_user", {
        data: {
            id: partnerId,
            role: roles.Partner, // always a partner we delete here
        },
    });
}

export function getPartners() {
    return Axios.get("/api/get_partners");
}

export function getCustomersAsPartner() {
    return Axios.get("/api/get_customers_as_partner");
}

export function getCustomersAsAdmin(partnerId) {
    return Axios.get("/api/get_customers_as_admin", {
        params: {
            partnerId,
        },
    });
}

export function updateUser(user) {
    return Axios.put("/api/update_user", user);
}

export function moveProject(customerId, siteId, newCustomerId) {
    return Axios.put("/api/move_site", {
        customerId,
        siteId,
        newCustomerId,
    });
}

export function addNewCustomer(username, password, ownerId) {
    return Axios.post("/api/add_user", {
        username,
        password,
        role: roles.Customer, // always a customer we add here
        ownerId,
    });
}

export function deleteCustomer(customerId) {
    return Axios.delete("/api/delete_user", {
        data: {
            id: customerId,
            role: roles.Customer, // always a customer we delete here
        },
    });
}

export function getSensorTypes() {
    return Axios.get("/api/sensor_types");
}

export function deleteSensorType(id) {
    return Axios.delete("/api/sensor_type", {
        data: {
            sensorTypeId: id,
        },
    });
}

export function getSensorTypeRights(typeId) {
    return Axios.get("/api/sensor_type_rights", {
        params: {
            sensorTypeId: typeId,
        },
    });
}

export function updateSensorType(typeId, name, formula, a, b, c) {
    return Axios.put("/api/sensor_type", {
        sensorTypeId: typeId,
        sensorTypeName: name,
        formula,
        a,
        b,
        c,
    });
}

export function updateSensorTypeRights(typeId, checkedUsers, grantAll) {
    return Axios.put("/api/sensor_type_rights", {
        sensorTypeId: typeId,
        checkedUsers,
        grantAll,
    });
}

export function migrateRh(typeId, formula, a, b, c) {
    return Axios.put("/api/migrate_rh", {
        sensorTypeId: typeId,
        formula,
        a,
        b,
        c,
    });
}

// --- measurer

export function addMeasurer(username, password, email) {
    return Axios.post("/api/add_user", {
        username,
        password,
        role: roles.Measurer,
        email,
        ownerId: null,
    });
}

export function deleteMeasurer(userId) {
    return Axios.delete("/api/delete_user", {
        data: {
            id: userId,
            role: roles.Measurer, // always a measurer we delete here
        },
    });
}

export function getMeasurers() {
    return Axios.get("/api/get_measurers");
}

export function assignMeasurer(measurerId, siteIds, customerId) {
    return Axios.put("/api/assign_measurer", {
        data: {
            id: measurerId,
            siteIds,
            customer: customerId,
        },
    });
}

export function getMeasurerProjectsByCustomer(measurerId, customerId) {
    return Axios.get("/api/measurer_projects_by_customer", {
        params: {
            measurerId,
            customerId,
        },
    });
}

export function updateCorrectedRHValues(sensorIds = []) {
    return Axios.post("/api/update_corrected_rh_values", {
        sensorIds,
    });
}
