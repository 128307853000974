import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";

import { useState } from "react";
import { sensorSearch } from "../../../Services/sensorService";

const styles = {
    dialogPaper: {
        minHeight: "40%",
        maxHeight: "40%",
        maxWidth: "40%",
        minWidth: "40%",
    },
};

export default function SearchByDevEUI({ open, closeCB }) {
    const [devEUI, setDevEUI] = useState("");
    const [type, setType] = useState("concrete");
    const [sensors, setSensors] = useState([]);

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleSearch = async () => {
        const res = (await sensorSearch(devEUI, type)).data.result;
        setSensors(res);
    };

    const getSensorString = (data) =>
        `Partner: ${data.partner.name}, Customer: ${data.customer.name}, Project: ${data.project.name}, Floor: ${data.floor.name}, Object: ${data.object.name}, Sensor: ${data.sensor.name}`;

    return (
        <Dialog
            disableEnforceFocus
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            onClose={() => {
                closeCB(false);
            }}
            open={open}
        >
            <DialogTitle>Search by devEUI</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <TextField
                            label="devEUI"
                            fullWidth
                            required
                            variant="outlined"
                            margin="dense"
                            value={devEUI}
                            onChange={(e) => setDevEUI(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        <Select
                            labelId="typeLabel"
                            value={type}
                            onChange={handleTypeChange}
                        >
                            <MenuItem value="concrete">Concrete</MenuItem>
                            <MenuItem value="activescreed">
                                Active screed
                            </MenuItem>
                        </Select>
                    </Grid>
                    {sensors.map((sensor) => (
                        <Grid item xs={12} key={sensor.object.id}>
                            {getSensorString(sensor)}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSearch} disabled={devEUI === ""}>
                    Search
                </Button>
                <Button
                    onClick={() => {
                        closeCB(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
