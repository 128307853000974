import React, { useEffect, useState } from "react";
import {
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TableChartIcon from "@mui/icons-material/TableChart";
import * as statisticsService from "../../Services/statisticsService";
import CustomToolbar from "../ReusableComponents/CustomToolbarDataGrid";
import SelectorsForRoles from "../ReusableComponents/SelectorsForRoles";
import * as projectService from "../../Services/projectService";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import roles from "../../Services/roleService";
import ProjectButtons from "./ProjectButtons";
import { projectToSensorType } from "../../Services/statisticsService";
import { useRoleContext } from "../../Context/RoleContext";

const styles = {
    grid: {
        marginTop: "45px",
        marginLeft: "75px",
        alignItems: "center",
        width: "100%",
    },
    dataGridGrid: {
        width: "95%",
        height: "70vh",
        marginRight: 5,
        paddingTop: "20px",
    },
};

const defaultSites = [
    {
        siteId: "default",
        name: "Select a site",
        address: "-",
        postal: "-",
        city: "-",
        country: "-",
    },
    {
        siteId: "alla",
        name: "All",
        address: "-",
        postal: "-",
        city: "-",
        country: "-",
    },
];

function Statistics() {
    const [alert, setAlert] = useState({ type: "error", message: "" });
    const [sites, setSites] = useState([defaultSites[0]]);
    const [selectedSite, setSelectedSite] = useState(defaultSites[0]);
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const defaultColumns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            hide: true,
        },
        {
            field: "sensorId",
            headerName: "Sensor ID",
            minWidth: 100,
            hide: false,
        },
        {
            field: "serialNumber",
            headerName: "Sensor number",
            minWidth: 125,
            hide: false,
            align: "center",
        },
        {
            field: "installationDate",
            headerName: "Installation date",
            minWidth: 140,
            hide: false,
        },
        {
            field: "sensorName",
            headerName: "Sensor name",
            minWidth: 125,
            hide: false,
        },
        {
            field: "measurementDate",
            headerName: "Measurement date",
            minWidth: 150,
            hide: false,
            align: "center",
        },
        {
            field: "relativeHumidity",
            headerName: "RF %",
            minWidth: 50,
            hide: false,
            align: "right",
        },
        {
            field: "sensorType",
            headerName: "Sensor type",
            minWidth: 125,
            hide: false,
        },
        {
            field: "projectName",
            headerName: "Site",
            minWidth: 50,
            hide: false,
        },
        {
            field: "floorName",
            headerName: "Floor",
            minWidth: 50,
            hide: false,
        },
        {
            field: "objectName",
            headerName: "Object name",
            minWidth: 125,
            hide: false,
        },
        {
            field: "frontName",
            headerName: "Adhesive side",
            minWidth: 125,
            hide: false,
        },
        {
            field: "backName",
            headerName: "Measurement side",
            minWidth: 150,
            hide: false,
        },
        {
            field: "technician",
            headerName: "Technician",
            minWidth: 125,
            hide: false,
        },
    ];
    const [dataColumns, setDataColumns] = useState(defaultColumns);
    const [partnerSelector, setPartnerSelector] = useState({
        selected: localStorage.getItem("partner") ?? "",
        partners: [],
    });
    const [customerSelector, setCustomerSelector] = useState({
        selected: localStorage.getItem("customer") ?? "",
        customers: [],
    });
    const { role } = useRoleContext();

    useEffect(() => {
        if (role !== roles.Unknown) {
            if (role !== roles.Admin && role !== roles.Partner) {
                statisticsService
                    .getInitSites()
                    .then((resp) => {
                        setSites([
                            ...defaultSites,
                            ...resp.data.sites.map((site) => ({
                                siteId: site.siteId,
                                name: site.name,
                                address: site.address,
                                postal: site.postal,
                                city: site.city,
                                country: site.country,
                            })),
                        ]);
                    })
                    .catch(() =>
                        setAlert({
                            type: "error",
                            message: "failed to get sites",
                        })
                    );
            } else if (role === roles.Admin) {
                projectService
                    .getPartners()
                    .then((resp) =>
                        setPartnerSelector((p) => ({
                            ...p,
                            partners: resp.data.partners,
                        }))
                    )
                    .catch(() =>
                        setAlert({
                            type: "error",
                            message: "failed to get partners",
                        })
                    );

                if (partnerSelector.selected !== "") {
                    projectService
                        .getCustomersAsAdmin(partnerSelector.selected)
                        .then((resp) => {
                            setCustomerSelector((c) => ({
                                ...c,
                                customers: resp.data.customers,
                            }));
                        })
                        .catch(() =>
                            setAlert({
                                type: "error",
                                message: "failed to get customers",
                            })
                        );
                }

                if (customerSelector.selected !== "") {
                    statisticsService
                        .getSites(
                            customerSelector.selected,
                            partnerSelector.selected,
                            role
                        )
                        .then((resp) => {
                            setSelectedSite(defaultSites[0]);
                            setSites([
                                ...defaultSites,
                                ...resp.data.sites.map((site) => ({
                                    siteId: site.siteId,
                                    name: site.name,
                                    address: site.address,
                                    postal: site.postal,
                                    city: site.city,
                                    country: site.country,
                                })),
                            ]);
                            setData([]);
                        })
                        .catch(() =>
                            setAlert({
                                type: "error",
                                message: "failed to get sites",
                            })
                        );
                }
            } else if (role === roles.Partner) {
                projectService
                    .getCustomersAsPartner()
                    .then((resp) =>
                        setCustomerSelector((c) => ({
                            ...c,
                            customers: resp.data.customers,
                        }))
                    )
                    .catch(() =>
                        setAlert({
                            type: "error",
                            message: "failed to get customers",
                        })
                    );

                if (customerSelector.selected !== "") {
                    statisticsService
                        .getSites(
                            customerSelector.selected,
                            partnerSelector.selected,
                            role
                        )
                        .then((resp) => {
                            setSelectedSite(defaultSites[0]);
                            setSites([
                                ...defaultSites,
                                ...resp.data.sites.map((site) => ({
                                    siteId: site.siteId,
                                    name: site.name,
                                    address: site.address,
                                    postal: site.postal,
                                    city: site.city,
                                    country: site.country,
                                })),
                            ]);
                            setData([]);
                        })
                        .catch(() =>
                            setAlert({
                                type: "error",
                                message: "failed to get sites",
                            })
                        );
                }
            }
            statisticsService
                .getStatsColumns()
                .then((resp) => {
                    setDataColumns((dc) =>
                        dc.map((val) => ({
                            ...val,
                            hide: !resp.data.selections[val.field],
                        }))
                    );
                })
                .catch(() =>
                    setAlert({
                        type: "error",
                        message: "Failed to get user column stats",
                    })
                );
        }
    }, [role, customerSelector.selected, partnerSelector.selected]);

    const handleSelectPartner = (partnerId) => {
        localStorage.setItem("partner", partnerId);
        setPartnerSelector({ ...partnerSelector, selected: partnerId });
        projectService
            .getCustomersAsAdmin(partnerId)
            .then((res) => {
                setCustomerSelector({
                    selected: "",
                    customers: res.data.customers,
                });
                setSelectedSite(defaultSites[0]);
                setSites([defaultSites[0]]);
                setData([]);
            })
            .catch(() =>
                setAlert({ type: "error", message: "failed to get customers" })
            );
    };

    const handleSelectCustomer = (customerId) => {
        localStorage.setItem("customer", customerId);
        setCustomerSelector({ ...customerSelector, selected: customerId });
        statisticsService
            .getSites(customerId, partnerSelector.selected, role)
            .then((res) => {
                setSelectedSite(defaultSites[0]);
                setSites([
                    ...defaultSites,
                    ...res.data.sites.map((site) => ({
                        siteId: site.siteId,
                        name: site.name,
                        address: site.address,
                        postal: site.postal,
                        city: site.city,
                        country: site.country,
                    })),
                ]);
                setData([]);
            })
            .catch(() =>
                setAlert({ type: "error", message: "failed to get sites" })
            );
    };

    const handleSiteChange = (site) => {
        setSelectedSite(site);
        setLoadingData(true);
        statisticsService
            .getStats(site.siteId, customerSelector.selected)
            .then((res) => {
                const projectSensorType = projectToSensorType(
                    localStorage.getItem("projectType")
                );

                const columnData = res.data.sensorData
                    .map((cData) => ({
                        ...cData,
                        id: cData.dataId,
                        installationDate: cData.installationDate
                            ? new Date(
                                  cData.installationDate
                              ).toLocaleDateString("sv-SE")
                            : "",
                        measurementDate: cData.datetime
                            ? new Date(cData.datetime).toLocaleDateString(
                                  "sv-SE"
                              )
                            : "",
                        serialNumber: cData.serialNumber
                            ? cData.serialNumber.split("-")[1]
                            : "",
                        sensorType: cData.sensorTypeName,
                        frontName: cData.frontMat,
                        backName: cData.backMat,
                        sensorColor: cData.color,
                    }))
                    .filter((cData) => cData.sensorType === projectSensorType);
                setData(columnData);
                if (columnData.length > 0) {
                    setDataColumns(
                        defaultColumns.map((w) => {
                            const longestData = columnData
                                .map((cData) => cData[w.field])
                                .reduce((a, b) =>
                                    String(a).length > String(b).length ? a : b
                                );

                            const minWidth =
                                w.headerName.length > String(longestData).length
                                    ? w.minWidth
                                    : String(longestData).length * 10;
                            return { ...w, minWidth };
                        })
                    );
                }
                setLoadingData(false);
            })
            .catch(() =>
                setAlert({
                    type: "error",
                    message: "failed to get data for selected site",
                })
            );
    };

    const handleSiteChangeEvent = (event) => {
        handleSiteChange(event.target.value);
    };

    const handleStatsColumnChange = (GridColumnVisibilityChangeParams) => {
        statisticsService.putStatsColumns({
            [GridColumnVisibilityChangeParams.field]:
                GridColumnVisibilityChangeParams.isVisible,
        });
    };

    return (
        <Grid container sx={styles.grid}>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <Grid spacing={3} container item alignItems="center">
                <Grid item>
                    <TableChartIcon sx={{ fontSize: 25 }} />
                </Grid>
                <Grid item>
                    <Typography fontSize={19} fontFamily="Arial">
                        Statistics
                    </Typography>
                </Grid>
                <Grid item>
                    <div className="icon-centering">
                        <ArrowForwardIosIcon />
                    </div>
                </Grid>
                <Grid item>
                    <ProjectButtons
                        selectedSite={selectedSite}
                        handleSiteChange={handleSiteChange}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ paddingTop: "20px" }}>
                {role === roles.Admin || role === roles.Partner ? (
                    <Grid item xs={12}>
                        <SelectorsForRoles
                            role={role}
                            cSelector={customerSelector}
                            handleCustomerSelect={handleSelectCustomer}
                            pSelector={partnerSelector}
                            handlePartnerSelect={handleSelectPartner}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={5} style={{ paddingTop: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel>Sites</InputLabel>
                        <Select
                            label="Sites"
                            onChange={handleSiteChangeEvent}
                            value={selectedSite}
                            inputProps={{
                                name: "siteSelector",
                                id: "siteSelector-picker",
                            }}
                        >
                            {sites.map((site, key) => (
                                <MenuItem value={site} key={site.siteId}>
                                    {key < 2
                                        ? site.name
                                        : `${site.name}, ${site.address}, ${site.postal}, ${site.city}, ${site.country}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item sx={{ ...styles.dataGridGrid }}>
                <DataGrid
                    rows={data}
                    columns={dataColumns}
                    density="compact"
                    loading={loadingData}
                    useResizeContainer
                    showColumnRightBorder
                    showCellRightBorder
                    disableSelectionOnClick
                    onColumnVisibilityChange={handleStatsColumnChange}
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={{ toolbar: { data, dataColumns } }}
                />
            </Grid>
        </Grid>
    );
}

export default Statistics;
