import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    getObjectTemplate,
    deleteObjectTemplate,
} from "../../Services/objectService";
import TopBar from "../ReusableComponents/TopBar";

export default function ManageTemplatesDialog({ setCallback }) {
    const [open, setOpen] = useState(true);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [toDelete, setToDelete] = useState();

    useEffect(() => {
        getObjectTemplate().then((res) => {
            setTemplates(res.data.templates);
            setLoading(false);
        });
    }, []);

    const handleClose = () => {
        setOpen(false);
        setCallback(false);
    };

    const handleDelete = () => {
        setOpenConfirm(false);
        // eslint-disable-next-line no-unused-vars
        deleteObjectTemplate(toDelete.id).then((_) => {
            const copy = [
                ...templates.filter((template) => template.id !== toDelete.id),
            ];
            setTemplates(copy);
        });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ padding: "0px" }}>
                    <TopBar
                        title="Manage object templates"
                        onClose={handleClose}
                        canGoBack={false}
                    />
                </DialogTitle>
                <DialogContent
                    style={{ paddingTop: "15px", width: 500, minHeight: 100 }}
                >
                    {loading ? (
                        <CircularProgress
                            size={50}
                            style={{ marginLeft: 200 }}
                        />
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ width: 450 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell style={{ width: 80 }}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templates.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                            >
                                                <Box
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setToDelete(row);
                                                        setOpenConfirm(true);
                                                    }}
                                                >
                                                    <DeleteIcon
                                                        sx={{ color: "red" }}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
            </Dialog>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                >
                    <DialogTitle>
                        {`Are you sure you want to delete ${
                            toDelete?.name ?? ""
                        }?`}
                    </DialogTitle>
                    <DialogActions>
                        <Button sx={{ color: "red" }} onClick={handleDelete}>
                            Delete
                        </Button>
                        <Button onClick={() => setOpenConfirm(false)} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
