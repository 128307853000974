import { Box, IconButton, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as Logo } from "../Images/InvisenseIcon.svg";
import * as loginService from "../Services/loginService";
import { getRole } from "../Services/projectService";
import roles from "../Services/roleService";
import { projectsWithStatisticsPage } from "../Services/statisticsService";
import { useRoleContext } from "../Context/RoleContext";

const styles = {
    sidebar: {
        backgroundColor: "#24242d",
        width: "50px",
        height: "100%",
    },
    logo: {
        height: "60px",
        marginTop: "40px",
        width: "auto",
        display: "block",
    },
    navItem: {
        padding: 0,
        marginBottom: "45px",
        color: "grey",
    },
    navItemActive: {
        padding: 0,
        marginBottom: "45px",
        color: "white",
    },
    highlighter: {
        display: "none",
    },
    highlighterActive: {
        display: "block",
        float: "left",
        width: "3px",
        height: "50px",
        backgroundColor: "#17A3F8",
    },
};

function SideNav() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { role, setRole } = useRoleContext();
    const sideBarButtons = [
        {
            path: "/dashboard",
            icon: <DashboardIcon sx={{ fontSize: 50 }} />,
            state: "active",
        },
        {
            path: "/Settings",
            icon: <SettingsIcon sx={{ fontSize: 50 }} />,
            state: "inactive",
        },
        {
            path: "/statistics",
            icon: <TableChartIcon sx={{ fontSize: 50 }} />,
            state: "inactive",
        },
    ];

    const [buttonState, setButtonState] = useState(sideBarButtons);

    const { data } = useQuery({
        queryKey: ["getRole"],
        queryFn: () => getRole(),
        enabled: role === roles.Unknown && pathname !== "/login",
    });

    useEffect(() => {
        if (data) {
            setRole(data.data.role);
        }
    }, [data, setRole]);

    useEffect(() => {
        const ignorePagesRegex = /^(?!.*(login|unauthorized))/;
        if (ignorePagesRegex.test(pathname) && role === roles.Measurer) {
            setButtonState([
                {
                    path: "/dashboard",
                    icon: <DashboardIcon sx={{ fontSize: 50 }} />,
                    state: "active",
                },
                {
                    path: "/Settings",
                    icon: <SettingsIcon sx={{ fontSize: 50 }} />,
                    state: "inactive",
                },
            ]);
        }
    }, [pathname, role]);

    useEffect(() => {
        if (pathname.split("/")[1])
            setButtonState((b) =>
                b.map((btn) => ({
                    path: btn.path,
                    icon: btn.icon,
                    state: btn.path.includes(pathname.split("/")[1])
                        ? "active"
                        : "inactive",
                }))
            );
    }, [pathname]);

    const toggleActive = (activeKey) => {
        setButtonState(
            buttonState.map((btn) => ({
                path: btn.path,
                icon: btn.icon,
                state: btn.path.includes(activeKey) ? "active" : "inactive",
            }))
        );

        if (activeKey === "/dashboard") {
            const projectType =
                localStorage.getItem("projectType") ?? "standard";
            navigate(`${activeKey}/${projectType}`);
        } else {
            navigate(activeKey);
        }
    };

    const signOut = () => {
        loginService.signOut().then(() => {
            navigate("/");
        });
    };

    const showStatistics = () =>
        projectsWithStatisticsPage.some((project) =>
            pathname.includes(`/${project}`)
        );

    return (
        !pathname.includes("/login") && (
            <Grid container sx={{ ...styles.sidebar }}>
                <Logo style={styles.logo} />
                {buttonState.map((item) =>
                    !showStatistics() &&
                    !pathname.includes("/statistics") &&
                    item.path.includes("/statistics") ? null : (
                        <Grid item sx={{ height: "60px" }} key={item.path}>
                            <IconButton
                                onClick={() => toggleActive(item.path)}
                                style={
                                    item.state === "active"
                                        ? styles.navItemActive
                                        : styles.navItem
                                }
                            >
                                <Box
                                    style={
                                        item.state === "active"
                                            ? styles.highlighterActive
                                            : styles.highlighter
                                    }
                                />
                                {item.icon}
                            </IconButton>
                        </Grid>
                    )
                )}
                <Grid item sx={{ height: "60px" }}>
                    <IconButton onClick={signOut} style={styles.navItem}>
                        <LogoutIcon sx={{ fontSize: 50 }} />
                    </IconButton>
                </Grid>
            </Grid>
        )
    );
}

export default SideNav;
