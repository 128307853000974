import * as settingsApi from "../Api/settingsApi";

export function setNewPassword(newPassword) {
    return settingsApi.setNewPassword(newPassword);
}

export function addPartner(partner) {
    return settingsApi.addPartner(partner);
}

export function deletePartner(partner) {
    return settingsApi.deletePartner(partner);
}

export function getPartners() {
    return settingsApi.getPartners();
}

export function getCustomersAsPartner() {
    return settingsApi.getCustomersAsPartner();
}

export function getCustomersAsAdmin(partnerId) {
    return settingsApi.getCustomersAsAdmin(partnerId);
}

export function updateUser(user) {
    return settingsApi.updateUser(user);
}

export function createSensorType(
    sensorTypeName,
    formula,
    a,
    b,
    c,
    checkedUsers
) {
    return settingsApi.createSensorType(
        sensorTypeName,
        formula,
        a,
        b,
        c,
        checkedUsers
    );
}

export function getProjectRights(id, role) {
    return settingsApi.getProjectRights(id, role);
}

export function updateRights(userId, role, rights) {
    return settingsApi.updateRights(userId, role, rights);
}

export function moveProject(customerId, siteId, newCustomerId) {
    return settingsApi.moveProject(customerId, siteId, newCustomerId);
}

export function addNewCustomer(username, password, ownerId) {
    return settingsApi.addNewCustomer(username, password, ownerId);
}

export function deleteCustomer(customerId) {
    return settingsApi.deleteCustomer(customerId);
}

// ------------ Measurer ------------

export function addMeasurer(username, password, email) {
    return settingsApi.addMeasurer(username, password, email);
}

export function deleteMeasurer(customerId) {
    return settingsApi.deleteMeasurer(customerId);
}

export function getMeasurers() {
    return settingsApi.getMeasurers();
}

export function assignMeasurer(measurerId, siteIds, customerId) {
    return settingsApi.assignMeasurer(measurerId, siteIds, customerId);
}

export function getMeasurerProjectsByCustomer(measurerId, customerId) {
    return settingsApi.getMeasurerProjectsByCustomer(measurerId, customerId);
}

export function updateCorrectedRHValues() {
    return settingsApi.updateCorrectedRHValues();
}

// ------------

export function getSensorTypes() {
    return settingsApi.getSensorTypes();
}

export function deleteSensorType(id) {
    return settingsApi.deleteSensorType(id);
}

export function getSensorTypeRights(typeId) {
    return settingsApi.getSensorTypeRights(typeId);
}

export function updateSensorType(typeId, name, formula, a, b, c) {
    return settingsApi.updateSensorType(typeId, name, formula, a, b, c);
}

export function updateSensorTypeRights(typeId, checkedUsers, grantAll) {
    return settingsApi.updateSensorTypeRights(typeId, checkedUsers, grantAll);
}

export function migrateRh(typeId, formula, a, b, c) {
    return settingsApi.migrateRh(typeId, formula, a, b, c);
}
