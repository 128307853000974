import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCustomer from "./AddCustomer";
import DeleteCustomer from "./DeleteCustomer";
import UpdateCustomer from "./UpdateCustomer";

function CustomerSettings({ role, onAlert }) {
    const [openAddCustomer, setOpenAddCustomer] = useState(false);
    const [openDeleteCustomer, setOpenDeleteCustomer] = useState(false);
    const [openChangeCustomer, setOpenChangeCustomer] = useState(false);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Customer</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1} alignItems="flex-start">
                    <Button onClick={() => setOpenAddCustomer(true)}>
                        Add customer
                    </Button>
                    <Button onClick={() => setOpenChangeCustomer(true)}>
                        Update customer
                    </Button>
                    <Button onClick={() => setOpenDeleteCustomer(true)}>
                        Delete customer
                    </Button>
                </Stack>
                <AddCustomer
                    open={openAddCustomer}
                    onCloseDialog={setOpenAddCustomer}
                    onAlert={onAlert}
                    role={role}
                />
                <UpdateCustomer
                    open={openChangeCustomer}
                    onAlert={onAlert}
                    onCloseDialog={setOpenChangeCustomer}
                    role={role}
                />
                <DeleteCustomer
                    open={openDeleteCustomer}
                    onCloseDialog={setOpenDeleteCustomer}
                    onAlert={onAlert}
                    role={role}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default CustomerSettings;
