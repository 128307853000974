import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import roles from "../../Services/roleService";

function SelectorsForRoles({
    role,
    pSelector,
    handlePartnerSelect,
    cSelector,
    handleCustomerSelect,
}) {
    const userRole = role;
    const partnerSelector = pSelector;
    const customerSelector = cSelector;

    if (
        userRole === roles.Admin ||
        userRole === roles.Partner ||
        userRole === roles.Measurer
    ) {
        return (
            <Grid container spacing={2}>
                {userRole === roles.Admin ? (
                    <Grid item>
                        <Box sx={{ width: 240 }}>
                            <FormControl fullWidth>
                                <InputLabel>Partners</InputLabel>
                                <Select
                                    value={
                                        pSelector.partners.length > 0
                                            ? partnerSelector.selected
                                            : ""
                                    }
                                    label="Partners"
                                    onChange={(event) =>
                                        handlePartnerSelect(event.target.value)
                                    }
                                >
                                    {partnerSelector.partners.map(
                                        ({ id, name }) => (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                ) : null}
                <Grid item>
                    <Box sx={{ width: 240 }}>
                        <FormControl fullWidth>
                            <InputLabel>Customers</InputLabel>
                            <Select
                                value={
                                    cSelector.customers.length > 0
                                        ? customerSelector.selected
                                        : ""
                                }
                                label="Customers"
                                onChange={(event) =>
                                    handleCustomerSelect(event.target.value)
                                }
                            >
                                {customerSelector.customers?.map(
                                    ({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        );
    }
    return null;
}

export default SelectorsForRoles;
