import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/sv";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

import * as sensorService from "../../Services/sensorService";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import { getScreedTypeName } from "../../Utils/sensorUtils";

dayjs.extend(localizedFormat);
dayjs.extend(utc);

function AddEditDataDialog({
    closeCallback,
    selectedSensor,
    isEditing,
    sensorType,
    measurement,
    addEditDataCallback,
}) {
    const [rh, setRh] = useState(measurement?.rh ?? 0);
    const [temp, setTemp] = useState(measurement?.temperature ?? 0);
    const [datetime, setDateTime] = useState(
        dayjs(measurement?.datetime ? measurement.datetime : new Date())
    );

    const [alert, setAlert] = useState({ type: "error", message: "" });

    const handleUpdateData = async () => {
        try {
            await sensorService.updateSensorData({
                id: measurement.id,
                rh,
                temp,
                datetime: new Date(datetime),
                sensorType,
            });

            const newData = [
                {
                    datetime: `${datetime
                        .local()
                        .format("YYYY-MM-DD")} ${datetime
                        .local()
                        .format("HH:mm")}`,
                    sensorId: selectedSensor.id,
                    id: measurement.id,
                    serialNumber: selectedSensor.serialNumber,
                    name: measurement.name,
                    rh,
                    temperature: temp,
                },
            ];

            if (sensorType === "screed") {
                const rhCorr = await sensorService.getCorrectedRHValue(
                    measurement.id
                );
                newData[0] = {
                    ...newData[0],
                    rhCorr: rhCorr.data.value[0].relativeHumidityCorrected,
                };
            }

            closeCallback(false);
            addEditDataCallback(newData);
        } catch (err) {
            setAlert({
                type: "error",
                message: err.response.data.error.message,
            });
        }
    };

    const handleAddData = async () => {
        try {
            const res = await sensorService.addSensorData({
                sensorId: selectedSensor.id,
                rh,
                temp,
                datetime,
                sensorType,
            });

            const newData = [
                {
                    id: res.data.dataId,
                    sensorId: selectedSensor.id,
                    serialNumber: selectedSensor.serialNumber,
                    datetime: `${datetime
                        .local()
                        .format("YYYY-MM-DD")} ${datetime
                        .local()
                        .format("HH:mm")}`,
                    name: selectedSensor.name,
                    rh,
                    temperature: temp,
                },
            ];

            if (sensorType === "screed") {
                const rhCorr = await sensorService.getCorrectedRHValue(
                    res.data.dataId
                );
                newData[0] = {
                    ...newData[0],
                    rhCorr: rhCorr.data.value[0].relativeHumidityCorrected,
                    screedDepth: selectedSensor.screedDepth,
                    screedBlockSize: selectedSensor.screedBlockSize,
                    screedTypeName: await getScreedTypeName(
                        selectedSensor.screedType
                    ),
                };
            }

            closeCallback(false);
            addEditDataCallback(newData);
        } catch (err) {
            setAlert({ type: "error", message: err.message });
        }
    };

    const handleCancel = () => {
        closeCallback(false);
    };

    const handleConfirmation = () => {
        if (isEditing) {
            handleUpdateData();
        } else {
            handleAddData();
        }
    };

    return (
        <Dialog open onClose={handleCancel}>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <DialogTitle>Add sensor data</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sx={{ marginTop: "10px" }}>
                        <TextField
                            fullWidth
                            disabled
                            label="Selected sensor name"
                            variant="outlined"
                            value={selectedSensor?.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Rh"
                            type="number"
                            variant="outlined"
                            value={rh}
                            onChange={(event) =>
                                setRh(parseFloat(event.target.value))
                            }
                        />
                    </Grid>
                    {sensorType === "screed" ? (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Temp"
                                type="number"
                                variant="outlined"
                                value={temp}
                                onChange={(event) =>
                                    setTemp(event.target.value)
                                }
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="sv"
                        >
                            <DateTimePicker
                                label="Date and time"
                                value={datetime}
                                onChange={setDateTime}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    sx={{ color: "green" }}
                    onClick={handleConfirmation}
                    disabled={rh.length === 0}
                >
                    {isEditing ? "Save" : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddEditDataDialog;
