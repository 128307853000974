import React, { memo, useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Chip } from "@mui/material";

function getStyles(positionX, positionY, isDragging, scale) {
    const transform = `translate3d(${positionX}px, ${positionY}px, 0) scale(${scale})`;
    return {
        position: "absolute",
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : "",
    };
}

const DraggableChip = memo(
    ({ index, id, serialNumber, sensorColor, positionX, positionY, scale }) => {
        const [{ isDragging }, drag, preview] = useDrag(
            () => ({
                type: "Chip",
                item: {
                    index,
                    id,
                    serialNumber,
                    sensorColor,
                    positionX,
                    positionY,
                    scale,
                },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(),
                }),
            }),
            [index, id, serialNumber, sensorColor, positionX, positionY, scale]
        );

        useEffect(() => {
            preview(getEmptyImage(), { captureDraggingState: true });
        }, [preview]);

        return (
            <div
                ref={drag}
                style={getStyles(positionX, positionY, isDragging, scale)}
                className="panningDisabled"
            >
                <Chip
                    key={`drag-${index}`}
                    label={serialNumber}
                    style={{
                        backgroundColor: sensorColor,
                        transform: `scale(${scale})`,
                    }}
                    className="panningDisabled"
                />
            </div>
        );
    }
);

export default DraggableChip;
