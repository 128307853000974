import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import React from "react";

function CustomToolbar({ data, dataColumns }) {
    const exportToExcel = () => {
        /* Create an array of objects with the data for each row */
        const rows = data.map((row) => {
            const rowData = {};
            dataColumns // todo could this use a reduce instead? would that improve readability?
                .filter((column) => !column.hide)
                .forEach((column) => {
                    rowData[column.headerName] =
                        row[column.field] ?? row[column.backupField];
                });
            return rowData;
        });

        /* Create a workbook and add a worksheet */
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(wb, ws, "Sensor Data");

        /* Generate the Excel file and trigger a download */
        XLSX.writeFile(wb, "sensor-data.xlsx");
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Button
                style={{ fontSize: "0.875rem" }}
                color="primary"
                startIcon={<FileDownloadIcon />}
                disabled={data.length === 0}
                onClick={exportToExcel}
            >
                EXPORT
            </Button>
        </GridToolbarContainer>
    );
}

export default CustomToolbar;
