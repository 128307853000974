import React, { useEffect, useState } from "react";
import "./ProjectButtons.css";
import { NavLink, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { sensorTypesWithRights } from "../../Services/sensorService";
import {
    existsSensorsBySensorType,
    projectTypesByObject,
} from "../../Services/projectService";
import { useRoleContext } from "../../Context/RoleContext";
import roles from "../../Services/roleService";

const styles = {
    buttonText: {
        margin: "10px 20px 0 20px",
        fontSize: "18px",
    },
};

function ProjectButtons({ basePath, objectId }) {
    const path = useLocation().pathname;
    const [buttonState, setButtonState] = useState([]);
    const { role } = useRoleContext();

    useEffect(() => {
        if (role !== roles.Unknown) {
            const objectHasSensor = async (sensorType) => {
                const response = await existsSensorsBySensorType(sensorType);
                return response?.data?.exists
                    ? "link-item selected"
                    : "link-item";
            };

            const fetchButtonState = async () => {
                const typeName = path.split("/").at(-1).toLowerCase();

                let projectTypes = [];
                if (path.includes("object")) {
                    const res = await projectTypesByObject(objectId, role);
                    projectTypes = res?.data?.projectTypes || [];
                } else {
                    const res = await sensorTypesWithRights(role);
                    projectTypes = res?.data?.sensorTypes || [];
                }
                const newState = await Promise.all(
                    projectTypes.map(async (sensorType) => {
                        const lowerCaseName = sensorType.name.toLowerCase();
                        const state =
                            typeName === lowerCaseName ? "active" : "inactive";
                        const className = await objectHasSensor(lowerCaseName);
                        return {
                            path: `/${lowerCaseName}`,
                            text: sensorType.name,
                            state,
                            key: lowerCaseName,
                            className,
                        };
                    })
                );
                setButtonState(newState);
            };
            fetchButtonState();
        }
    }, [objectId, path, role]);

    function toggleActive(activeKey) {
        localStorage.setItem("projectType", activeKey);
        setButtonState(
            buttonState.map((btn) => ({
                ...btn,
                state: btn.key === activeKey ? "active" : "inactive",
            }))
        );
    }

    return (
        <Box className="button-holder">
            {buttonState.map((item) => (
                <NavLink
                    to={`/${basePath}${item.path}`}
                    className={item.className}
                    key={item.key}
                    onClick={() => toggleActive(item.key)}
                >
                    <Box sx={{ ...styles.buttonText }}>
                        {item.text === "activescreed"
                            ? "Active screed"
                            : item.text}
                        <p className={`project-selected ${item.state}`} />
                    </Box>
                </NavLink>
            ))}
        </Box>
    );
}

export default ProjectButtons;
