import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SelectorsForRoles from "../ReusableComponents/SelectorsForRoles";
import * as projectService from "../../Services/projectService";
import * as statisticsService from "../../Services/statisticsService";
import * as settingsService from "../../Services/settingsService";
import roles from "../../Services/roleService";

const styles = {
    dataGridGrid: {
        marginLeft: "2%",
        marginRight: "2%",
        height: "70vh",
    },
    dialogPaper: {
        minHeight: "70%",
        maxHeight: "70%",
        maxWidth: "80%",
        minWidth: "80%",
    },
    grid: {
        width: "100%",
        height: "100%",
    },
};

function SettingsEditRightsDialog({ open, closeCB, alertCB, role }) {
    const updatePartner = { id: "Partner", name: "Update partner" };
    const defaultColumns = [
        {
            field: "id",
            headerName: "ID",
            minWidth: 50,
            hide: true,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 100,
            hide: false,
        },
        {
            field: "address",
            headerName: "Address",
            minWidth: 125,
            hide: false,
        },
        {
            field: "postal",
            headerName: "Postal",
            minWidth: 140,
            hide: false,
        },
        {
            field: "city",
            headerName: "City",
            minWidth: 125,
            hide: false,
        },
        {
            field: "country",
            headerName: "Country",
            minWidth: 150,
            hide: false,
        },
    ];
    const [dataColumns, setDataColumns] = useState(defaultColumns);
    const [loadingData, setLoadingData] = useState(false);
    const [allSites, setAllSites] = useState([]);
    const [customerSites, setCustomerSites] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [partnerRights, setPartnerRights] = useState([]);
    const [partnerSelector, setPartnerSelector] = useState({
        selected: "",
        partners: [],
    });
    const [customerSelector, setCustomerSelector] = useState({
        selected: "",
        customers: [],
    });

    useEffect(() => {
        if (open) {
            statisticsService
                .getInitSites()
                .then((res) => {
                    setAllSites([
                        ...res.data.sites.map((site) => ({
                            id: site.id,
                            name: site.name,
                            address: site.address,
                            postal: site.postal,
                            city: site.city,
                            country: site.country,
                        })),
                    ]);
                    if (res.data.sites.length > 0) {
                        setDataColumns((d) =>
                            d.map((w) => {
                                const longestData = res.data.sites
                                    .map((data) => data[w.field])
                                    .reduce((a, b) =>
                                        String(a).length > String(b).length
                                            ? a
                                            : b
                                    );
                                const minWidth =
                                    w.headerName.length >
                                    String(longestData).length
                                        ? w.minWidth
                                        : String(longestData).length * 10;
                                return { ...w, minWidth };
                            })
                        );
                    }
                })
                .catch(() =>
                    alertCB({ type: "error", message: "failed to get sites" })
                );
            if (role === roles.Admin) {
                projectService
                    .getPartners()
                    .then((res) =>
                        setPartnerSelector((p) => ({
                            ...p,
                            partners: res.data.partners,
                        }))
                    )
                    .catch(() =>
                        alertCB({
                            type: "error",
                            message: "failed to get partners",
                        })
                    );
            } else {
                projectService
                    .getCustomersAsPartner()
                    .then((res) => {
                        setCustomerSelector({
                            selected: "",
                            customers: [...res.data.customers],
                        });
                    })
                    .catch(() =>
                        alertCB({
                            type: "error",
                            message: "failed to get customers",
                        })
                    );
            }
        }
    }, [alertCB, role, open]);

    const handleSelect = (event) => {
        setSelectionModel(event);
    };

    const handleSelectPartner = (partnerId) => {
        setPartnerSelector({ ...partnerSelector, selected: partnerId });
        projectService
            .getCustomersAsAdmin(partnerId)
            .then((res) => {
                setCustomerSelector({
                    selected: updatePartner.id,
                    customers: [updatePartner, ...res.data.customers],
                });
                setCustomerSites([]);
                settingsService
                    .getProjectRights(partnerId, roles.Partner)
                    .then((resp) => {
                        handleSelect(
                            resp.data.rights.map((rights) => rights.siteId)
                        );
                        setPartnerRights(
                            resp.data.rights.map((rights) => rights.siteId)
                        );
                    })
                    .catch(() =>
                        alertCB({
                            type: "error",
                            message: "failed to get rights",
                        })
                    );
            })
            .catch(() =>
                alertCB({ type: "error", message: "failed to get customers" })
            );
    };

    const handleSelectCustomer = (customerId) => {
        setCustomerSelector({ ...customerSelector, selected: customerId });
        setLoadingData(true);
        if (customerId === updatePartner.id) {
            settingsService
                .getProjectRights(partnerSelector.selected, roles.Partner)
                .then((res) => {
                    handleSelect(
                        res.data.rights.map((rights) => rights.siteId)
                    );
                    setLoadingData(false);
                })
                .catch(() =>
                    alertCB({ type: "error", message: "failed to get rights" })
                );
            setCustomerSites([]);
        } else {
            setCustomerSites([
                ...allSites.filter((site) => partnerRights.includes(site.id)),
            ]);
            settingsService
                .getProjectRights(customerId, roles.Customer)
                .then((res) => {
                    handleSelect(
                        res.data.rights.map((rights) => rights.siteId)
                    );
                    setLoadingData(false);
                })
                .catch(() =>
                    alertCB({ type: "error", message: "failed to get rights" })
                );
        }
    };

    const handleSave = () => {
        if (customerSelector.selected === updatePartner.id) {
            settingsService
                .updateRights(
                    partnerSelector.selected,
                    roles.Partner,
                    selectionModel.map((siteId) => ({
                        userId: partnerSelector.selected,
                        siteId,
                    }))
                )
                .then(() => setPartnerRights(selectionModel))
                .catch(() =>
                    alertCB({
                        type: "error",
                        message: "failed to update rights",
                    })
                );
        } else {
            settingsService
                .updateRights(
                    customerSelector.selected,
                    roles.Customer,
                    selectionModel.map((siteId) => ({
                        userId: customerSelector.selected,
                        siteId,
                    }))
                )
                .catch(() =>
                    alertCB({
                        type: "error",
                        message: "failed to update rights",
                    })
                );
        }
    };

    return (
        <Dialog
            disableEnforceFocus
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            onClose={closeCB}
            open={open}
        >
            <DialogContent>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={styles.grid}
                >
                    <Grid
                        item
                        xs={12}
                        style={{ marginLeft: styles.dataGridGrid.marginLeft }}
                    >
                        <SelectorsForRoles
                            role={role}
                            cSelector={customerSelector}
                            handleCustomerSelect={handleSelectCustomer}
                            pSelector={partnerSelector}
                            handlePartnerSelect={handleSelectPartner}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ ...styles.dataGridGrid }}>
                        <DataGrid
                            rows={
                                customerSites.length > 0
                                    ? customerSites
                                    : allSites
                            }
                            columns={dataColumns}
                            checkboxSelection
                            density="compact"
                            loading={loadingData}
                            disableSelectionOnClick
                            useResizeContainer
                            showColumnRightBorder
                            showCellRightBorder
                            selectionModel={selectionModel}
                            onSelectionModelChange={handleSelect}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={closeCB}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SettingsEditRightsDialog;
