import {
    Autocomplete,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import roles from "../../../Services/roleService";
import {
    assignMeasurer,
    getMeasurerProjectsByCustomer,
    getCustomersAsAdmin,
    getCustomersAsPartner,
    getMeasurers,
    getPartners,
} from "../../../Services/settingsService";
import { getSitesByCustomer } from "../../../Services/statisticsService";
import { ProjectsTable } from "./ProjectTable";

const filter = createFilterOptions();

function AssignMeasurer({ role, open, onCloseDialog, onAlert }) {
    const header = "Assign Measurer";
    const [partner, setPartner] = useState("");
    const [measurer, setMeasurer] = useState("");
    const [customer, setCustomer] = useState("");
    const [partnerOptions, setPartnerOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [measurerOptions, setMeasurerOptions] = useState([]);
    const [siteOptions, setSiteOptions] = useState([]);
    const [rowSelection, setRowSelection] = useState([]);
    const [presetAssingedProjects, setpresetAssingedProjects] = useState({
        data: [],
        fetched: false,
    });

    // Reset Defaults
    useEffect(() => {
        setPartner("");
        setMeasurer("");
        setCustomer("");
        setSiteOptions([]);
        setpresetAssingedProjects({ data: [], fetched: false });
    }, [open]);

    // set Partner list
    useEffect(() => {
        if (role === roles.Admin) {
            getPartners().then((res) => {
                setPartnerOptions(res.data.partners);
            });
        }
    }, [role, open]);

    useEffect(() => {
        const handleFetchCustomersAsAdmin = async () => {
            try {
                const res = await getCustomersAsAdmin(partner.id);
                setCustomerOptions(res.data.customers);
            } catch (error) {
                onAlert({
                    type: "error",
                    message: "failed to get customers",
                });
            }
        };

        const handleFetchCustomersAsPartner = async () => {
            try {
                const res = await getCustomersAsPartner();
                setCustomerOptions(res.data.customers);
            } catch (error) {
                onAlert({
                    type: "error",
                    message: "failed to get customers",
                });
            }
        };
        if (partner !== "" && role === roles.Admin) {
            handleFetchCustomersAsAdmin();
        } else {
            handleFetchCustomersAsPartner();
        }
    }, [partner, onAlert, role, open]);

    // set measurer list
    useEffect(() => {
        const fetchData = async () => {
            if (role === roles.Admin || role === roles.Partner) {
                try {
                    const res = await getMeasurers();
                    setMeasurerOptions(res.data.measurers);
                } catch (error) {
                    onAlert({
                        type: "error",
                        message: "failed to get measurers",
                    });
                }
            }
        };
        fetchData();
    }, [onAlert, role]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getMeasurerProjectsByCustomer(
                measurer.id,
                customer
            );

            setpresetAssingedProjects({
                data: res.data.projects,
                fetched: true,
            });
        };

        if (measurer?.id && customer) {
            fetchData();
        }
    }, [measurer, customer]);

    const [defaultRowSelection, setDefaultRowSelection] = useState([]);

    useEffect(() => {
        const createDefaultSelection = (fullList, siteList) => {
            const selection = {};
            fullList.forEach((site, index) => {
                if (siteList.includes(site.siteId)) {
                    selection[index] = true;
                }
            });

            return selection;
        };

        setDefaultRowSelection(
            createDefaultSelection(siteOptions, presetAssingedProjects.data)
        );
    }, [siteOptions, presetAssingedProjects.data]);

    const handleCustomerFromSelect = async (customerId) => {
        setCustomer(customerId);
        if (customerId === -1) {
            setSiteOptions([]);
            return;
        }

        try {
            const siteResponse = await getSitesByCustomer(customerId, role);
            const newSiteOptions = siteResponse.data.sites.map((site) => ({
                siteId: site.siteId,
                name: site.name,
                address: site.address,
                postal: site.postal,
                city: site.city,
                country: site.country,
            }));

            setSiteOptions(newSiteOptions);
        } catch (error) {
            onAlert(error.response.data.error);
        }
    };

    const handleSave = async () => {
        const sites = Object.keys(rowSelection).map(
            (row) => siteOptions[row].siteId
        );

        const res = await assignMeasurer(measurer.id, sites, customer);
        if (res.data.status) onCloseDialog(false);
    };

    return (
        <Dialog maxWidth="lg" open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <Stack spacing={1} sx={{ paddingTop: "10px" }}>
                    <Autocomplete
                        label="Select measurer"
                        options={measurerOptions}
                        renderInput={(params) => (
                            <TextField {...params} label="Measurer" />
                        )}
                        onChange={(event, value) => {
                            setMeasurer(value ?? -1);
                        }}
                        getOptionLabel={(option) =>
                            typeof option === "string"
                                ? option
                                : option.name ?? ""
                        }
                        filterOptions={(options, params) =>
                            filter(options, params)
                        }
                    />
                    {role === roles.Admin ? (
                        <Autocomplete
                            label="Select partner"
                            options={partnerOptions}
                            renderInput={(params) => (
                                <TextField {...params} label="Partner" />
                            )}
                            onChange={(event, value) => {
                                setPartner(value ?? -1);
                            }}
                            getOptionLabel={(option) =>
                                typeof option === "string"
                                    ? option
                                    : option.name ?? ""
                            }
                            filterOptions={(options, params) =>
                                filter(options, params)
                            }
                        />
                    ) : null}
                    <Autocomplete
                        label="Select customer"
                        options={customerOptions}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" />
                        )}
                        onChange={(event, value) => {
                            handleCustomerFromSelect(value?.id ?? -1);
                        }}
                        getOptionLabel={(option) =>
                            typeof option === "string"
                                ? option
                                : option.name ?? ""
                        }
                        filterOptions={(options, params) =>
                            filter(options, params)
                        }
                    />

                    {open && (
                        <ProjectsTable
                            data={siteOptions}
                            setRowSelectionParent={setRowSelection}
                            defaultSelected={defaultRowSelection}
                        />
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!measurer || !customer}
                    onClick={() => handleSave()}
                >
                    Save
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AssignMeasurer;
