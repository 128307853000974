import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddMeasurer from "./AddMeasurer";
import DeleteMeasurer from "./DeleteMeasurer";
import UpdateMeasurer from "./UpdateMeasurer";
import AssignMeasurer from "./AssignMeasurer";

function MeasurerSettings({ role, onAlert }) {
    const [openAddMeasurer, setOpenAddMeasurer] = useState(false);
    const [openDeleteMeasurer, setOpenDeleteMeasurer] = useState(false);
    const [openChangeMeasurer, setOpenChangeMeasurer] = useState(false);
    const [openAssignMeasurer, setOpenAssignMeasurer] = useState(false);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Measurer</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1} alignItems="flex-start">
                    <Button onClick={() => setOpenAddMeasurer(true)}>
                        Add Measurer
                    </Button>
                    <Button onClick={() => setOpenAssignMeasurer(true)}>
                        Assign Measurer
                    </Button>
                    <Button onClick={() => setOpenChangeMeasurer(true)}>
                        Update Measurer
                    </Button>
                    <Button onClick={() => setOpenDeleteMeasurer(true)}>
                        Delete Measurer
                    </Button>
                </Stack>
                {openAddMeasurer && (
                    <AddMeasurer
                        open={openAddMeasurer}
                        onCloseDialog={setOpenAddMeasurer}
                        onAlert={onAlert}
                        role={role}
                    />
                )}
                {openAssignMeasurer && (
                    <AssignMeasurer
                        open={openAssignMeasurer}
                        onAlert={onAlert}
                        onCloseDialog={setOpenAssignMeasurer}
                        role={role}
                    />
                )}
                {openChangeMeasurer && (
                    <UpdateMeasurer
                        open={openChangeMeasurer}
                        onAlert={onAlert}
                        onCloseDialog={setOpenChangeMeasurer}
                        role={role}
                    />
                )}
                {openDeleteMeasurer && (
                    <DeleteMeasurer
                        open={openDeleteMeasurer}
                        onCloseDialog={setOpenDeleteMeasurer}
                        onAlert={onAlert}
                        role={role}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default MeasurerSettings;
