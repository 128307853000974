import {
    useCallback,
    useEffect,
    useRef,
    useState,
    useLayoutEffect,
} from "react";
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Box, Button, Grid } from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import SystemAlertSnackbar from "../ReusableComponents/SystemAlertSnackbar";
import DraggableChip from "./DragChip";

const styles = {
    styles: {
        border: "1px solid black",
        position: "relative",
        overflow: "hidden",
        height: "100%",
    },
    stylesButtons: {
        color: "#09669d",
    },
    transformComponentStyle: {
        maxHeight: "100px",
    },
    transformWrapperStyle: {
        height: "100%",
        position: "absolute",
    },
    tools: {
        height: "fit-content",
        marginTop: "10px",
        marginLeft: "10px",
        zIndex: 1,
    },
};

export default function PanContainer({
    sensors,
    selectedSensors,
    blueprint,
    blueprintName,
    updateSensorPosition,
}: {
    blueprint: { type: string; contentbody: string };
    blueprintName: string;
}) {
    const [boxes, setBoxes] = useState({});
    const [alert, setAlert] = useState({ type: "error", message: "" });
    const [cursor, setCursor] = useState("grab");
    const componentRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    const [positionScale, setPositionScale] = useState(0);
    const zoomStep = 0.25;
    const transformWrapperRef = useRef(null);

    const greyColor = "#adb3af";

    function updateLocalStorage(
        unit: "Scale" | "PositionX" | "PositionY",
        value: number
    ) {
        localStorage.setItem(blueprintName + unit, value.toString());
    }
    function getFromLocalStorage(unit: "Scale" | "PositionX" | "PositionY") {
        return localStorage.getItem(blueprintName + unit);
    }

    // Retrieve the initialScale and initialPosition from localStorage
    const storedScale = getFromLocalStorage("Scale");
    const storedPositionX = getFromLocalStorage("PositionX");
    const storedPositionY = getFromLocalStorage("PositionY");

    // State variables to track the scale and position
    const scale = storedScale ? parseFloat(storedScale) : 0.5;
    const positionX = storedPositionX ? parseInt(storedPositionX, 10) : 100;
    const positionY = storedPositionY ? parseInt(storedPositionY, 10) : 35;

    useLayoutEffect(() => {
        const componentElement = componentRef.current;
        const handleResize = () => {
            if (componentElement) {
                const { width } = componentElement.getBoundingClientRect();
                setContainerWidth(width);
            }
        };

        handleResize(); // Initial size calculation

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (positionScale > 0) {
            setBoxes(
                sensors.map((sensor, idx) => ({
                    index: idx,
                    id: sensor.id,
                    serialNumber: sensor.serialNumber,
                    sensorColor:
                        selectedSensors.find((ss) => ss.id === sensor.id)
                            ?.color ?? greyColor,
                    positionY: sensor.positionY * positionScale,
                    positionX: sensor.positionX * positionScale,
                }))
            );
        }
    }, [sensors, selectedSensors, positionScale]);

    useEffect(() => {
        if (imgWidth > 0 && containerWidth > 0) {
            const wScale = containerWidth / imgWidth;
            setPositionScale(wScale > 1 ? 1 : wScale);
        }
    }, [containerWidth, imgWidth]);

    const moveBox = useCallback(
        (index, newPositionX, newPositionY) => {
            setBoxes(
                update(boxes, {
                    [index]: {
                        $merge: { newPositionX, newPositionY },
                    },
                })
            );
        },
        [boxes]
    );

    const [, drop] = useDrop(
        () => ({
            accept: "Chip",
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const twScale = transformWrapperRef.current.state.scale;

                const newPositionX =
                    (item.positionX + delta.x / twScale) / positionScale;
                const newPositionY =
                    (item.positionY + delta.y / twScale) / positionScale;

                moveBox(item.index, newPositionX, newPositionY);
                updateSensorPosition(item, newPositionX, newPositionY);
                return undefined;
            },
        }),
        [moveBox]
    );

    const blueprintImage = new Image();

    if (blueprint?.type && blueprint?.contentbody) {
        blueprintImage.src = `data:${blueprint.type};base64,${blueprint.contentbody}`;
    }

    blueprintImage.onload = () => {
        setImgWidth(blueprintImage.naturalWidth);
    };

    return (
        <Grid
            container
            sx={{ ...styles.styles, cursor }}
            ref={drop}
            onMouseDown={() => setCursor("grabbing")}
            onMouseUp={() => setCursor("grab")}
        >
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <div style={{ width: "100%", height: "100%" }} ref={componentRef}>
                {blueprint?.type !== undefined ? (
                    <TransformWrapper
                        limitToBounds={false}
                        initialScale={scale}
                        initialPositionX={positionX}
                        initialPositionY={positionY}
                        minScale={0.1}
                        maxScale={10}
                        onTransformed={(ref) =>
                            updateLocalStorage("Scale", ref.state.scale)
                        }
                        onPanningStop={(ref) => {
                            updateLocalStorage(
                                "PositionX",
                                ref.state.positionX
                            );
                            updateLocalStorage(
                                "PositionY",
                                ref.state.positionY
                            );
                        }}
                        panning={{
                            excluded: ["panningDisabled", "MuiChip-label"],
                        }}
                        pinch={{
                            excluded: ["panningDisabled", "MuiChip-label"],
                        }}
                        doubleClick={{ disabled: true }}
                        wheel={{
                            disabled: true,
                            step: zoomStep,
                        }}
                        onInit={(ref) => {
                            transformWrapperRef.current = ref;
                        }}
                    >
                        {({ zoomIn, zoomOut, setTransform }) => (
                            <>
                                <Box sx={styles.tools}>
                                    <Button
                                        onClick={() => {
                                            zoomIn(zoomStep);
                                        }}
                                    >
                                        +
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            zoomOut(zoomStep);
                                        }}
                                    >
                                        -
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            updateLocalStorage("Scale", 0.5);
                                            updateLocalStorage("PositionX", 0);
                                            updateLocalStorage("PositionY", 0);
                                            setTransform(0, 0, 0.5);
                                        }}
                                    >
                                        x
                                    </Button>
                                </Box>

                                <TransformComponent
                                    contentStyle={
                                        styles.transformComponentStyle
                                    }
                                    wrapperStyle={{
                                        ...styles.transformWrapperStyle,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <img
                                        src={blueprintImage.src}
                                        alt="Blueprint"
                                    />
                                    {Object.keys(boxes).map((key) => (
                                        <DraggableChip
                                            key={key}
                                            scale={1}
                                            {...boxes[key]}
                                        />
                                    ))}
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                ) : (
                    <ImageNotSupportedIcon />
                )}
            </div>
        </Grid>
    );
}
