import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import * as projectService from "../../../Services/projectService";
import * as settingsService from "../../../Services/settingsService";
import CheckBoxForRole from "../../ReusableComponents/CheckBoxForRole";

const styles = {
    equation: {
        marginLeft: "15%",
    },
    dialogPaper: {
        minHeight: "50%",
        maxHeight: "50%",
        maxWidth: "30%",
        minWidth: "30%",
    },
};

function CreateSensorType({ open, closeCB, alertCB, role }) {
    const [sensorTypeName, setSensorTypeName] = useState("");
    const [translationFunction, setTranslationFunction] = useState("");
    const [a, setA] = useState("");
    const [b, setB] = useState("");
    const [c, setC] = useState("");
    const [partners, setPartners] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);

    useEffect(() => {
        if (open) {
            setTranslationFunction("quadratic equation");
            projectService
                .getPartners()
                .then((res) => setPartners(res.data.partners))
                .catch(() =>
                    alertCB({
                        type: "error",
                        message: `failed to get partners`,
                    })
                );
            projectService
                .getCustomers()
                .then((res) => setCustomers(res.data.customers))
                .catch(() =>
                    alertCB({
                        type: "error",
                        message: `failed to get customers`,
                    })
                );
        }
    }, [alertCB, role, open]);

    const handleSave = () => {
        settingsService
            .createSensorType(sensorTypeName, translationFunction, a, b, c, [
                ...selectedPartners,
                ...selectedCustomers,
            ])
            .then(() =>
                alertCB({ type: "success", message: "Sensortype created" })
            )
            .catch(() =>
                alertCB({
                    type: "error",
                    message: "Sensortype could not be created",
                })
            );
    };

    return (
        <Dialog
            disableEnforceFocus
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            onClose={closeCB}
            open={open}
        >
            <DialogContent>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            label="Sensortype name"
                            fullWidth
                            required
                            variant="outlined"
                            margin="dense"
                            value={sensorTypeName}
                            onChange={(e) => setSensorTypeName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Translation function"
                            fullWidth
                            required
                            variant="outlined"
                            margin="dense"
                            value={translationFunction}
                            disabled
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">RH% = af2+bf+c</Typography>
                        </Grid>{" "}
                        <Grid item xs={12}>
                            <Typography variant="h9">
                                Set a = 0 for a linear function
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={styles.equation}>
                            <TextField
                                label="a"
                                required
                                variant="outlined"
                                margin="dense"
                                type="number"
                                value={a}
                                onChange={(e) => setA(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} style={styles.equation}>
                            <TextField
                                label="b"
                                required
                                variant="outlined"
                                margin="dense"
                                type="number"
                                value={b}
                                onChange={(e) => setB(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} style={styles.equation}>
                            <TextField
                                label="c"
                                required
                                variant="outlined"
                                margin="dense"
                                type="number"
                                value={c}
                                onChange={(e) => setC(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Add Rights</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CheckBoxForRole
                                partners={partners}
                                customers={customers}
                                partnersCB={setSelectedPartners}
                                customersCB={setSelectedCustomers}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={
                        !(
                            sensorTypeName.length > 0 &&
                            translationFunction.length > 0 &&
                            a.length > 0 &&
                            b.length > 0 &&
                            c.length > 0
                        )
                    }
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button onClick={closeCB}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateSensorType;
