import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddPartner from "./AddPartner";
import UpdatePartner from "./UpdatePartner";
import DeletePartner from "./DeletePartner";

function PartnerSettings({ onAlert }) {
    const [openAddPartner, setOpenAddPartner] = useState(false);
    const [openUpdatePartner, setOpenUpdatePartner] = useState(false);
    const [openDeletePartner, setOpenDeletePartner] = useState(false);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Partner</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1} alignItems="flex-start">
                    <Button onClick={() => setOpenAddPartner(true)}>
                        Add partner
                    </Button>
                    <Button onClick={() => setOpenUpdatePartner(true)}>
                        Update partner
                    </Button>
                    <Button onClick={() => setOpenDeletePartner(true)}>
                        Delete partner
                    </Button>
                </Stack>
                <AddPartner
                    open={openAddPartner}
                    onCloseDialog={setOpenAddPartner}
                    onAlert={onAlert}
                />
                <UpdatePartner
                    open={openUpdatePartner}
                    onCloseDialog={setOpenUpdatePartner}
                    onAlert={onAlert}
                />
                <DeletePartner
                    open={openDeletePartner}
                    onCloseDialog={setOpenDeletePartner}
                    onAlert={onAlert}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default PartnerSettings;
