import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    TextField,
    Dialog,
    Checkbox,
    DialogContent,
    Button,
    DialogTitle,
    Autocomplete,
    Grid,
    FormControlLabel,
    FormGroup,
    Typography,
    Stack,
} from "@mui/material";
import FloorCards from "./ReusableComponents/FloorCards";
import TopBar from "./ReusableComponents/TopBar";
import * as projectService from "../Services/projectService";
import SystemAlertSnackbar from "./ReusableComponents/SystemAlertSnackbar";
import { sensorTypesWithRights } from "../Services/sensorService";
import { projectTypes } from "../Services/projectService";
import QuestionMarkToolTip from "./ReusableComponents/QuestionMarkToolTip";
import { PROJECT_ITEMS_TEXT } from "../Constants/Constants";
import { useRoleContext } from "../Context/RoleContext";

const styles = {
    dialogPaper: {
        minHeight: "80%",
        maxHeight: "80%",
        maxWidth: "90%",
        minWidth: "90%",
    },
    greenButton: {
        padding: "5px 10px",
        backgroundColor: "green",
        color: "white",
        borderRadius: 1,
        fontFamily: "arial",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "green",
            color: "white",
        },
    },
    gridMargin: {
        marginTop: "15px",
    },
};

function CreateEditProject() {
    const navigate = useNavigate();
    const { projectId, customerId, isNew } = JSON.parse(
        localStorage.getItem("CreateEditProject")
    );

    const [open, setOpen] = useState(true);
    const [selectedSensorTypes, setSelectedSensorTypes] = useState([]);
    const [sensorTypes, setSensorTypes] = useState([]);
    // TODO: Convert multilayered to boolean. Requires database migration. Left unchanged due to time constraints.
    const [project, setProject] = useState({
        name: "",
        address: "",
        postal: "",
        city: "",
        country: "",
        modalName: "",
        multilayered: 0,
    });
    const [alert, setAlert] = useState({ type: "error", message: "" });
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [isSaved, setIsSaved] = useState(!isNew);
    const { role } = useRoleContext();

    useEffect(() => {
        sensorTypesWithRights(role)
            .then((result) => setSensorTypes(result.data.sensorTypes))
            .catch((error) => setAlert(error.response.data.error));
    }, [role]);

    useEffect(() => {
        if (isNew) return;
        projectService
            .getProject(projectId, customerId)
            .then((res) => {
                setProject(res.data.project[0]);
                projectTypes(projectId).then((resp) =>
                    setSelectedSensorTypes(resp.data.projectTypes)
                );
            })
            .catch((error) => setAlert(error.response.data.error));

        localStorage.setItem("projectId", "createEdit");
    }, [projectId, customerId, isNew]);

    const handleMultilayered = () => {
        setSaveDisabled(false);
        setProject({
            ...project,
            multilayered: project.multilayered === 1 ? 0 : 1,
        });
    };

    const handleClose = () => {
        const projectType = localStorage.getItem("projectType") ?? "standard";
        if (!isSaved) {
            projectService
                .deleteProject(projectId)
                .then(() => {
                    setOpen(false);
                    navigate(`/dashboard/${projectType}`);
                })
                .catch((err) => setAlert(err.response.data.error));
        } else {
            setOpen(false);
            navigate(`/dashboard/${projectType}`);
        }
    };

    const handleSave = () => {
        projectService
            .updateProject(projectId, project, selectedSensorTypes)
            .then(() => {
                setSaveDisabled(true);
                localStorage.setItem(
                    "CreateEditProject",
                    JSON.stringify({
                        isNew: false,
                        projectId,
                        customerId,
                    })
                );
                setAlert({
                    type: "success",
                    message: "Saved project success",
                });
                setIsSaved(true);
            })
            .catch((error) => setAlert(error.response.data.error));
    };

    return (
        <Dialog
            PaperProps={{
                sx: styles.dialogPaper,
            }}
            open={open}
        >
            <DialogTitle style={{ padding: "0px" }}>
                <TopBar title="Create/Edit Project" onClose={handleClose} />
            </DialogTitle>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <DialogContent style={{ marginTop: "40px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Grid item xs={12} style={{ ...styles.gridMargin }}>
                            <TextField
                                required
                                fullWidth
                                label="Project Name"
                                size="small"
                                value={project.name}
                                onChange={(e) => {
                                    setSaveDisabled(false);
                                    setProject({
                                        ...project,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid container item>
                            <Grid item xs={8} style={{ ...styles.gridMargin }}>
                                <TextField
                                    style={{ width: "90%" }}
                                    label="Address"
                                    size="small"
                                    value={project.address}
                                    onChange={(e) => {
                                        setSaveDisabled(false);
                                        setProject({
                                            ...project,
                                            address: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ ...styles.gridMargin }}>
                                <TextField
                                    label="Postal"
                                    type="number"
                                    size="small"
                                    value={project.postal.replace(" ", "")}
                                    onChange={(e) => {
                                        setSaveDisabled(false);
                                        setProject({
                                            ...project,
                                            postal: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ ...styles.gridMargin }}>
                            <TextField
                                fullWidth
                                label="City"
                                size="small"
                                value={project.city}
                                onChange={(e) => {
                                    setSaveDisabled(false);
                                    setProject({
                                        ...project,
                                        city: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ ...styles.gridMargin }}>
                            <TextField
                                fullWidth
                                label="Country"
                                size="small"
                                value={project.country}
                                onChange={(e) => {
                                    setSaveDisabled(false);
                                    setProject({
                                        ...project,
                                        country: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ ...styles.gridMargin }}>
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    fullWidth
                                    label="Project Items Name"
                                    size="small"
                                    value={project.modalName}
                                    onChange={(e) => {
                                        setSaveDisabled(false);
                                        setProject({
                                            ...project,
                                            modalName: e.target.value,
                                        });
                                    }}
                                />
                                <QuestionMarkToolTip
                                    text={PROJECT_ITEMS_TEXT}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} style={{ ...styles.gridMargin }}>
                            <Autocomplete
                                fullWidth
                                limitTags={2}
                                options={sensorTypes}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={selectedSensorTypes}
                                multiple
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setSaveDisabled(false);
                                    setSelectedSensorTypes(value ?? []);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                            value={option.id}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        margin="dense"
                                        label="Sensor Types"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ ...styles.gridMargin }}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={project.multilayered === 1}
                                            onChange={handleMultilayered}
                                        />
                                    }
                                    label="Multilayered"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={2} style={{ ...styles.gridMargin }}>
                            <Button
                                disabled={
                                    saveDisabled ||
                                    project.name === "" ||
                                    selectedSensorTypes.length === 0
                                }
                                sx={{ ...styles.greenButton }}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <b>
                                    After modification of the project data you
                                    need to click Save for the changes to take
                                    effect
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={8}>
                        {project?.name !== "" &&
                            selectedSensorTypes?.length > 0 &&
                            !isNew && (
                                <Grid
                                    container
                                    item
                                    xs={8}
                                    style={{ ...styles.gridMargin }}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{ marginBottom: "10px" }}
                                        >
                                            <b>
                                                Changes regarding floors/objects
                                                will be saved automatically
                                            </b>
                                        </Typography>
                                        <FloorCards
                                            id={projectId}
                                            multilayered={project.multilayered}
                                            sensorType={
                                                selectedSensorTypes.length > 0
                                                    ? selectedSensorTypes[0].name.toLowerCase()
                                                    : null
                                            }
                                            editable
                                            hasStandardType={selectedSensorTypes.some(
                                                (type) =>
                                                    type.name === "Standard"
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CreateEditProject;
