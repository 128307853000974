export function scaleToRange(value, fromMin, fromMax, toMin, toMax) {
    return ((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin) + toMin;
}

export function roundedUpToNearest(value, nearest) {
    return Math.ceil(value / nearest) * nearest;
}

export function roundedDownToNearest(value, nearest) {
    return Math.floor(value / nearest) * nearest;
}
