import Axios from "axios";

const errorResponseIntercept = (error) => {
    if (error && error?.response?.status === 401) {
        if (error.response.data.message === "Unauthorized") {
            window.location = "/unauthorized";
        } else {
            window.location = "/login";
        }
    }
    return Promise.reject(error);
};

Axios.interceptors.response.use((res) => res, errorResponseIntercept);
