import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";

export default function TemplateDialog({
    confirmButton,
    saveCallback,
    setCallback,
}) {
    const [open, setOpen] = useState(true);
    const [name, setName] = useState("");

    const handleCancel = () => {
        setOpen(false);
        setCallback(false);
    };

    const handleConfirmation = () => {
        setOpen(false);
        setCallback(false);
        saveCallback(name);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Save object as template</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ marginTop: "5px" }}
                    fullWidth
                    label="Template name"
                    variant="outlined"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button sx={{ color: "green" }} onClick={handleConfirmation}>
                    {confirmButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
