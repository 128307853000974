import Axios from "axios";

export function getFloors(projectId) {
    return Axios.get("/api/floors", {
        params: {
            projectId,
        },
    });
}

export function getFloorsBySensorType(projectId, sensorType) {
    return Axios.get("/api/floors_by_sensor_type", {
        params: {
            projectId,
            sensorType,
        },
    });
}

export function addNewFloor(projectId, name) {
    return Axios.post("/api/add_new_floor", {
        projectId,
        name,
    });
}

export function deleteFloor(floorId) {
    return Axios.delete("/api/delete_floor", {
        data: {
            floorId,
        },
    });
}

export function UpdateFloorName(floorId, name) {
    return Axios.put("/api/update_floor_name", {
        floorId,
        name,
    });
}
