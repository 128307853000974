import { useEffect, useState } from "react";
import { Paper, Grid, Typography, CircularProgress } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import FloorCard from "./FloorCard";
import * as floorService from "../../Services/floorService";
import SystemAlertSnackbar from "./SystemAlertSnackbar";

const styles = {
    lightButton: {
        padding: "5px 10px 5px 5px",
        display: "flex",
        borderRadius: 1,
        alignItems: "center",
        fontFamily: "arial",
        cursor: "pointer",
    },
    addIcon: {
        fontSize: 20,
        color: "limegreen",
    },
};

export default function FloorCards({
    id,
    sensorType,
    hasStandardType = false,
    editable = true,
    multilayered = true,
}) {
    const [floors, setFloors] = useState([]);
    const [alert, setAlert] = useState({ message: "", type: "error" });
    const [loading, setLoading] = useState(true);

    const projectId = id;

    useEffect(() => {
        const getFloors = () => {
            if (sensorType && !editable) {
                floorService
                    .getFloorsBySensorType(projectId, sensorType)
                    .then((res) => setFloors(res.data.floors))
                    .catch((error) => setAlert(error.response.data.error))
                    .finally(() => setLoading(false));
            } else {
                floorService
                    .getFloors(projectId)
                    .then((res) => {
                        if (res.data.floors.length === 0 && !multilayered) {
                            const temp = [];
                            const name = "Temp name";
                            floorService
                                .addNewFloor(projectId, name)
                                .then((floor) => {
                                    temp.push({ id: floor.data.floorId, name });
                                    setFloors(temp);
                                })
                                .catch((error) =>
                                    setAlert(error.response.data.error)
                                );
                        } else {
                            setFloors(res.data.floors);
                        }
                    })
                    .catch((error) => {
                        setAlert(error.response.data.error);
                    })
                    .finally(() => setLoading(false));
            }
        };

        getFloors();
    }, [projectId, sensorType, multilayered, editable]);

    const handleAddFloor = () => {
        const temp = [...floors];
        const name = "Temp name";
        floorService
            .addNewFloor(projectId, name)
            .then((res) => {
                setAlert({
                    type: "success",
                    message: `successfully added floor ${name}`,
                });
                temp.push({ id: res.data.floorId, name });
                setFloors(temp);
            })
            .catch((error) => setAlert(error.response.data.error));
    };

    const handleFloorNameChange = (floorId, name) => {
        const temp = [...floors];
        const pos = temp.findIndex((x) => x.id === floorId);
        temp[pos].name = name;
        floorService
            .UpdateFloorName(floorId, name)
            .then(() => {
                setAlert({
                    type: "success",
                    message: `successfully updated floor ${name}`,
                });
                setFloors(temp);
            })
            .catch((error) => setAlert(error.response.data.error));
    };

    const handleFloorDelete = (floorId) => {
        const temp = [...floors];
        const sliceIndex = temp.findIndex((x) => x.id === floorId);
        floorService
            .deleteFloor(floorId)
            .then(() => {
                setAlert({
                    type: "success",
                    message: "successfully removed floor",
                });
                if (sliceIndex === 0) {
                    setFloors(temp.slice(1));
                } else if (sliceIndex === temp.length - 1) {
                    setFloors(temp.slice(0, -1));
                } else if (sliceIndex > 0) {
                    const newFloors = [].concat(
                        temp.slice(0, sliceIndex),
                        temp.slice(sliceIndex + 1)
                    );
                    setFloors(newFloors);
                }
            })
            .catch((error) => setAlert(error.response.data.error));
    };

    if (loading) {
        return <CircularProgress size={100} />;
    }

    return (
        <Grid item xs={12}>
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            {editable && multilayered ? (
                <Grid sx={{ marginBottom: "20px", width: "fit-content" }}>
                    <Paper
                        sx={{ ...styles.lightButton }}
                        onClick={handleAddFloor}
                    >
                        <AddIcon sx={styles.addIcon} />
                        Add Project Item
                    </Paper>
                </Grid>
            ) : null}
            {multilayered ? (
                <Grid
                    container
                    item
                    sx={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "69vh",
                    }}
                    xs={12}
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Project Items</Typography>
                    </Grid>
                    {floors.map((floor) => (
                        <FloorCard
                            key={floor.id}
                            id={floor.id}
                            name={floor.name}
                            sensorType={sensorType}
                            editCallback={handleFloorNameChange}
                            deleteCallback={handleFloorDelete}
                            editable={editable}
                            hasStandardType={hasStandardType}
                        />
                    ))}
                </Grid>
            ) : (
                <Grid>
                    {floors.map((floor) => (
                        <FloorCard
                            key={floor.id}
                            id={floor.id}
                            name={floor.name}
                            sensorType={sensorType}
                            editCallback={handleFloorNameChange}
                            deleteCallback={handleFloorDelete}
                            editable={editable}
                            multilayered={multilayered}
                            hasStandardType={hasStandardType}
                        />
                    ))}
                </Grid>
            )}
        </Grid>
    );
}
