import React, { createContext, useContext, useMemo, useState } from "react";

export const RoleContext = createContext();

export function RoleContextProvider(props) {
    const [role, setRole] = useState(-1);

    const roleValue = useMemo(() => ({ role, setRole }), [role, setRole]);

    return (
        <RoleContext.Provider value={roleValue}>
            {props.children}
        </RoleContext.Provider>
    );
}

export const useRoleContext = () => useContext(RoleContext);
