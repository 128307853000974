import "./App.css";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./Api/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoginForm from "./Components/LoginForm";
import Statistics from "./Components/Statistics/Statistics";
import Dashboard from "./Components/Dashboard/Dashboard";
import Settings from "./Components/Settings/Settings";
import SideNav from "./Components/SideNav";
import CreateEditProject from "./Components/CreateEditProject";
import * as signoutService from "./Services/signoutService";
import ObjectDialog from "./Components/Object/ObjectDialog.tsx";
import CreateEditSensor from "./Components/CreateEditSensor";
import Unauthorized from "./Components/Unauthorized";
import InfoModal from "./Components/InfoModal/InfoModal";
import EditMultipleSensors from "./Components/Object/EditMultipleSensors";
import { RoleContextProvider } from "./Context/RoleContext";

function App() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <RoleContextProvider>
                <Grid
                    container
                    style={{ height: "100%" }}
                    onClick={signoutService.updateToCurrentTime}
                >
                    <SideNav />
                    <Grid item xs>
                        <Routes>
                            <Route
                                path="/"
                                element={<Navigate replace to="/login" />}
                            />
                            <Route
                                path="/unauthorized"
                                element={<Unauthorized />}
                            />
                            <Route path="/login/*" element={<LoginForm />} />
                            <Route
                                path="/object/:objectId/*"
                                element={<ObjectDialog />}
                            />
                            <Route
                                path="/createeditsensor"
                                element={<CreateEditSensor />}
                            />
                            <Route
                                path="/editsensors"
                                element={<EditMultipleSensors />}
                            />
                            <Route path="/Settings" element={<Settings />} />
                            <Route
                                path="/statistics"
                                element={<Statistics />}
                            />
                            <Route
                                path="/info-modal/:projectId/:sensorType"
                                element={<InfoModal />}
                            />
                            <Route
                                path="/create-edit-project"
                                element={<CreateEditProject />}
                            />
                            <Route
                                path="/dashboard/*"
                                element={<Dashboard />}
                            />
                        </Routes>
                    </Grid>
                </Grid>
            </RoleContextProvider>
        </QueryClientProvider>
    );
}

export default App;
