import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";

import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import * as api from "../../../Services/settingsService";
import AlertDialog from "../../ReusableComponents/AlertDialog";
import roles from "../../../Services/roleService";

const styles = {
    dialogPaper: {
        minWidth: "700px",
    },
};

function DeleteMeasurer({ open, onCloseDialog, onAlert, role }) {
    const header = "Delete Measurer";
    const [measurers, setMeasurers] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [userDelete, setUserDelete] = useState({ id: "", username: "" });

    useEffect(() => {
        if (open) {
            if (role === roles.Admin || role === roles.Partner) {
                const fetchMeasureres = async () => {
                    try {
                        const res = await api.getMeasurers();
                        setMeasurers(res.data.measurers);
                    } catch (error) {
                        onAlert(error.response.data.error);
                    }
                };
                fetchMeasureres();
            }
        }
    }, [open, onAlert, role]);

    const openAlertDialog = (id, username) => {
        setUserDelete({ id, username });
        setShowDialog(true);
    };

    const columns = useMemo(
        () => [
            { field: "name", headerName: "Measurer name", width: 300 },
            { field: "email", headerName: "email", width: 250 },
            {
                field: "actions",
                type: "actions",
                headerName: "actions",
                width: 100,
                align: "center",
                getActions({ row }) {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => openAlertDialog(row.id, row.name)}
                        />,
                    ];
                },
            },
        ],
        []
    );

    const handleDelete = async (confirm) => {
        if (confirm) {
            try {
                await api.deleteMeasurer(userDelete.id);
                setMeasurers(
                    measurers.filter(
                        (measurer) => measurer.id !== userDelete.id
                    )
                );
            } catch (error) {
                onAlert(error.response.data.error);
            }
        }
    };

    const closeAlertDialog = (close) => {
        setUserDelete({ id: "", username: "" });
        setShowDialog(close);
    };

    const closeDialog = () => {
        setMeasurers([]);
        onCloseDialog(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => onCloseDialog(false)}
            PaperProps={{
                sx: styles.dialogPaper,
            }}
        >
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: "Measurer name", sort: "asc" },
                            ],
                        },
                    }}
                    columns={columns}
                    rows={measurers}
                    autoHeight
                    rowsPerPageOptions={[8]}
                    pageSize={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
            {showDialog ? (
                <AlertDialog
                    header="Delete Measurer"
                    body={`Are you sure you want to delete Measurer ${userDelete.username}?`}
                    confirmButton="Delete"
                    callback={handleDelete}
                    setCallback={closeAlertDialog}
                />
            ) : null}
        </Dialog>
    );
}

export default DeleteMeasurer;
