import React, { useEffect, useState } from "react";
import "./StatsProjectButtons.css";
import { NavLink, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { sensorTypesWithRights } from "../../Services/sensorService";
import { projectsWithStatisticsPage } from "../../Services/statisticsService";
import { useRoleContext } from "../../Context/RoleContext";

const styles = {
    buttonText: {
        margin: "10px 20px 0 20px",
        fontSize: "18px",
    },
};

function ProjectButtons({ objectId, selectedSite, handleSiteChange }) {
    const path = useLocation().pathname;
    const [buttonState, setButtonState] = useState([]);
    const { role } = useRoleContext();

    useEffect(() => {
        const projectType = localStorage.getItem("projectType");

        sensorTypesWithRights(role).then((res) => {
            if (res?.data?.sensorTypes) {
                const projects = res.data.sensorTypes.filter((type) =>
                    projectsWithStatisticsPage.includes(type.name.toLowerCase())
                );

                setButtonState(
                    projects.map((sensorType) => {
                        const lowerCaseName = sensorType.name.toLowerCase();
                        const state =
                            projectType === lowerCaseName
                                ? "active"
                                : "inactive";

                        return {
                            path: `/${lowerCaseName}`,
                            text: sensorType.name,
                            state,
                            key: lowerCaseName,
                        };
                    })
                );
            }
        });
    }, [objectId, path, role]);

    function toggleActive(activeKey) {
        localStorage.setItem("projectType", activeKey);
        setButtonState(
            buttonState.map((btn) => ({
                ...btn,
                state: btn.key === activeKey ? "active" : "inactive",
            }))
        );

        handleSiteChange(selectedSite);
    }

    return (
        <Box className="button-holder">
            {buttonState.map((item) => (
                <NavLink
                    className={`link-item2 ${
                        item.state === "active" ? "active2" : ""
                    }`}
                    key={item.key}
                    onClick={() => toggleActive(item.key)}
                >
                    <Box sx={{ ...styles.buttonText }}>
                        {item.text}
                        <p className={`project-selected ${item.state}`} />
                    </Box>
                </NavLink>
            ))}
        </Box>
    );
}

export default ProjectButtons;
