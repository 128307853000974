import Axios from "axios";

export function signOut() {
    return Axios.get("/signout");
}

export function checkPassword(username, password) {
    return Axios.get("/checkpass", {
        params: {
            name: username,
            pass: password,
        },
    });
}

export function login(username, password) {
    return Axios.post("/login", {
        username,
        password,
    });
}
