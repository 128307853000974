import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

export default function WarningDialog({ text, setCallback }) {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        setCallback(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
