import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";

function SortSelector({ sortValue, callback }) {
    const [sort, setSort] = useState(sortValue);

    const handleChange = (event) => {
        setSort(event.target.value);
        callback({ sortOrder: event.target.value, arr: [] });
    };

    return (
        <Box sx={{ boxShadow: 3, borderRadius: "28px" }}>
            <FormControl
                sx={{ width: 150, padding: "4px 0px 0px 0px" }}
                size="small"
            >
                <Select
                    id="selector"
                    style={{
                        borderRadius: "28px",
                        marginLeft: "10px",
                        textAlign: "center",
                    }}
                    value={sort}
                    variant="standard"
                    disableUnderline
                    onChange={handleChange}
                >
                    <MenuItem value="Latest activity">Latest activity</MenuItem>
                    <MenuItem value="Name">Name</MenuItem>
                    <MenuItem value="Created">Created</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export default SortSelector;
