import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { addNewCustomer } from "../../../Services/settingsService";
import { getPartners } from "../../../Services/projectService";
import roles from "../../../Services/roleService";

function AddCustomer({ open, onCloseDialog, onAlert, role }) {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [username, setUsername] = useState("");
    const [partnerSelector, setPartnerSelector] = useState({
        selected: "",
        partners: [],
    });

    useEffect(() => {
        if (role === roles.Admin && open) {
            getPartners()
                .then((res) => {
                    setPartnerSelector({
                        selected: "",
                        partners: res.data.partners,
                    });
                })
                .catch((error) => onAlert(error.response.data.error));
        }
    }, [open, onAlert, role]);

    const handleSave = () => {
        const ownerId = role === roles.Admin ? partnerSelector.selected : null;
        addNewCustomer(username, password, ownerId, null)
            .then(() => onCloseDialog(false))
            .catch((error) => onAlert(error.response.data.error));
    };

    const handlePartnerSelect = (partnerId) => {
        setPartnerSelector({ ...partnerSelector, selected: partnerId });
    };

    return (
        <Dialog open={open} onClose={() => onCloseDialog(false)}>
            <DialogTitle>Add customer</DialogTitle>
            <DialogContent>
                {role === roles.Admin ? (
                    <FormControl fullWidth sx={{ marginTop: "5px" }}>
                        <InputLabel>Partners</InputLabel>
                        <Select
                            value={
                                partnerSelector.partners.length > 0
                                    ? partnerSelector.selected
                                    : ""
                            }
                            label="Partners"
                            onChange={(event) =>
                                handlePartnerSelect(event.target.value)
                            }
                        >
                            {partnerSelector.partners.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
                <TextField
                    fullWidth
                    label="Customer name"
                    variant="outlined"
                    margin="dense"
                    onChange={(text) => setUsername(text.target.value)}
                />
                <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    margin="dense"
                    type={showPassword ? "text" : "password"}
                    onChange={(text) => setPassword(text.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    fullWidth
                    label="Confirm password"
                    variant="outlined"
                    margin="dense"
                    type={showConfirm ? "text" : "password"}
                    onChange={(text) => setConfirm(text.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowConfirm(!showConfirm)}
                                >
                                    {showConfirm ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={
                        password.length === 0 ||
                        username.length === 0 ||
                        (partnerSelector.selected.length === 0 &&
                            role !== roles.Partner) ||
                        password !== confirm
                    }
                >
                    Save
                </Button>
                <Button onClick={() => onCloseDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddCustomer;
