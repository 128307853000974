import { useEffect, useState } from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SystemAlertSnackbar({
    showAlert,
    type,
    message,
    callback,
}) {
    const [open, setOpen] = useState(showAlert);
    useEffect(() => setOpen(showAlert), [showAlert]);

    const handleClose = () => {
        setOpen(false);
        callback({ type, message: "" });
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <Alert
                severity={type}
                sx={{ mb: 2 }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );
}
