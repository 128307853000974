import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsEditRightsDialog from "./SettingsEditRightsDialog";
import roles from "../../Services/roleService";
import MoveProject from "./MoveProject";

function SettingsProject({ role, alertCB }) {
    const [openProjectRights, setOpenProjectRights] = useState(false);
    const [openMoveProject, setOpenMoveProject] = useState(false);

    const handleCloseRights = () => {
        setOpenProjectRights(false);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Projects</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1} alignItems="flex-start">
                    <Button onClick={() => setOpenProjectRights(true)}>
                        Project Rights
                    </Button>
                    {role === roles.Admin && (
                        <Button onClick={() => setOpenMoveProject(true)}>
                            Move project
                        </Button>
                    )}
                </Stack>
                {openProjectRights && (
                    <SettingsEditRightsDialog
                        open={openProjectRights}
                        closeCB={handleCloseRights}
                        alertCB={alertCB}
                        role={role}
                    />
                )}
                {role === roles.Admin && openMoveProject && (
                    <MoveProject
                        open={openMoveProject}
                        onAlert={alertCB}
                        role={role}
                        onCloseDialog={setOpenMoveProject}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default SettingsProject;
