import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getPartners } from "../../../Services/projectService";
import { updateUser } from "../../../Services/settingsService";

function UpdatePartner({ open, onCloseDialog, onAlert }) {
    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState({});
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);

    const filter = createFilterOptions();

    useEffect(() => {
        if (open) {
            getPartners()
                .then((res) => setPartners(res.data.partners))
                .catch((error) => onAlert(error.response.data.error));
        }
    }, [open, onAlert]);

    const handlePartnerSelect = (partner) => {
        setSelectedPartner(partner ?? "");
        setUsername(partner?.name ?? "");
    };

    const closeDialog = () => {
        setUsername("");
        setPassword("");
        setConfirm("");

        onCloseDialog(false);
    };

    const handleSave = () => {
        const user = {
            userId: selectedPartner.id,
            username,
            role: 2,
            password,
        };
        updateUser(user)
            .then(() => closeDialog(false))
            .catch((err) => onAlert({ message: err.response.data.error }));
    };

    const allowSave = selectedPartner && username && password === confirm;

    return (
        <Dialog open={open} onClose={closeDialog}>
            <DialogTitle>Update partner</DialogTitle>
            <DialogContent sx={{ maxWidth: "500px", paddingBottom: "0px" }}>
                <Stack spacing={1} sx={{ paddingTop: "10px" }}>
                    <Autocomplete
                        label="Select partner"
                        options={partners}
                        renderInput={(params) => (
                            <TextField {...params} label="Partner" />
                        )}
                        onChange={(event, value) => handlePartnerSelect(value)}
                        getOptionLabel={(option) =>
                            typeof option === "string"
                                ? option
                                : option.name ?? ""
                        }
                        filterOptions={(options, params) =>
                            filter(options, params)
                        }
                    />
                    <TextField
                        value={username}
                        fullWidth
                        label="Partner name"
                        variant="outlined"
                        margin="dense"
                        onChange={(text) => setUsername(text.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="New password"
                        variant="outlined"
                        margin="dense"
                        type={showPassword ? "text" : "password"}
                        onChange={(text) => setPassword(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Confirm password"
                        variant="outlined"
                        margin="dense"
                        type={showConfirm ? "text" : "password"}
                        onChange={(text) => setConfirm(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowConfirm(!showConfirm)
                                        }
                                    >
                                        {showConfirm ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} disabled={!allowSave}>
                    Save
                </Button>
                <Button onClick={() => closeDialog(false)}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpdatePartner;
