import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    AccordionDetails,
    Accordion,
    Typography,
    Box,
    InputAdornment,
    IconButton,
    Grid,
    AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import EditableTextField from "./EditableTextField";
import AlertDialog from "./AlertDialog";
import * as objectService from "../../Services/objectService";
import SystemAlertSnackbar from "./SystemAlertSnackbar";
import TemplateSelectionDialog from "../TemplateSelectionDialog";

const styles = {
    accordionBox: {
        marginRight: "10px",
        boxShadow: 3,
    },
    button: {
        cursor: "pointer",
    },
    accordionDetailsBox: {
        padding: "0px 0px 5px 0px",
    },
    addIcon: {
        color: "limegreen",
    },
    deleteIcon: {
        color: "red",
    },
    nameWhenNotEditable: {
        marginLeft: "20px",
    },
};

function FloorCard({
    id,
    name,
    sensorType,
    editCallback,
    deleteCallback,
    hasStandardType = false,
    editable = true,
    multilayered = true,
}) {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const [enableDelete, setEnableDelete] = useState(false);
    const [showDialogFloor, setShowDialogFloor] = useState(false);
    const [showTemplateDialog, setShowTemplateDialog] = useState(false);
    const [showDialogObject, setShowDialogObject] = useState({
        show: false,
        name: "",
        id: -1,
    });
    const [alert, setAlert] = useState({ message: "", type: "error" });
    const [objects, setObjects] = useState([]);
    const [templates, setTemplates] = useState([]);

    const floorId = id;
    const floorName = name;

    useEffect(() => {
        const getObjects = () => {
            if (sensorType && !editable) {
                objectService
                    .getObjectsBySensorType(floorId, sensorType)
                    .then((res) => setObjects(res.data.objects))
                    .catch((error) => setAlert(error.response.data.error));
            } else {
                objectService
                    .getObjects(floorId)
                    .then((res) => setObjects(res.data.objects))
                    .catch((error) => setAlert(error.response.data.error));
            }
        };

        getObjects();
    }, [floorId, sensorType, multilayered, editable]);

    useEffect(() => {
        objectService
            .getObjectTemplate()
            .then((res) => setTemplates(res.data.templates));
    }, []);

    const toggleAccordion = () => {
        setExpanded(!expanded);
    };

    const handleAddObject = () => {
        objectService
            .addNewObject(floorId, "Temp object name")
            .then((res) => {
                if (sensorType !== undefined) {
                    navigate(`/object/${res.data.objectId}/${sensorType}`);
                } else {
                    navigate(`/object/${res.data.objectId}/standard`);
                }
            })
            .catch((error) => setAlert(error.response.data.error));
    };

    const handleFloorNameChange = (newName) => {
        editCallback(floorId, newName);
    };

    const handleDeleteFloor = (confirmation) => {
        if (confirmation) {
            deleteCallback(floorId);
        }
    };

    const navigateFloor = (objectId) => {
        navigate(`/object/${objectId}/${sensorType}`);
    };

    const handleDeleteObject = (confirmation) => {
        if (confirmation) {
            const temp = [...objects];
            const sliceIndex = temp.findIndex(
                (x) => x.objectId === showDialogObject.id
            );
            objectService
                .deleteObject(showDialogObject.id)
                .then(() => {
                    setAlert({
                        type: "success",
                        message: `removed object with id ${showDialogObject.id}`,
                    });
                    if (sliceIndex === 0) {
                        setObjects(temp.slice(1));
                    } else if (sliceIndex === temp.length - 1) {
                        setObjects(temp.slice(0, -1));
                    } else if (sliceIndex > 0) {
                        const newObjects = [].concat(
                            temp.slice(0, sliceIndex),
                            temp.slice(sliceIndex + 1)
                        );
                        setObjects(newObjects);
                    }
                })
                .catch((error) => setAlert(error.response.data.error));
        }
    };

    const handleAddObjectFromTemplate = (template) => {
        objectService
            .addNewObjectFromTemplate(floorId, template)
            .then((res) => {
                if (sensorType !== undefined) {
                    navigate(`/object/${res.data.objectId}/${sensorType}`);
                } else {
                    navigate(`/object/${res.data.objectId}/standard`);
                }
            })
            .catch((error) => setAlert(error.response.data.error));
    };

    return (
        <>
            {showTemplateDialog && (
                <TemplateSelectionDialog
                    setCallback={setShowTemplateDialog}
                    saveCallback={handleAddObjectFromTemplate}
                    templates={templates}
                />
            )}
            {showDialogFloor && (
                <AlertDialog
                    header="Remove Floor"
                    body={`Are you sure you want to delete floor ${floorName}?`}
                    confirmButton="Delete"
                    callback={handleDeleteFloor}
                    setCallback={setShowDialogFloor}
                />
            )}
            {showDialogObject.show && (
                <AlertDialog
                    header="Remove Object"
                    body={`Are you sure you want to delete object ${showDialogObject.name}?`}
                    confirmButton="Delete"
                    callback={handleDeleteObject}
                    setCallback={setShowDialogObject}
                />
            )}
            <SystemAlertSnackbar
                showAlert={alert?.message.length > 0}
                type={alert?.type}
                message={alert?.message}
                callback={setAlert}
            />
            <Grid
                item
                xs={10}
                key={id}
                style={{ marginBottom: "5px", height: "fit-content" }}
                onMouseEnter={() => setEnableDelete(true)}
                onMouseLeave={() => setEnableDelete(false)}
            >
                {enableDelete && editable && multilayered ? (
                    <InputAdornment
                        position="end"
                        sx={{ justifyContent: "flex-end" }}
                    >
                        <IconButton
                            sx={{ marginRight: "-15px" }}
                            onClick={() => setShowDialogFloor(true)}
                        >
                            <CancelIcon
                                sx={{ color: "red", zIndex: "1" }}
                                fontSize="small"
                            />
                        </IconButton>
                    </InputAdornment>
                ) : null}
                <Accordion
                    disableGutters
                    x={{ ...styles.accordionBox }}
                    expanded={expanded}
                >
                    {multilayered ? (
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon onClick={toggleAccordion} />
                            }
                        >
                            <Grid container item xs={12}>
                                {editable ? (
                                    <Grid container item>
                                        <EditableTextField
                                            name={floorName}
                                            editCallback={handleFloorNameChange}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        item
                                        onClick={toggleAccordion}
                                    >
                                        <Typography> {floorName}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionSummary>
                    ) : null}
                    <AccordionDetails sx={{ ...styles.accordionDetailsBox }}>
                        {editable && (
                            <Box sx={{ ...styles.button }}>
                                <Grid container onClick={handleAddObject}>
                                    <Grid
                                        container
                                        item
                                        xs={2}
                                        justifyContent="center"
                                    >
                                        <AddIcon sx={{ ...styles.addIcon }} />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={10}
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Typography>Add new object</Typography>
                                    </Grid>
                                </Grid>
                                {hasStandardType && templates.length > 0 && (
                                    <Grid
                                        container
                                        onClick={() =>
                                            setShowTemplateDialog(true)
                                        }
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={2}
                                            justifyContent="center"
                                        >
                                            <AddIcon
                                                sx={{ ...styles.addIcon }}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={10}
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Typography>
                                                Add from template
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        )}
                        <Box sx={{ ...styles.button }}>
                            <Grid container item>
                                {objects?.map((object) =>
                                    editable ? (
                                        <Grid
                                            container
                                            item
                                            key={object.objectId}
                                            alignItems="center"
                                            onClick={() =>
                                                setShowDialogObject({
                                                    show: true,
                                                    name: object.name,
                                                    id: object.objectId,
                                                })
                                            }
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={2}
                                                justifyContent="center"
                                            >
                                                <CloseIcon
                                                    sx={{
                                                        ...styles.deleteIcon,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography>
                                                    {object.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            container
                                            item
                                            key={object.objectId}
                                            alignItems="center"
                                            onClick={() =>
                                                navigateFloor(object.objectId)
                                            }
                                        >
                                            <Grid
                                                item
                                                xs={10}
                                                style={
                                                    styles.nameWhenNotEditable
                                                }
                                            >
                                                <Typography>
                                                    {object.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                ) ?? null}
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </>
    );
}

export default FloorCard;
