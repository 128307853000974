import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Stack,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { addPartner } from "../../../Services/settingsService";

function AddPartner({ open, onCloseDialog, onAlert }) {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirm] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [username, setUsername] = useState("");

    const closeDialog = () => {
        setUsername("");
        setPassword("");
        setConfirm("");

        onCloseDialog(false);
    };

    const handleSave = () => {
        addPartner({ username, password })
            .then(() => closeDialog())
            .catch((error) => onAlert(error.response.data.error));
    };

    return (
        <Dialog open={open} onClose={closeDialog}>
            <DialogTitle>Add partner</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        label="Partner name"
                        variant="outlined"
                        margin="dense"
                        onChange={(text) => setUsername(text.target.value)}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        margin="dense"
                        type={showPassword ? "text" : "password"}
                        onChange={(text) => setPassword(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Confirm password"
                        variant="outlined"
                        margin="dense"
                        type={showConfirm ? "text" : "password"}
                        onChange={(text) => setConfirm(text.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowConfirm(!showConfirm)
                                        }
                                    >
                                        {showConfirm ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={
                        username.length === 0 ||
                        password.length === 0 ||
                        password !== confirm
                    }
                >
                    Save
                </Button>
                <Button onClick={closeDialog}>cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddPartner;
