import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

export default function AlertDialog({
    header,
    body,
    confirmButton,
    callback,
    setCallback,
}) {
    const [open, setOpen] = useState(true);

    const handleCancel = () => {
        setOpen(false);
        setCallback(false);
        callback(false);
    };

    const handleConfirmation = () => {
        setOpen(false);
        setCallback(false);
        callback(true);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <DialogContentText>{body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ color: "red" }} onClick={handleConfirmation}>
                    {confirmButton}
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
