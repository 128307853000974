import Axios from "axios";

export function putStatsColumns(filter) {
    return Axios.put("/api/statscolumns", { filter });
}

export function getStatsColumns() {
    return Axios.post("/api/statscolumns");
}

export function getInitSites() {
    return Axios.get("/all_sites_for_user");
}

export function getSites(customerId, partnerId, role) {
    return Axios.get("/api/sites", { params: { customerId, partnerId, role } });
}

export function getStats(siteId, customerId) {
    return Axios.get("/api/stats", { params: { siteId, customerId } });
}
