import { useState } from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";

export default function TemplateSelectionDialog({
    saveCallback,
    setCallback,
    templates,
}) {
    const [open, setOpen] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState();

    const handleCancel = () => {
        setOpen(false);
        setCallback(false);
    };

    const handleConfirmation = () => {
        setOpen(false);
        setCallback(false);
        saveCallback(selectedTemplate);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Save object as template</DialogTitle>
            <DialogContent>
                <Autocomplete
                    disablePortal
                    options={templates}
                    sx={{ width: 300, padding: "7px" }}
                    getOptionLabel={(template) => template.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Template name" />
                    )}
                    onChange={(_, value) => setSelectedTemplate(value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button sx={{ color: "green" }} onClick={handleConfirmation}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
